<div class="body content" style="margin-top: 8%;margin-left: 10%;">
  <form [formGroup]="Remainders">
    <div class="row marginright">
      <div>
        <button class="btn btn-primary" (click)="backbtn()">Back To List Template</button>
      </div>
      <div style="padding-left: 400px;"><h2><u>Create Template</u></h2></div>
     
      <div class="col-md-3">
        <label>Category</label>
        <select class="form-select" name="CategoryID" formControlName="CategoryID" required>
          <option selected value="" disabled>Select Category</option>
          <option *ngFor="let data of CategoryType" value={{data.CategoryID}}>{{data.AliasName}}</option>
        </select>
        <span style="color: red;" *ngIf="CategoryID && CategoryID.invalid && CategoryID.touched">*Enter Category</span>
      </div>
      <div class="col-md-3">
        <label>Template Name</label>
        <input type="text" class="form-control" formControlName="TemplateName" />
        <span style="color: red;" *ngIf="TemplateName && TemplateName.invalid && TemplateName.touched">*Enter Template Name</span>
      </div>
    </div>
    <div class="col-md-6">
      <label for="exampleFormControlTextarea1" class="form-label">Message</label>
      <div>
        <!-- [(ngModel)]="Message" [ngModelOptions]="{standalone: true}" id="exampleFormControlTextarea1" -->
        <textarea type="text" class="form-control"    formControlName="Message" 
          style="height: 150px;" rows="3"></textarea>


      </div>
      <span style="color: red;" *ngIf="Message && Message.invalid && Message.touched">*Enter Message</span>

      
      <div class="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add Attributes Here!</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <label>Enter Attribute</label>
              <input type="text" autocomplete="off" placeholder="Enter Attribute" class="form-control" (keypress)="spaceremove($event)" style="width: 50%;"
                [(ngModel)]="variable" [ngModelOptions]="{standalone: true}" name="variable" [value]="variable" />
            </div>
            <div class="modal-footer">
              <button (click)="Savechanges()" class="btn btn-primary" data-bs-dismiss="modal" >Save</button>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="row">
    <div class="col-md-2" style="padding-top: 10px;">
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Add Attributes
      </button>
    </div>
    <div class="col-md-2" style="padding-top: 10px; padding-left: 310px;">
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal"  [disabled]='Remainders.invalid || isNextDisabled' (click)="submittemplate(); " >
        Submit
      </button>
    </div>
  </div>
</form>
</div>