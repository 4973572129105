<form name="Sopnser" id="Sopnser">



    



    <div  class="previosButton" (click)="dashboard()"><img src="../../../assets/images/previous.png"></div>
    <div class="col-md-10 container">
        <div>
            <button class="button justify-content-md-center sponserdetailsplate titlebackground">Anchor Manager</button>
            <div class="btn-group dropcolor topdropdown">
              <button type="button" class="btn dropcolor">Cities</button>
              <button
                type="button"
                class="btn  dropdown-toggle dropdown-toggle-split dropcolor"
                data-mdb-toggle="dropdown"
                aria-expanded="false"
              >
              <img src="../../../assets/images/line.png" class="lineheight">
                <span class="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item" href="#">Separated link</a></li>
              </ul>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="form-group col-md-6 inputBox">
                <div class="btn-group dropcolor">
                    <button type="button" class="btn dropcolor">Cities</button>
                    <button
                      type="button"
                      class="btn  dropdown-toggle dropdown-toggle-split dropcolor"
                      data-mdb-toggle="dropdown"
                      aria-expanded="false"
                    >
                    <img src="../../../assets/images/line.png" class="lineheight">
                      <span class="visually-hidden">Toggle Dropdown</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">Action</a></li>
                      <li><a class="dropdown-item" href="#">Another action</a></li>
                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                      <li><hr class="dropdown-divider" /></li>
                      <li><a class="dropdown-item" href="#">Separated link</a></li>
                    </ul>
                  </div>
            </div>

            <div class="form-group col-md-6 inputBox">
                <div class="btn-group dropcolor">
                    <button type="button" class="btn dropcolor">verify </button>
                    <button
                      type="button"
                      class="btn  dropdown-toggle dropdown-toggle-split dropcolor"
                      data-mdb-toggle="dropdown"
                      aria-expanded="false"
                    >
                    <img src="../../../assets/images/line.png" class="lineheight">
                      <span class="visually-hidden">Toggle Dropdown</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">Action</a></li>
                      <li><a class="dropdown-item" href="#">Another action</a></li>
                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                      <li><hr class="dropdown-divider" /></li>
                      <li><a class="dropdown-item" href="#">Separated link</a></li>
                    </ul>
                  </div>
            </div>
           

        </div>
        <br>
        <div class="row">

            <div class=" col-md-4 inputBox">
                <input type="text" name="email" required
                    onkeyup="this.setAttribute('value', this.value);" value="">

                <label>Name</label>
            </div>
            <div class=" col-md-4 inputBox">
                <input type="text" name="email" required
                    onkeyup="this.setAttribute('value', this.value);" value="">

                <label>E-mail</label>
            </div>
            <div class=" col-md-4 inputBox">
              <input type="text" name="email" required
                  onkeyup="this.setAttribute('value', this.value);" value="">

              <label>mobile</label>
          </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-4 "></div>
            <div class="col-md-4"></div>
            <button class="button justify-content-md-center sponserdetailsplate titlebackground">save</button>
            <div class="col-md-4"></div>
        </div>

    </div>
    <br>








</form>