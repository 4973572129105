<div class="bodycontent" style="margin-top: 100px; margin-left: 120px;">
  <nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button"
        role="tab" aria-controls="nav-home" aria-selected="true">Features</button>
      <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button"
        role="tab" aria-controls="nav-profile" aria-selected="false">Devices</button>
      <button class="nav-link" id="nav-profiles-tab" data-bs-toggle="tab" data-bs-target="#nav-profiles" type="button"
        role="tab" aria-controls="nav-profiles" aria-selected="false">Devices & Features</button>
    </div>
  </nav>
  <div class="mt-3">
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
        <div class="col-md-12 row card">
          <div class="card-header" style="text-align: center;">
            <strong>Feature Type</strong>
          </div>
          <form [formGroup]="featuretypeform">
            <div class=" row card-Body">
              <div class="col-md-6">
                <span class="formdetails">Type</span>
                <select class="form-select" formControlName="Type" placeholder="Select Type">
                  <option selected value="" disabled>Select Type</option>
                  <option value="Device">
                    <li><span class="dropdown-item-text">Device</span></li>
                  </option>
                  <option value="Screen">
                    <li><span class="dropdown-item-text">Screen</span></li>
                  </option>
                </select>
                <div *ngIf=" featuretypeform.controls['Type'].value == '' && fpress == true" class="errorColor">*Select
                  Type</div>
              </div>
              <div class="col-md-3">
                <Label>Feature</Label>
                <input type="text" class="form-control" placeholder="Enter Feature" formControlName="Feature"
                  name="Feature" required>
                <div *ngIf=" featuretypeform.controls['Feature'].value == '' && fpress == true" class="errorColor">
                  *Select Feature</div>
              </div>
              <div class="col-md-3">
                <Label>Alias Name</Label>
                <input type="text" class="form-control" placeholder="Enter Name" formControlName="AliasName"
                  name="AliasName" required>
                <div *ngIf=" featuretypeform.controls['AliasName'].value == '' && fpress == true" class="errorColor">
                  *Select Alias Name</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <span class="formdetails">Goals</span>
               
                <select class="form-select" name="IsGoal" formControlName="IsGoal"   required>
                  <option selected value="" disabled>Select Goals</option>
                  <option value="true">
                    <li><span class="dropdown-item-text">Yes</span></li>
                  </option>
                  <option value="false">
                    <li><span class="dropdown-item-text">No</span></li>
                  </option>
                </select>
                {{featuretypeform.controls['IsGoal'].value}}
                <div *ngIf=" featuretypeform.controls['IsGoal'].value == null && fpress == true" class="errorColor">
                  *Select Goal</div>
              </div>
              <div class="col-md-2">
                <span class="formdetails">Required for Dashboard</span>
                <select class="form-select" formControlName="IsRequired" placeholder="Select IsRequired">
                  <option selected value="" disabled>Select IsRequired</option>
                  <option value="true">
                    <li><span class="dropdown-item-text">Yes</span></li>
                  </option>
                  <option value="false">
                    <li><span class="dropdown-item-text">No</span></li>
                  </option>
                </select>
                <div *ngIf=" featuretypeform.controls['IsRequired'].value == null && fpress == true" class="errorColor">
                  *Select Required</div>
              </div>
              <div class="col-md-2">
                <span class="formdetails">Threshold</span>
                <select class="form-select" formControlName="IsThreshold" placeholder="Select Threshold">
                  <option selected value="" disabled>Select Threshold</option>
                  <option value="true">
                    <li><span class="dropdown-item-text">Yes</span></li>
                  </option>
                  <option value="false">
                    <li><span class="dropdown-item-text">No</span></li>
                  </option>
                </select>
                <div *ngIf=" featuretypeform.controls['IsThreshold'].value == null && fpress == true" class="errorColor">
                  *Select Threshold</div>
              </div>
              <div class="col-md-2" *ngIf="this.featuretypeform.value.IsThreshold == 'true'"
                [hidden]="this.featuretypeform.value.IsThreshold != 'true'">
                <span class="formdetails">Threshold Minimum</span>
                <input type="text" class="form-control" placeholder="Enter ThresholdMin" formControlName="ThresholdMin"
                  name="ThresholdMin">
                <div *ngIf=" featuretypeform.controls['ThresholdMin'].value == null && fpress == true"
                        class="errorColor">*Select Threshold Min</div>
              </div>
              <div class="col-md-2" *ngIf="this.featuretypeform.value.IsThreshold == 'true'"
                [hidden]="this.featuretypeform.value.IsThreshold != 'true'">
                <span class="formdetails">Threshold Maximum</span>
                <input type="text" class="form-control" placeholder="Enter ThresholdMax" formControlName="ThresholdMax"
                  name="ThresholdMax">
                <div *ngIf=" featuretypeform.controls['ThresholdMax'].value == null && fpress == true"
                        class="errorColor">*Select Threshold Max</div>
              </div>
              <div class="col-md-3">
                <span class="formdetails" style="padding-top: 2%;">Image</span>
                <input type="file" class="form-control" formControlName="Images" (change)="onuploadimgFile($event)"
                  name="Images" />
                  <div *ngIf=" featuretypeform.controls['Images'].value == '' && fpress == true"
                        class="errorColor">*Select Threshold Max</div>
              </div>
              <!-- <div class="col-md-3">
                      <div>Existing Document</div>
                      <button (click)="existing()">View</button>
                  </div> -->
            </div>

            <br>
            <div class="button" style="text-align: right;"><button class="btn btn-anvayaaYellow" *ngIf="savefdetails"
                (click)="saveftype()">Save</button></div>
            <div class="button" style="text-align: right;"><button class="btn btn-anvayaaYellow" *ngIf="updatefdetails"
                (click)="updateftype()">Update</button></div>
          </form>
          <div class="mt-5">
            <table class="table table-striped table-hover">
              <thead class="tablehead">
                <tr>
                  <th scope="col" style="text-align: center;">S.No</th>
                  <th scope="col" style="text-align: center;">Type</th>
                  <th scope="col" style="text-align: center;">Feature</th>
                  <th scope="col" style="text-align: center;">Alias Name</th>
                  <th scope="col" style="text-align: center;">IsGoal</th>
                  <th scope="col" style="text-align: center;">IsRequired</th>
                  <th scope="col" style="text-align: center;">IsThreshold</th>
                  <th scope="col" style="text-align: center;">ThresholdMax</th>
                  <th scope="col" style="text-align: center;">ThresholdMin</th>
                  <th scope="col" style="text-align: center;">Images</th>
                  <th scope="col" style="text-align: center;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="tablecur" *ngFor="let Customer of this.customer;let i=index">
                  <td style="text-align: center;">{{i+1}}</td>
                  <td style="text-align: center;">{{Customer?.Type}}</td>
                  <td style="text-align: center;">{{Customer?.Feature}}</td>
                  <td style="text-align: center;">{{Customer?.AliasName}}</td>
                  <td style="text-align: center;">{{Customer?.IsGoal}}</td>
                  <td style="text-align: center;">{{Customer?.IsRequired}}</td>
                  <td style="text-align: center;">{{Customer?.IsThreshold}}</td>
                  <td style="text-align: center;">{{Customer?.ThresholdMax}}</td>
                  <td style="text-align: center;">{{Customer?.ThresholdMin}}</td>
                  <!-- <td>{{Customer?.Images}}</td> -->
                  <td style="text-align: center;"><img [src]=" Customer?.Images" alt="Image not available" width="50"
                      height="50" /></td>
                  <!-- baseUrl + -->
                  <td style="text-align: center;">
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" title="Update">
                      <i class="fa fa-edit" (click)="existingTableData(Customer)" aria-hidden="true"></i>
                    </button>&nbsp;
                    <button class="btn btn-danger" (click)="confirmationToRemovefeature(Customer)" title="Remove"
                      data-bs-toggle="modal" data-bs-target="#featureexampleModal"><i class="fa fa-trash-o"
                        aria-hidden="true"></i></button>


                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tab-pane fade show" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">

        <div class="col-md-12 row card">
          <div class="card-header" style="text-align: center;">
            <strong>Device Type</strong>
          </div>
          <form [formGroup]="devicetypeform">
            <div class=" row card-Body">
              <div class="col-md-6">
                <span class="formdetails">Type</span>
                <select class="form-select" formControlName="Type" placeholder="Select Type">
                  <option selected value="" disabled>Select Type</option>
                  <option value="Watch">
                    <li><span class="dropdown-item-text">Watch</span></li>
                  </option>
                  <option value="Health_Device">
                    <li><span class="dropdown-item-text">Health Device</span></li>
                  </option>
                </select>
                <div *ngIf=" devicetypeform.controls['Type'].value == '' && press == true" class="errorColor">*Select
                  Type</div>
              </div>



              <div class="col-md-3">
                <Label>Model</Label>
                <input type="text" class="form-control" placeholder="Enter Model" formControlName="Model" name="Model"
                  required>
                <div *ngIf=" devicetypeform.controls['Model'].value == '' && press == true" class="errorColor">*Select
                  Model</div>
              </div>
              <div class="col-md-3">
                <Label>Alias Name</Label>
                <input type="text" class="form-control" placeholder="Enter Name" formControlName="AliasName"
                  name="AliasName" required>
                <div *ngIf=" devicetypeform.controls['AliasName'].value == '' && press == true" class="errorColor">
                  *Select Alias Name</div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <span class="formdetails">Thershold</span>
                  <select class="form-select" formControlName="IsThresholdsRequired"
                    placeholder=" Is Thresholds Required">
                    <option selected value="" disabled> Is Thresholds Required</option>
                    <option value="true">
                      <li><span class="dropdown-item-text">Yes</span></li>
                    </option>
                    <option value="false">
                      <li><span class="dropdown-item-text">No</span></li>
                    </option>
                  </select>
                  <div *ngIf=" devicetypeform.controls['IsThresholdsRequired'].value == '' && press == true" class="errorColor">*Required</div>
                </div>
                <div class="col-md-2">
                  <span class="formdetails">IMEI Autogenerated</span>
                  <select class="form-select" formControlName="IsIMEIAutogenerated"
                    placeholder=" Is IMEI Autogenerated">
                    <option selected value="" disabled> Is IMEI Autogenerated</option>
                    <option value="true">
                      <li><span class="dropdown-item-text">Yes</span></li>
                    </option>
                    <option value="false">
                      <li><span class="dropdown-item-text">No</span></li>
                    </option>
                  </select>
                  <div *ngIf=" devicetypeform.controls['IsIMEIAutogenerated'].value == '' && press == true" class="errorColor">*Required</div>

                </div>
               
                <div class="col-md-2">
                  <span class="formdetails">SIM No Required</span>
                  <select class="form-select" formControlName="IsSIMNumberRequired"
                    placeholder=" SIM No Required">
                    <option selected value="" disabled>SIM No</option>
                    <option value="true">
                      <li><span class="dropdown-item-text">Yes</span></li>
                    </option>
                    <option value="false">
                      <li><span class="dropdown-item-text">No</span></li>
                    </option>
                  </select>
                  <div *ngIf=" devicetypeform.controls['IsSIMNumberRequired'].value == '' && press == true" class="errorColor">*Required</div>

                </div>
                <div class="col-md-2">
                  <span class="formdetails">NotificationRequired</span>
                  <select class="form-select" formControlName="IsNotificationRequired"
                    placeholder=" Is Notification Required">
                    <option selected value="" disabled> Is Notification Required</option>
                    <option value="true">
                      <li><span class="dropdown-item-text">Yes</span></li>
                    </option>
                    <option value="false">
                      <li><span class="dropdown-item-text">No</span></li>
                    </option>
                  </select>
                  <div *ngIf=" devicetypeform.controls['IsNotificationRequired'].value == '' && press == true" class="errorColor">*Required</div>

                </div>
              </div>
            </div><br>
            <div class="button" style="text-align: right;"><button class="btn btn-anvayaaYellow" *ngIf="saveddetails"
                (click)="savedtype()">Save</button></div>
            <div class="button" style="text-align: right;"><button class="btn btn-anvayaaYellow" *ngIf="updateddetails"
                (click)="updatedtype()">Update</button></div>
          </form>
          <div class="mt-5">
            <table class="table table-striped table-hover">
              <thead class="tablehead">
                <tr>
                  <th scope="col" style="text-align: center;">S.No</th>
                  <th scope="col" style="text-align: center;">Type</th>
                  <th scope="col" style="text-align: center;">Model</th>
                  <th scope="col" style="text-align: center;">Thresholds</th>
                  <th scope="col" style="text-align: center;">IMEI Autogenerated</th>
                  <th scope="col" style="text-align: center;">SIM No</th>
                  <th scope="col" style="text-align: center;">Notification</th>
                  <th scope="col" style="text-align: center;">Alias Name</th>
                  <th scope="col" style="text-align: center;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="tablecur" *ngFor="let Customers of this.dcustomers;let i=index">
                  <td style="text-align: center;">{{i+1}}</td>
                  <td style="text-align: center;">{{Customers?.Type}}</td>
                  <td style="text-align: center;">{{Customers?.Model}}</td>
                  <td style="text-align: center;">{{Customers?.IsThresholdsRequired}}</td>
                  <td style="text-align: center;">{{Customers?.IsIMEIAutogenerated}}</td>
                  <td style="text-align: center;">{{Customers?.IsSIMNumberRequired}}</td>
                  <td style="text-align: center;">{{Customers?.IsNotificationRequired}}</td>
                  <td style="text-align: center;">{{Customers?.AliasName}}</td>
                  <td style="text-align: center;">

                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" title="Update">
                      <i class="fa fa-edit" (click)="dexistingTableData(Customers)" aria-hidden="true"></i>
                    </button>&nbsp;
                    <button class="btn btn-danger" (click)="confirmationToRemovedevice(Customers)" title="Remove"
                      data-bs-toggle="modal" data-bs-target="#deviceexampleModal"><i class="fa fa-trash-o"
                        aria-hidden="true"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tab-pane fade show" id="nav-profiles" role="tabpanel" aria-labelledby="nav-profiles-tab">

        <div class="col-md-12 row card">
          <div class="card-header" style="text-align: center;">
            <strong>Devices & Features Type</strong>
          </div>
          <form [formGroup]="deviceandfeaturetypeform">
            <div class=" row card-Body">
              <div class="col-md-6">
                <span class="formdetails">Device</span>
                <select class="form-select" formControlName="DeviceID" aria-label="Default select example">
                  <option selected value="" disabled>Select Device</option>
                  <option *ngFor="let devicedetails of dcustomers" value="{{devicedetails.DeviceID}}">
                    {{devicedetails.Model}}</option>
                </select>
                <div *ngIf=" deviceandfeaturetypeform.controls['DeviceID'].value == '' && dnfpress == true"
                  class="errorColor">*Select Device</div>
              </div>
              <div class="col-md-3">
                <Label>Features</Label>
                <ng-multiselect-dropdown value='FeatureID' formControlName="Features" [settings]="dropdownSettings"
                  [data]="customer"  [(ngModel)]="selectedItems"  required>
                </ng-multiselect-dropdown>
                <div *ngIf=" deviceandfeaturetypeform.controls['Features'].value == '' && dnfpress == true"
                  class="errorColor">*Select Features</div>
              </div>

            </div><br>
            <div class="button" style="text-align: right;"><button class="btn btn-anvayaaYellow" *ngIf="savednfdetails"
                (click)="savednftype()">Save</button></div>
            <div class="button" style="text-align: right;"><button class="btn btn-anvayaaYellow"
                *ngIf="updatednfdetails" (click)="dfupdatetype()">Update</button></div>
          </form>
          <div class="mt-5">
            <table class="table table-striped table-hover">
              <thead class="tablehead">
                <tr>
                  <th scope="col" style="text-align: center;">S.No</th>
                  <th scope="col" style="text-align: center;">Model</th>
                  <!-- <th scope="col"  style="text-align: center;">DeviceID</th> -->
                  <th scope="col" style="text-align: center;">Features</th>
                  <th scope="col" style="text-align: center;">Status</th>
                  <th scope="col" style="text-align: center;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="tablecur" *ngFor="let Customers of this.dnfcustomers;let i=index">
                  <td style="text-align: center;">{{i+1}}</td>
                  <td style="text-align: center;">{{Customers?.Model}}</td>
                  <!-- <td  style="text-align: center;">{{Customers?.DeviceID}}</td> -->
                  <!-- <td style="text-align: center;">{{Customers?.Features[0]?.Feature}}</td> -->
                  
                  <td  style="text-align: center;"><span *ngFor="let Feature of Customers.Features ;let j=index">{{Feature.Feature}}{{j === Feature.length - 1 ? '' : ', '}}</span></td>
                  <td style="text-align: center;">{{Customers?.Status}}</td>
                  <td style="text-align: center;">
                    <button type="button" class="btn btn-primary" title="Update">
                      <i class="fa fa-edit" (click)="dandfexistingTableData(Customers)" aria-hidden="true"></i>
                    </button>&nbsp;
                    <button class="btn btn-danger" title="Remove" (click)="confirmationToRemove(Customers)"
                      data-bs-toggle="modal" data-bs-target="#removeRole"><i class="fa fa-trash-o"
                        aria-hidden="true"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
    <!-- Remove D&F  Modal -->
    <div class="modal fade" id="removeRole" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Confirmation To Remove</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">
            Confirm to Remove Device & Feature
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="removerole('InActive',this.dnfcustomers)"> Delete </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Remove D  Modal -->
    <div class="modal fade" id="deviceexampleModal" tabindex="-1" aria-labelledby="deviceexampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deviceexampleModalLabel">Confirmation To Remove</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Confirm to Remove Device
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="removedevice('InActive',this.dcustomers)"> Delete </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Remove f  Modal -->
    <div class="modal fade" id="featureexampleModal" tabindex="-1" aria-labelledby="featureexampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="featureexampleModalLabel">Confirmation To Remove</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Confirm to Remove Feature
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="removefeature('InActive',this.customer)"> Delete </button>
          </div>
        </div>
      </div>
    </div>
    


  </div>