import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-partner-revenue',
  templateUrl: './update-partner-revenue.component.html',
  styleUrls: ['./update-partner-revenue.component.css']
})
export class UpdatePartnerRevenueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
