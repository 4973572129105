import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../users.service'
import { Router, Routes, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';




@Component({
  selector: 'app-customer-operations',
  templateUrl: './customer-operations.component.html',
  styleUrls: ['./customer-operations.component.css']
})
export class CustomerOperationsComponent implements OnInit {
  customerPackageDetailsData: any = []
  CustRecID: any;
  availbleCities:any = []
    page:number=1;
  pageLimit=10;
  totalCount:any
  paginationdata:any
  cityID:string
  filterstatus:string
  constructor(private userservice: UsersService, private route: Router, private spinner: NgxSpinnerService, private Router: Router, private activatedRoute: ActivatedRoute, private UsersService: UsersService, private date: DatePipe) { }

  ngOnInit(): void {



    this.customerPackageDetails();
    this.getAvailableCityDetails()
  }


  serviceArea(event:any){

        this.cityID = event.target.value
        this.page = 1
        this.customerPackageDetails()    
  }

  statusFilter(event:any){
    this.filterstatus = event.target.value
    this.page = 1
        this.customerPackageDetails() 

}


  customerPackageDetails() {

      let filterOBj:any = {}
  
        filterOBj.ServiceAreaID = this.cityID,
        filterOBj.Status = this.filterstatus,
        filterOBj.pageNumber = this.page ,
        filterOBj.pageLimit = 10,
       
     
    this.spinner.show()
    this.customerPackageDetailsData = []
    this.userservice.PackageSubsciptionsDetails(filterOBj).subscribe((packageData: any) => {
      if (packageData.code == 'S001') {
        this.spinner.hide()
        this.customerPackageDetailsData = packageData.data.CustomerDetails
        this.totalCount = packageData.data.totalRecords 
        
        console.log("llllll",this.totalCount)
      } else {
        this.spinner.hide()
        alert(packageData.data)
      }
    }, (error) => {
      this.spinner.hide()
      alert(error.error.data)
    })
  }


  getAvailableCityDetails(){
    this.spinner.show();
    this.userservice.viewallcitiestable({}).subscribe((response:any)=>{
      if(response.code == "S001"){
       
        this.availbleCities=response?.data.map((citiesObj:any)=>{
          return { seviceAreaName:citiesObj.CityName,serviceAreaID:citiesObj.CityID}
        })
  
         

        
}      },(error)=>{
      this.spinner.hide();
      alert(error.error.data)
    })
  }


  onTableDataChange(event:any){
    
    this.page = event
    this.customerPackageDetails()
  }

  detailsPage(data: any) {

    console.log("custrec id", data)
    this.route.navigate(['Dashboard/customeroperations/customerDetailsScreen'], { queryParams: { CustRecID: data.CustRecID } })
  }
}
