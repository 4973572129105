<div >

</div>


   
 <!-- <div  class="alertBox card">

    <div class="card-header" style="text-align: center; background-color: purple;color: white;">
        
                <h4>Emergency Alert</h4>
           
    </div>
    <div class="m-2">
        <div class="card m-2">
            <div class="card-body">
                <table class="table card-body">
                    <tr>
                        <td class="fw-bold"> Customer Name</td>
                        <td>:</td>
                        <td class="text-center" style="color:red">{{notificationstobeshownasalert?.notification?.customData?.Details?.Name}}</td>
                    </tr>
                    <tr>
                        <td class="fw-bold">Message</td>
                        <td>:</td>
                        <td class="text-center" style="color:red">{{notificationstobeshownasalert?.notification?.customData?.NotificationFor}}</td>
                    </tr>
                  
                </table>
            </div>
          </div>
          <div class="card-footer" style="text-align: end;"><button class="btn btn-anvayaaYellow" type="button" (click)="emergencynavigation()">Re-direct..</button></div>
          
    </div>
    
  </div> -->

<router-outlet></router-outlet>