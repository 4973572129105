import { Component } from '@angular/core';

@Component({
  selector: 'app-transactiondetailsview',
  templateUrl: './transactiondetailsview.component.html',
  styleUrls: ['./transactiondetailsview.component.css']
})
export class TransactiondetailsviewComponent {

}
