<div class="bodycontent">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <h3>Request Details</h3>
                    <table class=" table bordered ">
                        <tbody>
                            <tr>
                                <td>Request ID </td>
                                <td>:</td>

                                <td class="reqIDStyle">{{reqID}}</td>
                            </tr>
                            <tr>

                                <td>Requested For</td>
                                <td>:</td>
                                <td *ngIf="requestDetailsData &&requestDetailsData.AliasName ">
                                    {{requestDetailsData.AliasName}}</td>
                            </tr>
                            <tr *ngIf="requestDetailsData&&requestDetailsData.ServiceAreaName">

                                <td>Requested City</td>
                                <td>:</td>
                                <td  *ngIf="requestDetailsData&&requestDetailsData.ServiceAreaName">{{requestDetailsData.ServiceAreaName}}</td>

                            </tr>
                            <!-- <tr *ngIf="requestDetailsData&&requestDetailsData.ServiceAreaName">

                                <td>Requested City</td>
                                <td>:</td>
                                <td></td>

                            </tr> -->
                            <tr>

                                <td>Service Price</td>
                                <td>:</td>
                                <td *ngIf="requestDetailsData&&requestDetailsData.TotalPrice">
                                    {{requestDetailsData.TotalPrice}}</td>
                            </tr>
                            <tr>

                                <td>Registered Date</td>
                                <td>:</td>
                                <!-- {{requestDetailsData.CustomerDetails.CreatedDate}} -->
                                <td *ngIf="requestDetailsData&&requestDetailsData.CustomerDetails">
                                    {{(requestDetailsData.CustomerDetails.CreatedDate)*1000|date:'dd/MM/YYYY'}}</td>
                            </tr>
                            <tr>
                                <td>Requested Date</td>
                                <td>:</td>
                                <!-- {{requestDetailsData.CustomerDetails.CreatedDate}} -->
                                <td *ngIf="requestDetailsData&&requestDetailsData.RequestedDate">
                                    {{(requestDetailsData.RequestedDate)*1000|date:'dd/MM/YYYY'}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="col-md-4">
                    <h3>Customer Details</h3>
                    <table class="table ">

                        <tbody>
                            <tr >
                                <td>Beneficiary Name</td>
                                <td>:</td>
                                <td *ngIf="requestDetailsData&&requestDetailsData.CustomerDetails && requestDetailsData.CustomerDetails.Beneficiaries.length>0 ">
                                    {{requestDetailsData.CustomerDetails.Beneficiaries[0].Name}}</td>
                            </tr>
                            <tr >
                                <td>Beneficiary Mobile</td>
                                <td>:</td>
                                <td *ngIf="requestDetailsData&&requestDetailsData.CustomerDetails&&requestDetailsData.CustomerDetails.Beneficiaries.length>0">{{requestDetailsData.CustomerDetails.Beneficiaries[0].MobileNumber}}</td>
                            </tr>
                            <tr >
                                <td>Sponser Name</td>
                                <td>:</td>
                                <td *ngIf="requestDetailsData&&requestDetailsData.CustomerDetails">{{requestDetailsData.CustomerDetails.Name}}</td>
                            </tr>
                            <tr     >
                                <td >Sponser Mobile</td>
                                <td>:</td>
                                <td *ngIf="requestDetailsData&&requestDetailsData.CustomerDetails">{{requestDetailsData.CustomerDetails.MobileNumber}}</td>
                            </tr>



                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>



<div [formGroup]="CompleteTheJob"  [hidden]="CM=='CM'" *ngIf="Status=='Processing'">
    <div class="row">


        <div class="col-md-4">

        </div>
        <div class="col-md-4">
            <label class="rqDetails"><u>Check On Complete Job</u></label>
       
        </div>
        <div class="col-md-4">

        </div>
    </div>
    

    <div class="row serviceName">
        <div class="col-md-6">
            <div class="row" style="margin-left:40%">
                <div class="col-md-5">
                    <label class="fontstyle">Job Start Date </label>:
                    <input class="inputEndDate" formControlName="StartDate" readonly type="datetime-local">
                </div>
                <div class="col-md-2">

                </div>
                <div class="col-md-5">
                    <label class="fontstyle">Job End Date </label>:
                    <input formControlName="JobEndDate" type="date" class="inputEndDate" required="">
                    <span class="error"
                        *ngIf="this.CompleteTheJob.controls['JobEndDate'].status=='INVALID'&&completeJobBtn==true">*Enter
                        EndDate</span>
                </div>
                <div class="row tasks">
                    <div class="col-md-3">
                        <h5 class="pad10px fontstyle">Rating </h5>

                    </div>
                    <div class="col-md-8" (change)="ratings($event)">
                        <div class="rate" >
                            <input type="radio" id="star5" name="rate" value="5" />
                            <label for="star5" title="text">5 stars</label>
                            <input type="radio" id="star4" name="rate" value="4" />
                            <label for="star4" title="text">4 stars</label>
                            <input type="radio" id="star3" name="rate" value="3" />
                            <label for="star3" title="text">3 stars</label>
                            <input type="radio" id="star2" name="rate" value="2" />
                            <label for="star2" title="text">2 stars</label>
                            <input type="radio" id="star1" name="rate" value="1" />
                            <label for="star1" title="text">1 star</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <label>Feedback :</label>
                            <textarea formControlName="Comments" id="w3review"
                                style="background-color:#ECECEC; width: 100%;height: 100px;" required>
                        </textarea>
                      
                        </div>
                        <span class="error"
                        *ngIf="this.CompleteTheJob.controls['Comments'].status=='INVALID'&&completeJobBtn==true">*Enter Comments
                        </span>
                    </div>



                </div>


            </div>
        </div>
    </div>

</div>

<div class="row" [formGroup]="extendJob">

            
    <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog ">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="DropJob">Extend Job</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="container allcp-form ">
                    <div class="row">
                        
                        <div class='col-sm-3'>
                            EndDate :
                            <div id='Remark'>
                                <input type="date" formControlName="EndDate" value="" required style="width: 225px;"  >
                          
                            </div>
                        </div>
                     


                    </div>
                 
                </div>



            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"
                    data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary"
                    (click)="CheckOnCompletedJobs('InProgress')">Extend JOb</button>
            </div>
        </div>
    </div>
</div>


</div>

<div class="row" [formGroup]="dropJob" *ngIf="Status=='Processing'">
    <div class="col-md-1"> </div>
    <div class="col-md-2">
        <div class="modal fade" id="dropjobmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="DropJob">Complete Job</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="container allcp-form ">
                            <div class="row">
                                <div class='col-sm-3' >
                                    EndDate :
                                    <div id='Remark'>
                                        <input type="date" formControlName="EndDate" value="" required
                                            style="width: 225px;">

                                    </div>
                                </div>
                                <div class='col-sm-3' style="margin-left: 10%;">
                                    Remarks :
                                    <div id='Remark'>
                                        <textarea formControlName="Comments" type='text' class="form-control" required
                                            style="width: 225px;" placeholder="Enter Comment">
                              </textarea>
                                    </div>
                                </div>

                            </div>
                            <div class="row" style="padding-top: 20px ;">
                                <!-- 
                                <div class='col-sm-3'>
                                    EndDate :
                                    <div id='Remark'>
                                        <input type="date" value="" required style="width: 225px;"  >
                                  
                                    </div>
                                </div> -->
                             

                                <!-- <div class='col-sm-3'>
                                    Do You Want To Find New Vendor :
                                    <div id='date' style="margin-top:20px ;">
                                        Yes &nbsp;: &nbsp;<input formControlName="Assigned_To_Partner" type="radio"
                                            value="Yes">&nbsp;&nbsp;
                                        No &nbsp;:&nbsp; <input type="radio" formControlName="Assigned_To_Partner"
                                            value="No">
                                    </div>
                                </div> -->
                               
                            </div>
                        </div>



                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" (click)="ConfirmIfJobStart('Dropped')">Complete
                            Job</button>
                    </div>
                </div>
            </div>
        </div>
      
        
                
    </div>

   
    <div [hidden]="CM=='CM'"  class="row" style="margin-left: 10%;">
        <div class="col-md-4">
            <button class="btn btn-info" id="DropJob" data-bs-toggle="modal" data-bs-target="#dropjobmodal">Complete the
                Job</button>
        </div>
        <div class="col-md-4">
<button class="btn btn-info "  id="DropJob" data-bs-toggle="modal"
                data-bs-target="#exampleModal1"  >Extend the Job</button>
        </div>
        <!-- <div class="col-md-4">
            <button class="btn btn-info " (click)="CheckOnCompletedJobs('Completed')">Complete the Job</button>

        </div> -->
    </div>
 <p>



 </p>
</div>

  

<div class="AssignedVendor" [hidden]="vendorName==null || Status=='Processing'">
    <div class="col-md-6">
        <h3>Assigned Vendor:{{vendorName}}</h3>
    </div>
</div>
<div class="bodycontent "
    [hidden]="serviceType=='Onetime' ||requestDetailsData?.AssignedVendor.length>0 || Status=='Processing'">
    <div class="card panel-default">
        <div class="card-body">
            <table class="table table-striped table-hover table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <!-- <th scope="col">Mobile NO</th> -->
                        <th scope="col">ID Type</th>
                        <th scope="col">Profile</th>
                        <th scope="col">Vendor Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Accept</th>
                        <th scope="col">Reject</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let VendorData of vendorData;let i=index">

                        <td>{{VendorData.Name}}</td>
                        <!-- <td>{{VendorData.PrimaryMobileNo}}</td> -->
                        <td>{{VendorData.IdType}}</td>
                        <td> <button class="btn btn-primary" (click)="showView(i)">View</button></td>
                        <td>{{VendorData.VendorData.VendorName}}</td>
                        <td>{{VendorData.Status}}</td>
                        <td> <button class="AcceptBtn"
                                (click)="profileActed(VendorData.ProfileID,'Approved',VendorData.VendorID)"
                                [disabled]="vendorStatus=='Yes'">Accept</button>
                        </td>
                        <td> <button class="rejectBtn"
                                (click)="profileActed(VendorData.ProfileID,'Rejected',VendorData.VendorID)"
                                [disabled]="VendorData.Status==='Rejected'">Reject</button> </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>


</div>
<div class=" row">
    <div class="modal fade " id="UpdateStatus" role="dialog " aria-hidden="true " data-backdrop="static "
        data-keyboard="false " aria-labelledby="fieldEmployeeModalLabel " style="padding-bottom: 50px;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content" style="height: auto; width: auto;">
                <div class="modal-header model">
                    <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
                    <h4 class="titlevendor modal-title  text-center ">Vendor Status Details</h4>
                </div>
                <form name="UpdateStatusForm" [formGroup]="vendorUpdate">
                    <div class="modal-body">
                        <div class="container allcp-form ">
                            <div class="row">
                                <div class='col-sm-3'>
                                    Job Status :
                                    <div id='Status'>
                                        <label for="Status" class="field select">
                                            <select formControlName="Status" style="height: 40px;"
                                                (change)="alertForJobs($event)" required>
                                                <option selected disabled value=""> ---Select Status---</option>

                                                <option value="Job_Started"> Job Started</option>
                                                <option value="Job_Completed"> Job Completed</option>
                                                <option value="Vendor_Assigned">Vendor Assigned </option>
                                                <option value="Job_Cancelled"> Job Cancelled</option>
                                            </select>
                                            <i class="arrow double"></i>
                                        </label>
                                    </div>
                                </div>
                                <div class='col-sm-3' [hidden]="this.vendorUpdate.value['Status']!='Job_Started'">
                                    Select Start Date :

                                    <div class="input-group date">
                                        <input formControlName="StartDate" type='datetime-local' class="form-control"
                                            placeholder="Select Start Date" required>


                                    </div>

                                </div>
                                <div class='col-sm-3' [hidden]="this.vendorUpdate.value['Status']!='Job_Completed'">
                                    Select End Date :

                                    <div class="input-group date">
                                        <input formControlName="EndDate" (change)="endDate($event)"
                                            type='datetime-local' class="form-control" placeholder="Select EndDate ">


                                    </div>

                                </div>
                                <div class='col-sm-3' [hidden]="this.vendorUpdate.value['Status']!='Job_Completed'">
                                    Day Served :

                                    <div class="input-group date">
                                        <input [hidden]="this.vendorUpdate.value['Status']!='Job_Completed'"
                                            formControlName="ActualDaysServed" type='number' class="form-control"
                                            placeholder="ActualDaysServed ">


                                    </div>

                                </div>
                                <div class='col-sm-2' [hidden]="this.vendorUpdate.value['Status']!='Job_Started'">
                                    Price :

                                    <div id='price'>
                                        <input type='text' class="form-control" formControlName="Price" maxlength="5"
                                            placeholder="Enter Price" required>

                                    </div>
                                    <!-- <div class="row"
                                        *ngIf="this.vendorUpdate.value['Price'].length<3    && this.vendorStatusbtn==true">
                                        <p>*Enter Minimun 3 </p>
                                    </div> -->
                                </div>
                                <div class='col-sm-3' [hidden]="this.vendorUpdate.value['Status']!='Job_Started'">
                                    Job Status :
                                    <div>
                                        <label for="PriceFor" class="form-control field select">
                                            <select formControlName="PriceFor" style="height: 40px;" required>
                                                <option selected disabled value=""> ---Select Tariff Status---</option>
                                                <option value="Daily"> Daily</option>
                                                <option value="Monthly">Monthly </option>
                                            </select>
                                            <i class="arrow double"></i>

                                        </label>
                                    </div>
                                </div>


                            </div>
                            <div class="row" style="padding-top: 20px ;">

                                <div class='col-sm-2'>
                                    Remarks :
                                    <div id='Remark'>
                                        <textarea formControlName="StatusRemarks" type='text' class="form-control"
                                            required style="width: 225px;" placeholder="Enter Remark">
                                    </textarea>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </form>
                <div class="modal-footer">
                    <button type="button" class="backbtn" (click)="vendorStatusUpdate()">Save </button>
                    <button type="button" class="closeBtn" data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="bodycontent" *ngIf="requestDetailsData?.AssignedVendor.length>0" [hidden]="Status=='Processing'">
    <div class="card panel-default" >
        <div class="card-body">
            <table class="table table-striped table-hover table-bordered">
                <thead>
                    <tr>
                        <th scope="col">START DATE</th>
                        <th scope="col">END DATE</th>
                        <th scope="col">VENDOR NAME</th>
                        <th scope="col">JOB STATUS</th>
                        <th scope="col">TARIF TYPE</th>
                        <th scope="col">AMOUNT</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let VendorData of requestDetailsData.AssignedVendor;let i=index">

                        <td *ngIf="VendorData.StartDate!=null">{{(VendorData.StartDate)*1000 | date:'dd/MM/YYYY' }}</td>
                        <td *ngIf="VendorData.StartDate==null"></td>
                        <td *ngIf="VendorData.EndDate!=null">{{(VendorData.EndDate)*1000 | date:'dd/MM/YYYY'}}</td>
                        <td *ngIf="VendorData.EndDate==null"></td>
                        <td>{{VendorData.VendorName}}</td>

                        <td>{{(VendorData.Status).replace('_',' ')}}</td>
                        <td>{{VendorData.PriceFor}}</td>
                        <td>{{VendorData.Price}}</td>


                    </tr>

                </tbody>
            </table>
        </div>

    </div>
</div>
<!-- <button class="ScheduleJob btn btn-primary" *ngIf="requestDetailsData?.AssignedVendor.length>0"  data-bs-toggle="modal" 

    data-bs-target="#UpdateStatus"   [hidden]="this.requestDetailsData.Status=='Pending_Customer_Approval'|| Status=='Processing' ||this.requestDetailsData.AssignedVendor.slice(-1)[0].Status=='Scheduled'">Schedule Job
</button> -->

<footer class="footer" [hidden]="Status=='Processing'">

</footer>
<ngx-spinner type="ball-scale-multiple">loading...</ngx-spinner>