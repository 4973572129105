import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-nishchint-corporate-details',
  templateUrl: './nishchint-corporate-details.component.html',
  styleUrls: ['./nishchint-corporate-details.component.css']
})
export class NishchintCorporateDetailsComponent {

  companyID: string | null = '';
  companyDetails: any;

  constructor(private userservice: UsersService, private datePipe: DatePipe, private route: Router, private spinner: NgxSpinnerService, private activatedRoute: ActivatedRoute) { }
  ngOnInit(): void {
    this.companyID = this.activatedRoute.snapshot.queryParamMap.get("companyID");
    this.viewCorporateDetails();

  }

  viewCorporateDetails(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      const params = { companyID: this.companyID }
      this.userservice.getNishchintCorporateDetailsById(params).subscribe(
        (response) => {
          this.companyDetails = response?.data;
          this.spinner.hide();
        },
        (error) => {
          console.error("Error fetching token:", error);
          this.spinner.hide();
          reject(error);
        }
      );
    });
  }


  approveOrRejectCorporate(status: string) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      const planIDs = this?.companyDetails?.companyPackageDetails?.map((packages: any) => packages?.planDetails[0]?.planID);

      const payload = { companyID: this.companyID, planIDs: planIDs, status: status }
      this.userservice.approveOrRejectNishchintCorporate(payload).subscribe(
        (response) => {
          alert(response?.message);
          this.gotoMytasks();
          this.spinner.hide();
        },
        (error) => {
          console.error("Error fetching token:", error);
          this.spinner.hide();
          reject(error);
        }
      );
    });
  }

  getFormattedDate(epoch: number): string {
    return new Date(epoch).toLocaleDateString('en-GB'); // Converts to "10/02/2025"
  }

  viewDocument(type: string) {
    let documentUrl = '';

    if (type === 'gst') {
      documentUrl = this?.companyDetails?.GSTAttachment;
    } else if (type === 'pan') {
      documentUrl = this?.companyDetails?.PANAttachment;
    } else if (type === 'mou') {
      documentUrl = this?.companyDetails?.mou;
    }

    if (documentUrl) {
      window.open(documentUrl, '_blank');
    }
  }

  gotoMytasks() {
    this.route.navigate(['Dashboard/Task/MyTask'])
  }
}
