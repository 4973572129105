<div class="bodycontent">
    <div class="row">
        <div class="col-md-2">
            <button class="btn anvayaaButton" (click)="onBackBtnClick()" >Back</button>
        </div>
    </div>
    <div class="row">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th>CustomerID</th>
                    <th>Name</th>
                    <th>Registration Date</th>
                    <th>Package Name</th>
                    <th>Location</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let customers of AllDementiaCustomers">
                    <td id="CustomerId" (click)="onSelectedCustomer($event)">{{customers.CustRecID}}</td>
                    <td>{{customers.CustomerDetails.Name}}</td>
                    <td>{{customers.SubscriptionCreatedDate*1000 |date:"dd-MMM-yyyy"}}</td>
                    <td>{{customers.AliasName}}</td>
                    <td>{{customers.ServiceAreaName}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>


<ngx-spinner type="ball-scale-multiple">loading...</ngx-spinner>
