import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-nishchint-wellbeing-customer-details',
  templateUrl: './nishchint-wellbeing-customer-details.component.html',
  styleUrls: ['./nishchint-wellbeing-customer-details.component.css']
})
export class NishchintWellbeingCustomerDetailsComponent {

  companyID: string | null;
  customersDetails: any = [];
  agentsDetails: any = [];

  constructor(private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService, private userservice: UsersService, private route: Router) { }


  ngOnInit(): void {
    this.companyID = this.activatedRoute.snapshot.queryParamMap.get("companyID");
    this.getCustomersDetails();
    this.getAgentsDetails();
  }

  getAgentsDetails() {
    let EmpParmas: any = {}
    EmpParmas.Type = "customercareemployee";

    this.spinner.show()

    this.userservice.GetEmployees(EmpParmas).subscribe((Response) => {

      if (Response.code == "S001") {
        this.spinner.hide()
        this.agentsDetails = Response.data
      } else {
        this.spinner.hide()
        alert(Response.message)
      }
    }, (error) => {
      this.spinner.hide()
      alert(error.error.message)
    })
  }

  onAgentChange(event: any, customerId: string) {
    const selectedEmployeeID = event.target.value;
    this.userservice.assignAgentToNishchintCustomers({
      userID: customerId,
      anvayaaEmployeeID: selectedEmployeeID,
      isAssinedTo: "SE",
    }).subscribe(
      (response) => {
        if (response?.code == "S001" || response?.data != undefined) {
          alert("Agent assigned successfully:");
          this.getCustomersDetails();
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }

  getCustomersDetails() {
    this.spinner.show()
    this.userservice.getNishchintWellbeingCustomersDetails({ companyID: this.companyID?.toString() }).subscribe((response: any) => {
      console.log('####',response)
      if (response?.data[0]?.customerDetails?.length > 0) {
        this.customersDetails = response.data[0]?.customerDetails;
        this.spinner.hide()
      } else {
        this.customersDetails = [];
        alert('No data found');
        this.spinner.hide()
      }
    }, (error) => {
      this.spinner.hide()
      alert(error.error.data)
    })
  }
}
