<p>customer-details works!</p>

<div>
    <table>
        <!-- <tr><td>{{}}</td><td>{{}}</td></tr>
        <tr><td>{{}}</td><td>{{}}</td></tr>
        <tr><td>{{}}</td><td>{{}}</td></tr>
        <tr><td>{{}}</td><td>{{}}</td></tr>
        <tr><td>{{}}</td><td>{{}}</td></tr>
        <tr><td>{{}}</td><td>{{}}</td></tr>
        <tr><td>{{}}</td><td>{{}}</td></tr>
        <tr><td>{{}}</td><td>{{}}</td></tr>
        <tr><td>{{}}</td><td>{{}}</td></tr> -->
    </table>
</div>
