import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '../users.service';
import * as moment from 'moment';

@Component({
  selector: 'app-nishchint-onboarding-caremanager-assignment',
  templateUrl: './nishchint-onboarding-caremanager-assignment.component.html',
  styleUrls: ['./nishchint-onboarding-caremanager-assignment.component.css']
})
export class NishchintOnboardingCaremanagerAssignmentComponent {
  companyID: string | null;
  customersDetails: any = [];
  agentsDetails: any = [];
  onboardingDate: any;

  constructor(private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService, private userservice: UsersService, private route: Router) { }

  ngOnInit(): void {
    this.companyID = this.activatedRoute.snapshot.queryParamMap.get("companyID");
    this.getCustomersDetails();
    this.getAgentsDetails();
  }

  getAgentsDetails() {
    let EmpParmas: any = {}
    EmpParmas.Type = "FieldEmployee";

    this.spinner.show()

    this.userservice.GetEmployees(EmpParmas).subscribe((Response) => {

      if (Response.code == "S001") {
        this.spinner.hide()
        this.agentsDetails = Response.data
      } else {
        this.spinner.hide()
        alert(Response.message)
      }
    }, (error) => {
      this.spinner.hide()
      alert(error.error.message)
    })
  }
 
  updateAssignmentDate(event:any) {
    this.onboardingDate = event?.target?.value;
}

 
  onAgentChange(event: any, customerId: string) {
    const selectedCMID = event.target.value;
    if(this.onboardingDate === ''|| this.onboardingDate === undefined) {
      alert('Please select schedule date');
      return;
    }
    this.userservice.assignCaremanagerToNishchintCustomers({
      userID: customerId,
      careManagerID: selectedCMID,
      onboardingDate: moment(this.onboardingDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
    }).subscribe(
      (response) => {
        if (response?.code == "S001" || response?.data != undefined) {
          alert("Caremanager assigned successfully");
          this.getCustomersDetails();
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }

  getCustomersDetails() {
    this.spinner.show()
    this.customersDetails = [];
    this.userservice.getNishchintOnboardingCustomersDetails({ companyID: this.companyID?.toString() }).subscribe((response: any) => {
      if (response?.data?.length > 0) {
        this.customersDetails = response.data;
        this.spinner.hide()
      } else {
        this.customersDetails = [];
        alert('No data found');
        this.spinner.hide()
      }
    }, (error) => {
      this.spinner.hide()
      alert(error.error.data)
    })
  }

}
