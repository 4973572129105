import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { UsersService } from '../../users.service'
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, Form } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-asersmasters',
  templateUrl: './asersmasters.component.html',
  styleUrls: ['./asersmasters.component.css']
})
export class AsersmastersComponent implements OnInit {
  public featuretypeform!: FormGroup
  public devicetypeform!: FormGroup
  public deviceandfeaturetypeform!: FormGroup
  createdevice: any = []
  createddevice: any = []
  customer: any = []
  dcustomers: any = []
  dnfcustomers: any = []
  createdeviceobj: any
  createfeatureobj: any
  updatefdetails: any = false
  savefdetails: any = true
  updateddetails: any = false
  saveddetails: any = true
  savednfdetails: any = true
  updatednfdetails: any = false
  devicesData: any = []
  press: any = false
  fpress: any = false
  dnfpress: any = false
  deatilsandfeatures: any = []
  dnfobj: any
  dropdownSettings: IDropdownSettings;
  selectedItems: any = [];
  item_id: any
  IDropdownSettings: any
  dataOfTasks: any = []
  fid: any
  dnfremove: any
  dremove: any
  dtyperemove: any
  dnfremovefid: any
  dnfremoveconfigid:any
  dmodelremove: any
  removefeatureanddeviceobj: any
  removedeviceobj: any
  pUrl: any = false
  ExDoc: any
  fremove:any
  removefeatureeobj:any
  ftyperemove:any
  faliasnameremove:any
  ffeatureremove:any
  fisthersholdremove:any
  fthersholdminremove:any
  fthersholdmaxremove:any
  fisrequiredremove:any
  fisgoalremove:any
  fimageremove:any
  featureid:any
  deviceid:any
  // baseUrl: string = 'path/to/your/images/';
  imageDoc: any;
  dThresold : any;
  dIMeiNumber: any;
  dSimNumber: any;
  dNotification : any;
  dId : any;
  PrescriptionImageFiles: any;
  photo: any;

  constructor(private userservice: UsersService, private datePipe: DatePipe, private route: Router, private snap: ActivatedRoute, private spinner: NgxSpinnerService, private formBuilder: FormBuilder) { }
  ngOnInit(): void {
    this.submitftypeformbuilder()
    this.fdetails()
    this.submitdtypeformbuilder()
    this.ddetails()
    this.submitdandftypeformbuilder()
    this.dandfdetails()
    //this is for multi-select field
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'FeatureID',
      textField: 'Feature',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 6,
      allowSearchFilter: true
    };
  }
  submitftypeformbuilder() {
    this.featuretypeform = this.formBuilder.group({
      Type: ['', [Validators.required]],
      AliasName: ['', [Validators.required]],
      Feature: ['', [Validators.required]],
      IsThreshold: [, [Validators.required]],
      ThresholdMin: [],
      ThresholdMax: [],
      IsRequired: [, [Validators.required]],
      IsGoal: [, [Validators.required]],
      Images: ['', [Validators.required]],
    })
  }
  submitdtypeformbuilder() {
    this.devicetypeform = this.formBuilder.group({
      Type: ['', [Validators.required]],
      Model: ['', [Validators.required]],
      IsThresholdsRequired: ['', [Validators.required]],
      IsIMEIAutogenerated: ['', [Validators.required]],
      IsSIMNumberRequired: ['', [Validators.required]],
      IsNotificationRequired: ['', [Validators.required]],
      AliasName:['', [Validators.required]]
    })
  }
  submitdandftypeformbuilder() {
    this.deviceandfeaturetypeform = this.formBuilder.group({
      DeviceID: ['', [Validators.required]],
      Features: ['', [Validators.required]],
    })
  }

  //this below block is for creating or saving the feature type and device type and both device and feature type..
  saveftype() {
    this.fpress = true

     console.log("dkdkdkdk",this.featuretypeform.controls['IsGoal'].value)
    if (this.featuretypeform.status == "INVALID") {
      return
    }
    
    this.createfeatureobj = {
      'Type': this.featuretypeform.value.Type,
      'AliasName': this.featuretypeform.value.AliasName,
      'Feature': this.featuretypeform.value.Feature,
      'IsThreshold': this.featuretypeform.value.IsThreshold,
      'ThresholdMin': this.featuretypeform.value.ThresholdMin,
      'ThresholdMax': this.featuretypeform.value.ThresholdMax,
      'IsRequirThresholdMaxed': this.featuretypeform.value.IsRequired,
      'IsGoal': this.featuretypeform.value.IsGoal,
      'Images': this.PrescriptionImageFiles
    }
    this.createfeatureobj.IsThreshold= this.featuretypeform.value.IsThreshold
    const UploadProfile = new FormData()
 
    UploadProfile.append('Type', this.featuretypeform.value.Type)
    UploadProfile.append('AliasName', this.featuretypeform.value.AliasName)
    UploadProfile.append('Feature', this.featuretypeform.value.Feature)
    UploadProfile.append('IsThreshold', this.featuretypeform.value.IsThreshold)
    UploadProfile.append('ThresholdMin',  this.featuretypeform.value.ThresholdMin)
    UploadProfile.append('ThresholdMax',this.featuretypeform.value.ThresholdMax)
    UploadProfile.append('IsGoal', this.featuretypeform.value.IsGoal)
    UploadProfile.append('IsRequired', this.featuretypeform.value.IsRequired,)
    for (let i = 0; i < this.photo?.length; i++) {
      UploadProfile.append('Images' + "" + [i], this.photo[i])
    }
    this.userservice.aserscreatefeatures(UploadProfile).subscribe(
      (response) => {
        if (response.code == 'S001') {
          console.log("createfeature", response.data)
          alert(response.data);
        //  this.createdevice = response.data;
          // this.submitftypeformbuilder(); // Move it here
          this.fdetails()
          this.featuretypeform.reset()
        } else if (response.code == 'S002') {
          alert(response.data);
        } else {
          alert(response.data);
        }
      },
      (error) => {
        alert(error.error.data);
      }
    );  
    this.submitftypeformbuilder();  
  }
  savedtype() {
    this.press = true

    // if (this.devicetypeform.status == "INVALID") {
    //   return
    // }
    this.createdeviceobj = {
      'Type': this.devicetypeform.value.Type,
      'Model': this.devicetypeform.value.Model,
      'IsThresholdsRequired': this.devicetypeform.value.IsThresholdsRequired,
      'IsIMEIAutogenerated': this.devicetypeform.value.IsIMEIAutogenerated,
      'IsSIMNumberRequired': this.devicetypeform.value.IsSIMNumberRequired,
      'IsNotificationRequired': this.devicetypeform.value.IsNotificationRequired,
      'AliasName': this.devicetypeform.value.AliasName,
    }
    this.userservice.aserscreatedevices(this.createdeviceobj).subscribe((response) => {
      if (response.code == 'S001') {
        this.createddevice = response.data
        alert(response.data)
        this.ddetails()
        this.devicetypeform.reset()
      }
      else if (response.code == 'S002') {
        alert(response.data)
      } else {
        alert(response.data)
      }
    }, (error) => {
      alert(error.error.data)
    })
    this.submitdtypeformbuilder()
  }
  savednftype() {
    this.dnfpress = true

    if (this.deviceandfeaturetypeform.status == "INVALID") {
      return
    }

    this.dnfobj = {
      'DeviceID': this.deviceandfeaturetypeform.value.DeviceID,
      'Features': this.deviceandfeaturetypeform.value.Features.map((i: any) => (i as { FeatureID: string }).FeatureID)

    }
    this.userservice.aserscreatedevicesandfeatures(this.dnfobj).subscribe((response) => {
      if (response.code == 'S001') {
        this.deatilsandfeatures = response.data
        alert(response.data)
        this.dandfdetails()
        this.deviceandfeaturetypeform.reset()
      }
      else if (response.code == 'S002') {
        alert(response.data)
      } else {
        alert(response.data)
      }
    }, (error) => {
      alert(error.error.data)
    })
    this.submitdandftypeformbuilder();
  }

  // disableFeatureField() {
  //   this.featuretypeform.get('Feature')!.disable();
  // }
  //updating..
  updateftype() { 
    console.log("ZZZZZZZZZZZ",this.ffeatureremove)
    // if(this.featuretypeform.value.Feature == undefined || this.featuretypeform.value.Feature== null){
    //   this.featuretypeform.value.Feature = this.ffeatureremove
    // }
    const createfeatureobj = {
      
      'FeatureID': this.fid,
      'Type': this.featuretypeform.value.Type,
      'AliasName': this.featuretypeform.value.AliasName,
      'Feature': this.featuretypeform.value.Feature,
      'IsThreshold': this.featuretypeform.value.IsThreshold,
      'ThresholdMin': this.featuretypeform.value.ThresholdMin,
      'ThresholdMax': this.featuretypeform.value.ThresholdMax,
      'IsRequired': this.featuretypeform.value.IsRequired,
      'IsGoal': this.featuretypeform.value.IsGoal,
      'Images':  this.PrescriptionImageFiles,
      'Status': 'Active'
    }

    const UpdateProfile = new FormData()
 
    UpdateProfile.append('Type', this.featuretypeform.value.Type)
    UpdateProfile.append('FeatureID', this.fid)
    UpdateProfile.append('AliasName', this.featuretypeform.value.AliasName)
    UpdateProfile.append('Feature', this.featuretypeform.value.Feature)
    UpdateProfile.append('IsThreshold', this.featuretypeform.value.IsThreshold)
    UpdateProfile.append('ThresholdMin',  this.featuretypeform.value.ThresholdMin)
    UpdateProfile.append('ThresholdMax',this.featuretypeform.value.ThresholdMax)
    UpdateProfile.append('IsGoal', this.featuretypeform.value.IsGoal)
    UpdateProfile.append('IsRequired', this.featuretypeform.value.IsRequired,)
    for (let i = 0; i < this.photo?.length; i++) {
      UpdateProfile.append('Images' + "" + [i], this.photo[i])
    }
    // for (let i = 0; i < this.photo?.length; i++) {
    //   createfeatureobj.append('Images' + "" + [i], this.photo[i])
    // }
    this.userservice.aserscreatefeatures(UpdateProfile).subscribe((response) => {
      if (response.code == 'S001') {
    // this.featuretypeform.controls['Feature'].enable();
    alert(response.data)
        this.createdevice = response.data
        this.fdetails()
          this.featuretypeform.reset()
      }
      else if (response.code == 'S002') {
        alert(response.data)
      } else {
        alert(response.data)
      }
    }, (error) => {
      alert(error.error.data)
    })
  }
  updatedtype(){
    const createfeatureobj = {
      'DeviceID': this.dId,
      'Type': this.devicetypeform.value.Type,
      'Model': this.devicetypeform.value.Model,
      'IsThresholdsRequired': this.devicetypeform.value.IsThresholdsRequired,
      'IsIMEIAutogenerated': this.devicetypeform.value.IsIMEIAutogenerated,
      'IsSIMNumberRequired': this.devicetypeform.value.IsSIMNumberRequired,
      'IsNotificationRequired': this.devicetypeform.value.IsNotificationRequired,
      'AliasName': this.devicetypeform.value.AliasName,
      'Status': 'Active'
    }
    this.userservice.aserscreatedevices(createfeatureobj).subscribe((response) => {
      if (response.code == 'S001') {
        alert(response.message)
        this.createdevice = response.data
        this.ddetails()
        this.devicetypeform.reset()
      }
      else if (response.code == 'S002') {
        alert(response.data)
      } else {
        alert(response.data)
      }
    }, (error) => {
      alert(error.error.data)
    })
  }
  dfupdatetype() {
    const createfeatureanddeviceobj = {
      'DeviceID': this.deviceid,
      'ConfigID':this.dnfremoveconfigid,
      // 'Features': this.featureid,
      'Features':this.deviceandfeaturetypeform.value.Features.map((i: any) => (i as { FeatureID: string }).FeatureID)
    }
    this.userservice.aserscreatedevicesandfeatures(createfeatureanddeviceobj).subscribe((response) => {
      if (response.code == 'S001') {
        this.createdevice = response.data
        alert(response.data)
        this.dandfdetails()
        this.deviceandfeaturetypeform.reset()
      }
      else if (response.code == 'S002') {
        alert(response.data)
      } else {
        alert(response.data)
      }
    }, (error) => {
      alert(error.error.data)
    })
    this.submitftypeformbuilder()
  }
  // this below is for binding data in the table format for each type..
  fdetails() {
    this.spinner.show()
    this.userservice.getAllFeatues().subscribe((response) => {
      this.spinner.hide()
      // console.log("em data edi aslla =>", this.customer)
      // this.customer = response.data.filter((obj: any) => {
      //   if (obj.Status == 'Active') {
      //     return obj
      //   }
      // })
      if (response.code == 'S001') {
        this.customer = response.data
        // alert(response.data)
      } else {
        alert(response.data)
      }
    }, (error) => {
      alert(error.error.data)
    })
  }
  ddetails() {
    this.spinner.show()
    this.userservice.getAllAsersDevices().subscribe((response) => {
      this.spinner.hide()
      if (response.code == 'S001') {
        this.dcustomers = response.data
        // alert(response.data)
      } else {
        alert(response.data)
      }
    }, (error) => {
      alert(error.error.data)
    })
  }
  dandfdetails() {
    this.spinner.show()
    this.userservice.getdevicesandfeaturesdetails().subscribe((response) => {
      this.spinner.hide()

      // console.log("edi kada kavalsindi manaki epudu =>", this.dnfcustomers)
      // this.dnfcustomers = response.data.filter((obj: any) => {
      //   if (obj.Status == 'Active') {
      //     return obj
      //   }
      // })
      if (response.code == 'S001') {
        this.dnfcustomers = response.data

        // alert(response.data)
      } else {
        alert(response.data)
      }
    }, (error) => {
      alert(error.error.data)
    })
  }
  //this for is to update and patch the deatils which are in table..
  existingTableData(index: any) {
    // this.disableFeatureField()

    this.savefdetails = false
    this.updatefdetails = true
    this.fid = index.FeatureID

    this.featuretypeform.patchValue({ Type: index.Type })
    this.featuretypeform.patchValue({ AliasName: index.AliasName })
    this.featuretypeform.patchValue({ Feature: index.Feature })
    this.featuretypeform.patchValue({ IsGoal: index.IsGoal })
    this.featuretypeform.patchValue({ IsRequired: index.IsRequired })
    this.featuretypeform.patchValue({ IsThreshold: index.IsThreshold })
    if (this.featuretypeform.value.IsThreshold == 'true') {
      this.featuretypeform.patchValue({ ThresholdMax: index.ThresholdMax })
    }
    if (this.featuretypeform.value.IsThreshold == 'true') {
      this.featuretypeform.patchValue({ ThresholdMin: index.ThresholdMin })
    }
    // this.featuretypeform.patchValue({ ThresholdMax: index.ThresholdMax })
    // this.featuretypeform.patchValue({ ThresholdMin: index.ThresholdMin })
    this.featuretypeform.patchValue({ Images: index.Images })
    // this.spinner.hide()
  }
  dexistingTableData(index: any) { 
    this.saveddetails = false
    this.updateddetails = true

    this.dId=index.DeviceID
    this.devicetypeform.patchValue({ Type: index.Type })
    this.devicetypeform.patchValue({ Model: index.Model })
    this.devicetypeform.patchValue({ IsThresholdsRequired: index.IsThresholdsRequired })
    this.devicetypeform.patchValue({ IsIMEIAutogenerated: index.IsIMEIAutogenerated })
    this.devicetypeform.patchValue({ IsSIMNumberRequired: index.IsSIMNumberRequired })
    this.devicetypeform.patchValue({ IsNotificationRequired: index.IsNotificationRequired })
    this.devicetypeform.patchValue({ AliasName: index.AliasName })
    // this.spinner.hide()
  }
  dandfexistingTableData(index: any) {
    this.savednfdetails = false
    this.updatednfdetails = true
    // this.featureid = []
 
 
    // console.log("zzzzxxxxxxxxxxxxxxzzzzzzzzzz", index.Features)
 
    // index.Features.map((data:any)=>{
    //   console.log(data.FeatureID)
    //   this.featureid.push(data.FeatureID)
 
    // })
   
    this.deviceid=index.DeviceID
    this.deviceandfeaturetypeform.patchValue({ "DeviceID": index.DeviceID })
    this.deviceandfeaturetypeform.patchValue({ "Features": index.Features })
  }
  //deleting from the table..(3)
  removerole(status: any, index: any) {
    this.removefeatureanddeviceobj = {
      'Action':"Delete",
      'Status': status,
      'DeviceID': this.dnfremove,
      'Features': [this.dnfremovefid],
      'ConfigID':this.dnfremoveconfigid,
      // this.deviceandfeaturetypeform.value.DeviceID 
      // 'ConfigID': this.dnfcustomers.patchValue({"ConfigID": }),
    }

    if (status == 'InActive') {

      this.userservice.aserscreatedevicesandfeatures(this.removefeatureanddeviceobj).subscribe((response) => {
        if (response.code == 'S001') {
          this.deatilsandfeatures = response.data
          alert(response.data)

        }
        else if (response.code == 'S002') {
          alert(response.data)
        } else {
          alert(response.data)
        }
      }, (error) => {
        alert(error.error.data)
      })
    }
  }
  confirmationToRemove(index: any) {
    // console.log("deviceidddddd", index.DeviceID)
    // console.log("fffffffffidddddd", index.Features[0].FeatureID)
    this.dnfremove = index.DeviceID
    this.dnfremovefid = index.Features[0].FeatureID
    this.dnfremoveconfigid = index.ConfigID
    // this.dnfremovefid = index.Features.map((i: any) => (i as { FeatureID: string }).FeatureID)
    // 'Features': this.deviceandfeaturetypeform.value.Features.map((i: any) => (i as { FeatureID: string }).FeatureID)
  }

  //deleting from the table..(2)
  removedevice(status: any, index: any) {
    this.removedeviceobj = {
      'Action':"Delete",
      'Status': status,
      'DeviceID': this.dremove,
      'Type': this.dtyperemove,
      'Model': this.dmodelremove,
      'IsThresholdsRequired': this.dThresold,
      'IsIMEIAutogenerated': this.dIMeiNumber,
      'IsSIMNumberRequired': this.dSimNumber,
      'IsNotificationRequired': this.dNotification,
    }

    if (status == 'InActive') {

      this.userservice.aserscreatedevices(this.removedeviceobj).subscribe((response) => {
        if (response.code == 'S001') {
          this.createddevice = response.data
          alert(response.data)

        }
        else if (response.code == 'S002') {
          alert(response.data)
        } else {
          alert(response.data)
        }
      }, (error) => {
        alert(error.error.data)
      })
    }
  }
  confirmationToRemovedevice(index: any) {
    this.dremove = index.DeviceID
    this.dtyperemove = index.Type
    this.dmodelremove = index.Model
    this.dThresold = index.IsThresholdsRequired
    this.dIMeiNumber= index.IsIMEIAutogenerated
    this.dSimNumber = index.IsSIMNumberRequired
    this.dNotification = index.IsNotificationRequired
    
    // this.dnfremovefid = index.Features.map((i: any) => (i as { FeatureID: string }).FeatureID)
    // 'Features': this.deviceandfeaturetypeform.value.Features.map((i: any) => (i as { FeatureID: string }).FeatureID)
  }

  //deleting from the table..(1)
  removefeature(status: any, index: any){
    this.removefeatureeobj = {
      'Action': "Delete",
      'Status': status,
      'FeatureID': this.fremove,
      'Type': this.ftyperemove,
      'AliasName': this.faliasnameremove,
      'Feature': this.ffeatureremove,
      'IsThreshold': this.fisthersholdremove,
      'ThresholdMin': this.fthersholdminremove,
      'ThresholdMax': this.fthersholdmaxremove,
      'IsRequired': this.fisrequiredremove,
      'IsGoal': this.fisgoalremove,
      'Images': this.fimageremove,
    }

    if (status == 'InActive') {

      this.userservice.aserscreatefeatures(this.removefeatureeobj).subscribe((response) => {
        if (response.code == 'S001') {
          // this.createdevice = response.data
          alert(response.data)
          this.fdetails()

        }
        else if (response.code == 'S002') {
          alert(response.data)
        } else {
          alert(response.data)
        }
      }, (error) => {
        alert(error.error.data)
      })
    }
  }
  confirmationToRemovefeature(index: any) {
    this.fremove = index.FeatureID
    this.ftyperemove=index.Type
    this.faliasnameremove=index.AliasName
    this.ffeatureremove=index.Feature
    this.fisthersholdremove=index.IsThreshold
    this.fthersholdminremove=index.ThresholdMin
    this.fthersholdmaxremove=index.ThresholdMax
    this.fisrequiredremove=index.IsRequired
    this.fisgoalremove=index.IsGoal
    this.fimageremove=index.Images

    
  }
  //uploading image
  onuploadimgFile(event: any) {
    this.photo =[];
    this.PrescriptionImageFiles=[];
    // if (img.target.files.length > 0) {
    //   this.imageDoc = img.target.files[0];
  
    //   // Log file details for debugging
    //   console.log("File details:", this.imageDoc);
    // }
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        this.photo.push(event.target.files[i])
        var reader = new FileReader()
        reader.onload = (event: any) => {
 
          this.PrescriptionImageFiles.push(event.target.result);
        }
        reader.readAsDataURL(event.target.files[i]);
        // this.PrescriptionImageFiles= event.target.files[i]
      }
    }
  } 
  //to view whatever the image been uploaded 

  existing() {
    return window.location.href = this.ExDoc

  }
}

