<div class="bodycontent mainPage">
    <div class="tittle">
        <h3> {{title}} </h3>
        <div class="update-status-container">
            <!-- <button class="anvayaabutton-primary">Update Status</button> -->
            <button type="button" class="anvayaabutton-primary" data-bs-toggle="modal"
                data-bs-target="#update-finance-status">
                Update Status
            </button>
        </div>
    </div>
    <div class="partner-details-container">
        <div class="container">
            <div class="item">Partner ID: <span class="value">{{partnerDetails?.partnerID}}</span></div>
            <div class="item">Partner Name: <span class="value">{{partnerDetails?.partnerName}}</span></div>
            <div class="item">MobileNumber: <span class="value">{{partnerDetails?.mobileNumber}}</span></div>
            <div class="item">EmailID: <span class="value">{{partnerDetails?.emailID}}</span></div>
            <div class="item">Partner Status: <span class="value">{{partnerDetails?.status}}</span></div>
        </div>
    </div>
    <div class="details-container">
        <div *ngIf="type==='Bank'">
            <div class="title-container">
                <h3>Bank Details</h3>
            </div>
            <div class="bank-details-container">
                <div class="card" style="width: 30rem;">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <label class="label">Bank Account Holder Name: </label><span
                                class="value">{{bankDetails.bankAccountHolderName}} </span>
                        </li>
                        <li class="list-group-item">
                            <label class="label">Account Number:</label><span
                                class="value">{{bankDetails.bankAccountNumber}} </span>
                        </li>
                        <li class="list-group-item">
                            <label class="label">IFSC Code:</label><span class="value">{{bankDetails.IFSCCode }} </span>
                        </li>
                        <li class="list-group-item">
                            <label class="label">Bank Name : </label><span class="value">{{bankDetails.bankName}}
                            </span>
                        </li>
                        <li class="list-group-item">
                            <label class="label">Account Type:</label><span class="value">{{bankDetails.accountType}}
                            </span>
                        </li>
                        <li class="list-group-item">
                            <label class="label">Branch Name:</label><span class="value">{{bankDetails.branch}} </span>
                        </li>
                        <li class="list-group-item">
                            <label class="label">Branch State:</label><span class="value">{{bankDetails.state}} </span>
                        </li>
                        <li class="list-group-item">
                            <label class="label">Status: </label><span class="value">{{bankDetails.status}} </span>
                        </li>
                        <li class="list-group-item">
                            <label class="label">Pass Book File: </label>
                            <button class="anvayaabutton-primary" type="button" data-bs-toggle="offcanvas"
                                data-bs-target="#passbook-offcanvas" aria-controls="passbook-offcanvas">view</button>

                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div *ngIf="type==='Contract'">
            <div class="title-container">
                <h3>Contract Details</h3>
            </div>
            <div class="contract-details-container">
                <div class="contract-details-item"><label>ContractID : <span
                            class="value">{{contractDetails.contractID}}</span></label></div>
                <div class="contract-details-item"><label>Contract Start Date : <span
                            class="value">{{contractDetails.startDate * 1000 | date : 'dd-MMM-YYYY'}}</span></label>
                </div>
                <div class="contract-details-item"><label>Contract End Date : <span class="value">
                            {{contractDetails.endDate * 1000 | date : 'dd-MMM-YYYY'}}</span></label></div>
            </div>
            <div class="rate-card-container">
                <table class="table table-bordered">
                    <thead>
                        <tr class="table-header">
                            <th>Service</th>
                            <th>Tariff Type</th>
                            <th>Proposed Rate</th>
                            <th>Final Price</th>
                            <th>Location</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ratecard of contractDetails.rateCardDetails">
                            <td>{{ratecard.serviceName}}</td>
                            <td>{{ratecard.tarrifType}}</td>
                            <td>{{ratecard.vendorPrice}}</td>
                            <td>{{ratecard.finalPrice}}</td>
                            <td>{{ratecard.cityName}}</td>
                            <td>{{ratecard.status}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<!-- Modal for update the approval status -->

<div class="modal fade" id="update-finance-status" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="finance-approval-status" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="finance-approval-status">UpdateStatus</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    #statusclosebutton></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="updateStatusForm">
                    <div class="form-group">
                        <label>Status</label>
                        <select formControlName="status" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['status'].errors }">
                            <option selected disabled value="">-- Please Select --</option>
                            <option value="Approved">Approve</option>
                            <option value="Rejected">Reject</option>
                        </select>
                        <div *ngIf="submitted && f['status'].errors" class="invalid-feedback">
                            <div *ngIf="f['status'].errors['required']">Status is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Comments</label>
                        <textarea type="text" formControlName="comments" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted &&  f['comments'].errors }"></textarea>
                        <div *ngIf="submitted    && f['comments'].errors" class="invalid-feedback">
                            <div *ngIf="f['comments'].errors['required']">Comments is required</div>
                            <div *ngIf="f['comments'].errors['minlength']">
                                Comments must be at least 3 characters
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="anvayaabutton-secondary" (click)="handleUpdateStatus()">update</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal for update the approval status End Here -->


<!-- Bank pass book offcanvas start Here  -->
<div class="offcanvas offcanvas-end" tabindex="-1" id="passbook-offcanvas">
    <div class="offcanvas-header">
        <h5 class="passbook-offcanvas-title"> Pass Book File</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body ratio ratio-16x9">
        <iframe [src]="bankDetails.passBookFile | safe"  allowfullscreen></iframe>
    </div>
</div>
<!-- Bank pass book offcanvas End Here  -->