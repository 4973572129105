<div class="bodycontent">
<div class="row">
    <div class="col-md-4">
        <div class="card" style="width: 18rem;">
            <div class="card-body" style="cursor: pointer;" (click)="viewScreen()">
              <h5 class="card-title text-center">Configurations</h5>

            </div>
          </div>
    </div>
    <div class="col-md-4">
        <div class="card" style="width: 18rem;">
            <div class="card-body" style="cursor: pointer;" (click)="viewRequest()">
              <h5 class="card-title" style="text-align:center ;">Request Configurations</h5>

            </div>
          </div>
    </div>
    <div class="col-md-4">
        <div class="card" style="width: 18rem;">
            <div class="card-body" style="cursor: pointer;" (click)="viewReminders()">
              <h5 class="card-title" style="text-align:center ;">Reminders</h5>

            </div>
          </div>
    </div>

    <div class="col-md-4">
      <div class="card" style="width: 18rem;">
          <div class="card-body" style="cursor: pointer;" (click)="viewstates()">
            <h5 class="card-title" style="text-align:center ;">Locations</h5>

          </div>
        </div>
  </div>

  <div class="col-md-4">
    <div class="card" style="width: 18rem;">
        <div class="card-body" style="cursor: pointer;" (click)="viewcsatquestions()">
          <h5 class="card-title" style="text-align:center ;">CSAT Questions</h5>

        </div>
      </div>
</div>
    
<div class="col-md-4">
  <div class="card" style="width: 18rem;">
      <div class="card-body" style="cursor: pointer;" (click)="viewactivecustomers()">
        <h5 class="card-title" style="text-align:center ;">view active customers</h5>

      </div>
    </div>

</div>
<div class="col-md-4">
  <div class="card" style="width: 18rem;">
      <div class="card-body" style="cursor: pointer;" (click)="features()">
        <h5 class="card-title" style="text-align:center ;">Asers Master </h5>

      </div>
    </div>
</div>
</div>


</div>

