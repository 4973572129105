export const COMETCHAT_CONSTANTS = {
  //sample details
  //   APP_ID: '2471183a3cfa5ebd',
  //   REGION: 'in',
  //   AUTH_KEY: '060ca222a759065e1bba8e197d9e2775a58e3afb',

  //Anvayaa project details
  APP_ID: '23310136900a4a6a', // Replace with your App ID
  REGION: 'us', // Replace with your App Region ("eu" or "us")
  AUTH_KEY: '0c2c064a67536547017c919fcdaed7f1b61a8bad', // Replace with your Auth Key
};
