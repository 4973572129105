<form name="Sopnser" id="Sopnser">

    
        <div  class="previosButton" (click)="dashboard()"><img src="../../../assets/images/previous.png"></div>
        <div class="col-md-10 container">
            <div>
                <button class="button justify-content-md-center sponserdetailsplate">Profile</button>
            </div>
            <br>
            <div class="row">
                <div class="form-group col-md-4 inputBox">
                    <input type="text" name="Name" required
                        onkeyup="this.setAttribute('value', this.value);" value="">
                    <label>Name</label>
                </div>

                <div class="form-group col-md-4 inputBox">
                    <input type="text" name="ContactNumber" required
                        onkeyup="this.setAttribute('value', this.value);" value="">
                    <label >MobileNumber</label>
                </div>
                <div class="col-md-4 inputBox">
                    <input type="email" name="Gender" required
                        onkeyup="this.setAttribute('value', this.value);" value="">
                    <label>E-mail</label>
                </div>

            </div>
            <br>
            <div class="row">

                <div class=" col-md-4 inputBox">
                    <input type="text" name="email" required
                        onkeyup="this.setAttribute('value', this.value);" value="">

                    <label>PAN Number</label>
                </div>
                <div class=" col-md-4 inputBox">
                    <input type="text" name="email" required
                        onkeyup="this.setAttribute('value', this.value);" value="">

                    <label>Aadhar Number</label>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-4 "><button type="submit" class="button updateBirthdayButton uploadBox "
                        id="submit"><img src="../../../assets/images/folder.png"> Birthday Profile</button></div>
                <div class="col-md-4"><button type="submit" id="submit"
                    class="updateProfileButton uploadBox"><img src="../../../assets/images/folder.png">Update Profile</button></div>
                <div class="col-md-4"></div>
            </div>

        </div>
        <br>



    




</form>