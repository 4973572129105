<div class="bodycontent card">
    <div class="container">
        <div class="row info">
            <div class="row">
                <!-- Customer Details Start -->
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title text-center text-center">Customer Details</h2>
                            <div class="row">
                                <div class="details">
                                    <div>
                                        <label>CustRecID</label>
                                    </div>
                                    <div>
                                        <span>{{RequestDetails?.CustomerDetails?.CustRecID}}</span>
                                    </div>
                                </div>
                                <div class="details">
                                    <div>
                                        <label>CustomerName</label>
                                    </div>
                                    <div>
                                        <span>{{RequestDetails?.CustomerDetails?.Name}}</span>
                                    </div>
                                </div>
                                <div class="details">
                                    <div>
                                        <label></label>
                                    </div>
                                    <div>
                                        <div>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  Customer Details  End -->

                <!-- Beneficiary Details Start -->
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title text-center text-center">Beneficiary Details</h2>
                            <div class="row">
                                <div class="details">
                                    <div>
                                        <label>CustID</label>
                                    </div>
                                    <div>
                                        <span>{{RequestDetails?.BeneficiaryData?.CustID}}</span>
                                    </div>
                                </div>
                                <div class="details">
                                    <div>
                                        <label>Beneficiary Name</label>
                                    </div>
                                    <div>
                                        <span>{{RequestDetails?.BeneficiaryData?.Name}}</span>
                                    </div>
                                </div>
                                <div class="details">
                                    <div>
                                        <label></label>
                                    </div>
                                    <div>
                                        <div>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  Beneficiary Details  End -->

                <!--Request Details Start -->
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title ">Request Details</h2>
                            <div class="row">
                                <div class="details">
                                    <div>
                                        <label>RequestID</label>
                                    </div>
                                    <div>
                                        <span>{{RequestDetails?.RequestID}}</span>
                                    </div>
                                </div>
                                <div class="details">
                                    <div>
                                        <label>ServiceName</label>
                                    </div>
                                    <div>
                                        <!-- {{myRequestsObj["AliasName"]|replaceUnderscore |titlecase}} -->
                                        <span>{{RequestDetails?.CategoryName|replaceUnderscore |titlecase}}</span>
                                    </div>
                                </div>
                                <div class="details">
                                    <div>
                                        <label>Status</label>
                                    </div>
                                    <div>
                                        <div>
                                            <span>{{RequestDetails?.Status|replaceUnderscore |titlecase}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Request Details End -->
            </div>
        </div>
        <!-- <div class="row selectOptions">
            <form [formGroup]="searchForm">
                <div class="row">
                    <div class="col-md-3">
                        <label for="Gender">Select Gender</label>
                        <select class="form-control" aria-label="Default select example" formControlName="Gender">
                            <option selected disabled value="">Please Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                        <span class="text-danger"
                            *ngIf="!searchForm.controls['Gender'].valid && (searchForm.controls['Gender']?.dirty ||searchForm.controls['Gender']?.touched)">
                            Please Enter Gender
                        </span>
                    </div>
                    <div class="col-md-1">
                        <label></label>
                        <button class="anvayaabutton-primary" (click)="handleSearchSubmit()">Search</button>
                    </div>
                    <div class="col-md-4" *ngIf="partnersData.length < 1 && noProfilesFoundFlag">
                        <div>
                            <label></label>
                        </div>
                        <button class="anvayaabutton-secondary" (click)="handleAssignRequestToPartnerTeam()" >Assign To PartnerTeam</button>
                    </div>
                </div>
            </form>
        </div> -->
        <div class="row addPartnersList" *ngIf="partnersDataFlag && partnersData.length > 0">
            <div class="card addPartnersList-card">
                <table class="table text-center">
                    <thead>
                        <tr>
                            <th>Partner Name</th>
                            <th>Profile Name</th>
                            <th>AadhaarNo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let partnerDetailsObj of partnersData">
                            <th class="partnerName">{{partnerDetailsObj.PartnerDetails?.BusinessInfo?.PartnerName}}</th>
                            <td>{{partnerDetailsObj.Name}}</td>
                            <td>{{partnerDetailsObj.AadhaarNo}}</td>
                            <td><button class="anvayaabutton-primary" data-bs-toggle="modal" data-bs-target="#addModel"
                                    (click)="handleAddProfile(partnerDetailsObj)">Add</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row profiles" *ngIf="profiles.length > 0">
            <h4> Profiles</h4>
            <div class="card profiles-card">
                <table class="table table-striped table-hover text-center">
                    <thead>
                        <tr>
                            <th>ProfileID</th>
                            <th>ReferralType</th>
                            <th>Tarrif Type</th>
                            <th>VendorPrice </th>
                            <th>MinimumPrice</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let profiles of profiles">
                            <td>{{profiles.ProfileID}}</td>
                            <td>{{profiles.ReferralType|replaceUnderscore}}</td>
                            <td>{{profiles.TariffType}}</td>
                            <td>{{profiles.VendorPrice}}</td>
                            <td>{{profiles.MinimumPrice}}</td>
                            <td>
                                <div class="actionButtons">
                                    <button class="anvayaabutton-primary" (click)="handleAcceptProfile(profiles)">Accept</button>
                                    <button class="anvayaabutton-secondary" data-bs-toggle="modal" data-bs-target="#rejectProfile" (click)="handleRejectProfile(profiles)">Reject</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="card">
     

       <!-- <table>
        <thead>
            <th>Select</th>
            <th>Vendor Id</th>
            <th>Vendor Name</th>
            <th>Vendor Status</th>
            <th>Profiles</th>
        </thead>
        <tbody>
            <tr *ngFor="let row of VendorsList; let rowIndex = index">
                <div [class.clickable]="isRowClickable(rowIndex)" (click)="expanded[row.id] = !expanded[row.id]">
                <td>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckCheckedDisabled" checked disabled>
                        <label class="form-check-label" for="flexCheckCheckedDisabled">
                           
                        </label>
                    </div>
                </td>
                <td>{{row._id}}</td>
                <td>{{row.partnerData.BusinessInfo.PartnerName}}</td>
                <td>{{row.partnerData.PartnerStatus}}</td> 
                <td><span  *ngIf="isRowClickable(rowIndex)">
                    <span *ngIf="expanded[row._id]">-</span>
                    <span *ngIf="!expanded[row._id]"><button class="btn btn-primary">Profiles</button></span>
                  </span></td>
            </div>
            <div [@detailExpand]="expanded[row.id] ? 'expanded' : 'collapsed'">
                <div  class="child-row" *ngFor="let childrow of row.staffData">
                  
                  <div class="td">{{childrow.Name}}</div>
                  <div class="td">{{childrow.Age}}</div>
                  <div class="td">{{childrow.MobileNumber}}</div>
                  <div class="td">{{childrow.Religion}}</div>
                  <div class="td">{{childrow.Gender}}</div>
                  <div class="td">{{childrow.ProfileID}}</div>
                  <div class="td">{{childrow.SubcategoryName}}</div>
                  <div class="td">{{childrow.text2}}</div>
                </div>
            </div>
            </tr>
           
        </tbody>
       </table> -->








         <div class="table">
            <div class="table-header table-row">
              
              
              <div class="td">Select</div>
              <div class="td">Vendor Id</div>
              <div class="td">Vendor Name</div>
              <div class="td">Vendor Status</div>
              <!-- <div class="td">Profiles</div> -->
              
            </div>
            <div class="table-row" *ngFor="let row of VendorsList; let rowIndex = index">
              <div [class.clickable]="isRowClickable(rowIndex)" (click)="expanded[row.id] = !expanded[row.id]">
                
                <div class="td">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckCheckedDisabled" checked disabled>
                        <label class="form-check-label" for="flexCheckCheckedDisabled">
                           
                        </label>
                    </div>
                </div>
                <div class="td">{{row._id}}</div>
                <div class="td">{{row.partnerData.BusinessInfo.PartnerName}}</div>
                <div class="td">{{row.partnerData.PartnerStatus}}</div>
                
                <div class="td">
                    <span  *ngIf="isRowClickable(rowIndex)">
                      <span *ngIf="expanded[row._id]">-</span>
                      <span *ngIf="!expanded[row._id]"><button class="btn btn-primary">Profiles</button></span>
                    </span>
                  </div>
                
                <div style="padding:0px" [@detailExpand]="expanded[row.id] ? 'expanded' : 'collapsed'">
                  <div   >
                     <table class="table">
                        <!-- <thead>
                            <th>Name</th>
                            <th>Age</th>
                            <th>Number</th>
                            <th>Religion</th>
                            <th>Gender</th>
                            <th>ProfileID</th>
                            <th>Work For</th>
                        </thead> -->
                        <tbody >
                            <tr *ngFor="let childrow of row.staffData">
                            <td>{{childrow.Name}}</td>
                            <td>{{childrow.Age}}</td>
                            <td>{{childrow.MobileNumber}}</td>
                            <td>{{childrow.Religion}}</td>
                            <td>{{childrow.Gender}}</td>
                            <td>{{childrow.ProfileID}}</td>
                            <td>{{childrow.SubcategoryName}}</td>
                            </tr>
                            
                          
                        </tbody>
                     </table>
                    
                  </div>
              </div>
              </div>
            </div>
          </div>
          <div>
            <button class="btn btn-primary" (click)="submitVendorsProfiles()">Submit</button>
          </div>
    </div>
  
    <!-- Add profile Modal  Start-->
    <div class="modal fade" id="addModel" tabindex="-1" aria-labelledby="addProfileModal" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="addProfileModal">Select Tarrif Type</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        #closebutton></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="Tarrif_type">Tarrif Type</label>
                            <select class="form-control" aria-label="Default select example"
                                (change)="handleTarrifChange($event)">
                                <option selected value="">Please Select</option>
                                <option *ngFor="let tarrif of selectedProfile?.Rates" value={{tarrif.TarrifType}}>
                                    {{tarrif.TarrifType}}</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label>Price : </label>
                            <span> {{tarrifDetails?.MinimumPrice}}</span>

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="anvayaabutton-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="anvayaabutton-primary"
                        (click)="handleSaveProfileToRequest()">Submit</button>
                </div>
            </div>
        </div>
    </div>
    <!--Add profile Modal  End-->

    <!-- Reject Action Modal  Start-->
    <div class="modal fade" id="rejectProfile" tabindex="-1" aria-labelledby="rejectProfile" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="rejectProfile">Reject</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        #rejectProfilebutton></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <form [formGroup]="rejectProfileForm">
                            <div class="input-group">
                                <span class="input-group-text">Reason For Reject</span>
                                <textarea class="form-control" aria-label="With textarea" formControlName="Comment"></textarea>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="anvayaabutton-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="anvayaabutton-primary" [disabled]="(rejectProfileForm.value?.Comment).length<3" (click)="submitRejectProfile()">Submit</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Reject Action Modal  End-->
</div>