<div class="mainDiv">
   <div class="container">
    <div class="row">
        <div class="col-md-4 align-self-end">
       <button type="button" class="anvayaabutton-primary"  (click)="newRemainder()">+ Create Reminder</button>
     </div>
   </div>
   </div>
    
    <table class="table">
        <thead>
          <tr>
            <th scope="col">Category Name </th>
            <th scope="col">Recivers Name</th>
            <th scope="col">Frequency</th>
            <th scope="col">StartDate/EndDate</th>
            <th scope="col">Scheduled Dates</th>
            
            <th scope="col">Status</th>
            <th scope="col">Message Mode</th>
            <th scope="col">Remove</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let i of remaindersDataArray">

            <td>{{i.CategoryName}}</td>
            <td><span *ngFor="let name of i.ReceiversDetails">{{name.Name}}</span></td>
            <td>{{i.Frequency}}</td>
            <td>{{i.StartDate*1000|date:'dd-MM-yyyy'}}
                <br>{{i.EndDate*1000|date:'dd-MM-yyyy'}}
            </td>
            <td><div> <span [hidden]="i.RemainderSchedules.Date==''">{{i.RemainderSchedules.Date}}</span></div>
                <div><span  [hidden]="i.RemainderSchedules.DaysOfWeek==''"  *ngFor="let a of i.RemainderSchedules.DaysOfWeek">{{a+", "}}</span></div>
               <div> <span   [hidden]="i.RemainderSchedules.DatesOfMonth==''"  *ngFor="let e of i.RemainderSchedules.DatesOfMonth">{{e+","}}</span></div></td>
            <td>{{i.Status}}</td>
            <td><div *ngFor="let d of i.ReceiversDetails"><span *ngFor="let c of d.MessageMode">{{c}}</span></div></td>
           <td>
            <button type="button" class="btn anvayaaButton" data-bs-toggle="modal" data-bs-target="#updateVisitsModal" (click)="confirmRemove(i)" >Remove</button>
            
           </td>
            <!-- <td><img src="../../../assets/images/delete.png" style="width: 25px;"   (click)="removeremainder(i)"> </td> -->
          </tr>
          
        </tbody>
      </table>

      <div class="modal fade" id="updateVisitsModal" tabindex="-1" aria-labelledby="updateVisitsModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
             <h4>Confirm to Remove Reminder </h4>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal">CANCEL</button>
              <button type="button" class="btn anvayaaButton"  data-bs-dismiss="modal"  (click)="removeremainder()">Remove</button>
            </div>
          </div>
        </div>
      </div>
</div>

