<div class="bodycontent">
    <div style="text-align: center;">
        <h2>Close Tickets</h2>
    </div>
    <div>
        <table class="table table-striped text-start card-body mt-6">
            <thead class="tablehead">
                <tr>
                    <th (click)="sortData('RequestID')">Request Details<i class="fa fa-sort" aria-hidden="true"></i></th>
                    <th (click)="sortData('Name')">Customer Details<i class="fa fa-sort" aria-hidden="true"></i></th>
                    <th (click)="sortData('CreatedPersonName')">Created Person Name<i class="fa fa-sort" aria-hidden="true"></i></th>
                    <th (click)="sortData('ServiceName')">Agent Name <i class="fa fa-sort" aria-hidden="true"></i></th>
                    <th (click)="sortData('CreatedDate')">Created Date <i class="fa fa-sort" aria-hidden="true"></i></th>
                    <th (click)="sortData('RequestedDate')">Requested Date <i class="fa fa-sort" aria-hidden="true"></i></th>
                    <th (click)="sortData('UpdatedDate')">Updated Date <i class="fa fa-sort" aria-hidden="true"></i></th>
                    <th (click)="sortData('Status')">Status <i class="fa fa-sort" aria-hidden="true"></i></th>
                    <th (click)="sortData('StatusTrack')">Status Track <i class="fa fa-sort" aria-hidden="true"></i></th>
                </tr>
            </thead>
            <tbody *ngFor="let data of ViewCCdbArray">
                <tr>
                    <td (click)="updateCloseTicket(data)" class="RequestStyle">
                        <div>
                          {{data?.AliasName |titlecase}}
                        </div><b>{{data?.RequestID}}</b>
                        <div style="color:#914572">{{data?.CustomerType}}</div>
                      </td>
                    <td>{{data?.Name}}
                        <div>
                            <b>{{data?.CustRecID}}</b>
                        </div>
                    </td>
                    <td>{{data?.CreatedPersonName}}</td>
                    <td>{{data?.EmployeeDetails?.FirstName}} {{data?.EmployeeDetails?.LastName}}</td>
                    <td>{{data?.CreatedDate*1000 |date: 'dd-MMM-yyyy'}}</td>
                    <td>{{data?.RequestedDate*1000 |date: 'dd-MMM-yyyy'}}</td>
                    <td>{{data?.UpdatedDate*1000 |date: 'dd-MMM-yyyy'}}</td>
                    <td>{{data?.Status}}</td>
                    <td><button type="button" class="anvayaabutton-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="showStatusTrack(data)" >
                        View
                      </button></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Status Track</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table class="table table-striped text-start card-body mt-6">
                    <thead class="tablehead">
                        <tr>
                            <th>Name</th>
                            <th>Time</th>
                            <th>Status Message</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let statustrack of selectedData?.StatusTrack">
                            <td>{{statustrack?.CreatedPersonName}}</td>
                            <td>{{statustrack?.Time*1000| date: 'dd-MMM-yyyy HH:MM'}}</td>
                            <td>{{statustrack?.Comments}}</td>
                            <td>{{statustrack?.Status}}</td>
                        </tr>
                    </tbody>
                </table>
                
               
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
</div>

