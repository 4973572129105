<div class="parent">
  <div>
    <div class="card-body">
      <div class="intial-amb-form-parent">
        <!-- <form class="container" [formGroup]="intialAmbulanceForm" >
                  <div class="row">
                    <div class="col-md-3">
                        <label>Name</label>
                        <input type="text" class="form-control"  formControlName="userName" readonly />
                    </div>
                    <div  class="col-md-3">
                        <label>Phone Number</label>
                        <input type="tel" class="form-control" formControlName="userPhone" readonly />
                    </div>
                    <div class="col-md-3">
                        <label>PickUp Address</label>
                        <input type="text" class="form-control" formControlName="pickupPoint" />
                    </div>
                    <div class="col-md-3">
                        <label>Drop Address</label>
                        <input type="text" class="form-control" formControlName="dropPoint" />
                    </div>
                  </div>   
                    
                </form> -->
        <form [formGroup]="intialAmbulanceForm" (ngSubmit)="submitVendorToRequest()">
          <!-- User Name Field -->
          <div class="row">
            <div class="col-md-3 col-lg-3  col-xl-3">
              <div>
                <label for="userName">User Name</label>
                <input id="userName" class="form-control" formControlName="userName" readonly />
                <div class="text-danger"
                  *ngIf="intialAmbulanceForm.get('userName')?.invalid && intialAmbulanceForm?.get('userName')?.touched">
                  User Name is required.
                </div>
              </div>
            </div>

            <div class="col-md-3 col-lg-3  col-xl-3">
              <!-- User Phone Field -->
              <div>
                <label for="userPhone">Agent Number</label>
                <input id="userPhone" class="form-control" formControlName="userPhone" readonly />
                <div class="text-danger"
                  *ngIf="intialAmbulanceForm.get('userPhone')?.invalid && intialAmbulanceForm.get('userPhone')?.touched">
                  Phone is required and must be 10 digits.
                </div>
              </div>
            </div>

          </div>
          <div class="m-4">
            <div formGroupName="pickupPoint">
              <h4>Pickup Point</h4>
              <div class="row">
                <div class="m-2">
                  <label><span class="text-danger">*</span>Select Address</label>
                  <ng-autocomplete style="border-radius: 10px;" [data]="suggetionsList" name="Name"
                    formControlName="address" [placeholder]="autocompletePlaceHolder" [searchKeyword]="keyword"
                    notFoundText="Not found" (inputChanged)='onSearch($event,"pick")'
                    [itemTemplate]="itemTemplateStatic" (selected)='onChangeSearch($event)'
                    (inputCleared)='onFocused($event,"pick")' [notFoundTemplate]="notFoundTemplate"
                    (keypress)="onlyAlphabets($event)">
                  </ng-autocomplete>
                  <ng-template #itemTemplateStatic let-item>
                    <a [innerHTML]="item.description"></a>
                  </ng-template>
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>
                <!-- <div class="col-md-3 col-lg-3  col-xl-3">
                  <input formControlName="address" placeholder="Address" class="form-control" />
                  <div
                    *ngIf="intialAmbulanceForm.get('pickupPoint.address')?.invalid && intialAmbulanceForm.get('pickupPoint.address')?.touched">
                    Address is required
                  </div>
                </div> -->
                <div class="col-md-3 col-lg-3  col-xl-3">
                  <input formControlName="city" placeholder="City" class="form-control" />
                  <div
                    *ngIf="intialAmbulanceForm.get('pickupPoint.city')?.invalid && intialAmbulanceForm.get('pickupPoint.city')?.touched">
                    City is required
                  </div>
                </div>
                <div class="col-md-3 col-lg-3  col-xl-3">
                  <input formControlName="location" placeholder="Location" class="form-control" />
                  <div
                    *ngIf="intialAmbulanceForm.get('pickupPoint.location')?.invalid && intialAmbulanceForm.get('pickupPoint.location')?.touched">
                    Location is required
                  </div>
                </div>
                <div class="col-md-3 col-lg-3  col-xl-3">
                  <input formControlName="state" placeholder="State" class="form-control" />
                  <div
                    *ngIf="intialAmbulanceForm.get('pickupPoint.state')?.invalid && intialAmbulanceForm.get('pickupPoint.state')?.touched">
                    State is required
                  </div>
                </div>
                <div class="col-md-3 col-lg-3  col-xl-3">
                  <input formControlName="pinCode" placeholder="Pin Code" class="form-control" />
                </div>
              </div>

            </div>
          </div>

          <div class="m-4">
            <div formGroupName="dropoffPoint">
              <h4>Dropoff Point</h4>
              <div class="row">
                <div class="m-4">
                  <label><span class="text-danger">*</span>Select Address</label>
                  <ng-autocomplete style="border-radius: 10px;" [data]="suggetionsList" name="Name"
                    formControlName="address" [placeholder]="autocompletePlaceHolder" [searchKeyword]="keyword"
                    notFoundText="Not found" (inputChanged)='onSearch($event,"drop")'
                    [itemTemplate]="itemTemplateStatic" (selected)='onChangeSearch($event)'
                    (inputCleared)='onFocused($event,"drop")' [notFoundTemplate]="notFoundTemplate"
                    (keypress)="onlyAlphabets($event)">
                  </ng-autocomplete>
                  <ng-template #itemTemplateStatic let-item>
                    <a [innerHTML]="item.description"></a>
                  </ng-template>
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>
                <!-- <div class="col-md-3 col-lg-3  col-xl-3">
                                      <input formControlName="address" placeholder="Address" class="form-control">
                              <div *ngIf="intialAmbulanceForm.get('dropoffPoint.address')?.invalid && intialAmbulanceForm.get('dropoffPoint.address')?.touched">
                                Address is required
                              </div>
                                  </div> -->
                <div class="col-md-3 col-lg-3  col-xl-3">
                  <input formControlName="city" placeholder="City" class="form-control" />
                  <div
                    *ngIf="intialAmbulanceForm.get('dropoffPoint.city')?.invalid && intialAmbulanceForm.get('dropoffPoint.city')?.touched">
                    City is required
                  </div>
                </div>
                <div class="col-md-3 col-lg-3  col-xl-3">
                  <input formControlName="location" placeholder="Location" class="form-control" />
                  <div
                    *ngIf="intialAmbulanceForm.get('dropoffPoint.location')?.invalid && intialAmbulanceForm.get('dropoffPoint.location')?.touched">
                    Location is required
                  </div>
                </div>
                <div class="col-md-3 col-lg-3  col-xl-3">
                  <input formControlName="state" placeholder="State" class="form-control" />
                  <div
                    *ngIf="intialAmbulanceForm.get('dropoffPoint.state')?.invalid && intialAmbulanceForm.get('dropoffPoint.state')?.touched">
                    State is required
                  </div>
                </div>
                <div class="col-md-3 col-lg-3  col-xl-3">
                  <input formControlName="pinCode" placeholder="Pin Code" class="form-control" required />
                  <!-- <div *ngIf="intialAmbulanceForm.get('dropoffPoint.pinCode')?.invalid && intialAmbulanceForm.get('dropoffPoint.pinCode')?.touched">
                                <span *ngIf="intialAmbulanceForm.get('dropoffPoint.pinCode')?.errors?.required">Pin Code is required</span>
                                <span *ngIf="intialAmbulanceForm.get('dropoffPoint.pinCode')?.errors?.pattern">Pin Code should be a 6-digit number</span>
                              </div> -->
                </div>
              </div>

            </div>
          </div>
          <div class="text-end">
            <button class="btn btn-primary" type="submit" style="background-color: #914572;"
              [disabled]="intialAmbulanceForm.invalid">Submit</button>

          </div>
        </form>
        <div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner type="ball-scale-multiple">loading...</ngx-spinner>