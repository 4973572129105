<form *ngFor="let data of anchorsRevenueDetails"   #userlogin = "ngForm" (ngSubmit)="updateachors()"  [formGroup]="anchorsdata">


    <div  class="previosButton" (click)="dashboard()"><img src="../../../assets/images/previous.png"></div>
    <div class="col-md-10 container">
        <div>
            <button class="button justify-content-md-center sponserdetailsplate" >RevenueDetails</button>
        </div>
        <br>
        <div class="row">
            <div class="form-group col-md-4 inputBox">
                <input type="text" name="Name" formControlName="Type" required
                    onkeyup="this.setAttribute('value', this.value);" value="" ngModel="{{data.Type}}">
                <label>Type</label>
            </div>
            <div class="col-md-4 inputBox">
                <input type="email" name="Gender" formControlName="PurchaseType" required
                    ngModel="{{data.PurchaseType}}">
                <label>PurchaseType</label>
            </div>

            <div class="form-group col-md-4 inputBox">
                <input type="text" name="ContactNumber" formControlName="FranchiseShare" required
                    onkeyup="this.setAttribute('value', this.value);" value="" ngModel="{{data.FranchiseShare}}">
                <label >FranchiseShare</label>
            </div>
            

        </div>
        <br>
        <div class="row">
            <!-- <input matInput [matDatepicker]="picker">
<mat-hint>MM/DD/YYYY</mat-hint>
<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
<mat-datepicker #picker></mat-datepicker> -->
        </div>
        <div class="row">

            
             <div class="form-group col-md-4 inputBox" >
                <input type="date" class="date-picker" name="StartDate" formControlName="StartDate"  onkeyup="this.setAttribute('value', this.value);" value=""  ngModel="{{data.StartDate}}">
              
                <label>StartDate</label>
             
            </div>
            <div class="form-group col-md-4 inputBox" >
                <input type="date" class="date-picker" name="EndDate" formControlName="EndDate"  onkeyup="this.setAttribute('value', this.value);" value=""  ngModel>
              
                <label>EndDate</label>
             
            </div>
          
            <div class=" col-md-4 inputBox">
                <input type="text" name="email" formControlName="CityID" required
                    onkeyup="this.setAttribute('value', this.value);" value="" ngModel="{{data.CityID}}">

                <label>CityID</label>
            </div>
        </div>
        <br>
       
        <br>
         <div class="row"> 

            <!-- <div class="form-group col-md-4 inputBox">
                <input type="text" name="email" formControlName="IsTaxExeempeted" class="form-control" required
                    onkeyup="this.setAttribute('value', this.value);" value="" ngModel>

                <label>InCorporation</label>
            </div>
            <div class=" col-md-2 inputBox">
                <img src="../../../assets/images/icons8-checkmark-80.png" class="imgSize">

            </div>
            <div class=" col-md-2 inputBox">
               
             <img src="../../../assets/images/icons8-close-64.png" class="imgSize">
                
            </div> -->
            <!-- <div class=" col-md-4 inputBox">
                <button type="submit" class="button updateBirthdayButton uploadBox "
                    id="submit"><img src="../../../assets/images/folder.png" > Select Document</button>
            </div> -->
            
            <input type = "submit" value = "submit">
        </div> 
        <br>
        

    </div>
    <br>

</form>
