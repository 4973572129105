<div class="main">
    <div class="card panel-default">
        <div class="card-body">
            <h3 class="reuestHeading" style="color: purple;text-align: center;">Package Subscription Details</h3>
            <div class="row m-2">
                <div class="col-md-8"></div>
                <div class="col-md-2">
                      <select class="form-select" (change)="serviceArea($event)">
                        <option value="" selected disabled>Select City</option>
                        <option *ngFor="let cityObj of availbleCities" value="{{cityObj?.serviceAreaID}}">
                            {{cityObj?.seviceAreaName}}
                            </option>
                      </select>
                </div>
                <div class="col-md-2">
                    <select class="form-select" (change)="statusFilter($event)">
                        <option value="" selected disabled>Select Status</option>
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                        <option value="Refunded">Refunded</option>
                        <option value="Cancelled">Cancelled</option>
                    </select>
                </div>
            </div>
            <table class="table  table-striped table-hover">

                <thead>
                    <tr>
                        <th scope="col" style="text-align: center;" >Customer ID </th>
                        <th scope="col" >Customer Name</th>
                        <th scope="col" >Package Name</th>
                        <th scope="col" >City</th>
                        <th scope="col" style="text-align: center;">Membership Start Date</th>
                        <th scope="col" style="text-align: center;">Membership Expire Date</th>
                        <!-- <th scope="col" style="text-align: center;">Payment Status</th> -->
                        <th scope="col" style="text-align: center;">Package Status</th>
                        <th scope="col" style="text-align: center;">Freez Status</th>
                        <th scope="col" style="text-align: center;">Action</th>


                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of customerPackageDetailsData | paginate: { itemsPerPage: pageLimit, currentPage: page, totalItems:totalCount }" style="text-align: center;">
                        <td>{{data.CustRecID}}</td>
                        <td class="text-start">{{data.CustomerData.Name |titlecase}}</td>
                        <td class="text-start">{{data.AliasName|replaceUnderscore}}</td>
                        <td class="text-start">{{data.ServiceAreaName}}</td>
                        <td>{{(data.PackageCreatedDate)*1000|date:'dd MMM YYYY'}}</td>
                        <td>{{(data.PackageExpiryDate)*1000|date:'dd MMM YYYY'}}</td>
                        <!-- <td>{{data.PaymentStatus}}</td> -->
                        <td>{{data.Status}}</td>
                        <td>{{data.PackageStatus?data.PackageStatus:'-'}}</td>
                        <td (click)="detailsPage(data)" style="cursor: pointer;"><img src="../../assets/images/view.png" style="width: 30px;"></td>

                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="" style="text-align: center;font-size: x-large;">No Data Found !</div>
    </div>

    <div class="d-flex justify-content-center">
        <pagination-controls   
          (pageChange)="onTableDataChange($event)"
        >
        </pagination-controls>
      </div>
</div>
<ngx-spinner type="ball-scale-multiple">loading...</ngx-spinner>
