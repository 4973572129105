<div class="bodycontent">
    <div class="dashboard-cards">
        <div class="row" >
            <!-- Earnings (Monthly) Card Example -->

            <div class="col-xl-2 col-md-6 mb-3" *ngFor="let data of items">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="card-content">
                            <h5 class="card-title">{{data.title}}</h5>
                        <span>{{data.value}}</span>
                        </div>
                        
                      </div>
                </div>
            </div>
        </div>
    </div>




     
        


        <div class="tab-list">

        <div  class="tab active" id="tab1" #tabElement (click)="setActiveTab('tab1')">
        <span class="tab-item">Tab1</span>
    </div>
        <div  class="tab" id="tab2" #tabElement (click)="setActiveTab('tab2')">        <span class="tab-item">Tab2</span>
        </div>
        <div  class="tab" id="tab3" #tabElement (click)="setActiveTab('tab3')">        <span class="tab-item">Tab3</span>
        </div>
        <div  class="tab" id="tab4" #tabElement (click)="setActiveTab('tab4')">        <span class="tab-item">Tab4</span>
        </div>
        <div  class="tab" id="tab5" #tabElement (click)="setActiveTab('tab5')">        <span class="tab-item">Tab5</span>
        </div>
        <div  class="tab" id="tab6" #tabElement (click)="setActiveTab('tab6')">        <span class="tab-item">Tab6</span>
        </div>

      
        </div>
        
      <div class="tab-content">
        <div *ngIf="activeTab === 'tab1'">
          Content for Tab 1
        </div>
        <div *ngIf="activeTab === 'tab2'">
          Content for Tab 2
        </div>
        <div *ngIf="activeTab === 'tab3'">
          Content for Tab 3
        </div><div *ngIf="activeTab === 'tab4'">
            Content for Tab 4
        </div><div *ngIf="activeTab === 'tab5'">
            Content for Tab 5
        </div><div *ngIf="activeTab === 'tab6'">
            Content for Tab 6
          </div>
      </div>

</div>
 