<div class="main ">
    <div class="panel panel-primary">
        <div class="panel-heading">
            <div class="row " style="border-bottom: 1px solid;margin-left: 10px;margin-right: 10px;">
                <div class="col-md-4">
                    <button class="bckbtn" (click)="BackBtn()">Back</button>
                </div>
                <div class="col-md-6">
                    <h3 class="textcolor" *ngIf="CustomerPlanDetails&&CustomerPlanDetails.AliasName">
                        {{CustomerPlanDetails.AliasName}}</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <span class="col-xs-4">CustRec ID:<span class="textcolor" style="font-size: 20px;"
                            *ngIf="CustomerPlanDetails&&CustomerPlanDetails.CustRecID">{{CustomerPlanDetails.CustRecID}}</span></span>
                </div>
                <div class="col-md-3">
                    <span class="col-xs-4">Status:<span class="textcolor" style="font-size: 20px;"
                            *ngIf="CustomerPlanDetails&&CustomerPlanDetails.Status">{{CustomerPlanDetails.Status}}</span></span>
                </div>
                <div class="col-md-3">
                    <span class="col-xs-4"> Package Status:<span class="textcolor" style="font-size: 20px;"
                            *ngIf="CustomerPlanDetails&&CustomerPlanDetails.PackageStatus">{{CustomerPlanDetails.PackageStatus?CustomerPlanDetails.PackageStatus:'NA'}}</span></span>
                </div>
            </div>
        </div>
        <div class="panel-body">
            <div class="ng-pristine ng-invalid ng-invalid-required">
                <div class="row" style="margin-top:10px;">
                    <div class="col-md-4 service-card-no">
                        <div class="row">
                            <div class="col-md-12">
                                <h3 *ngIf="CustomerPlanDetails&&CustomerPlanDetails.AliasName" class="">
                                    {{CustomerPlanDetails.AliasName}} Details</h3>
                            </div>
                        </div>
                        <ul class="list-group">
                            <li class="list-group-item">
                                <span><strong>Package Name:</strong></span>
                                <span class="pull-right ng-binding"
                                    *ngIf="CustomerPlanDetails&&CustomerPlanDetails.AliasName">{{CustomerPlanDetails.AliasName}}
                                </span>
                            </li>
                            <li class="list-group-item">
                                <span><strong>Created On:</strong></span>
                                <span class="pull-right ng-binding"
                                    *ngIf="CustomerPlanDetails&&CustomerPlanDetails.CreatedDate">{{CustomerPlanDetails.CreatedDate*1000
                                    |date:"dd-MMM-yyyy"}}
                                    </span>
                                    <!-- HH:MM a -->
                                    <!-- <span class="pull-right ng-binding" *ngIf="PackageAuditDetails&&PackageAuditDetails.PackageCreatedDate">{{(PackageAuditDetails.PackageCreatedDate)*1000|date:'dd MMM YYYY hh:mm a'}}</span> -->
                            </li>
                            <li class="list-group-item">
                                <span><strong>Expiry Date:</strong></span>
                                <span class="pull-right ng-binding"
                                    *ngIf="CustomerPlanDetails&&CustomerPlanDetails.PackageExpiryDate">{{CustomerPlanDetails.PackageExpiryDate*1000
                                    |date:"dd-MMM-yyyy "}}</span>
                                    <!-- HH:MM a -->
                            </li>
                            <li class="list-group-item">
                                <span><strong>City:</strong></span>
                                <span class="pull-right ng-binding"
                                    *ngIf="CustomerPlanDetails&&CustomerPlanDetails.ServiceAreaName">{{CustomerPlanDetails.ServiceAreaName}}</span>
                            </li>
                            <li class="list-group-item">
                                <span><strong>Payment Status:</strong></span>
                                <span class="pull-right ng-binding"
                                    *ngIf="CustomerPlanDetails&&CustomerPlanDetails.PaymentStatus">{{CustomerPlanDetails.PaymentStatus}}</span>
                            </li>
                            <li class="list-group-item">
                                <span><strong> Price:</strong></span>
                                <span class="pull-right ng-binding"
                                    *ngIf="CustomerPlanDetails&&CustomerPlanDetails.CurrencyType">{{CustomerPlanDetails.CurrencyType}} {{CustomerPlanDetails.Price}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-8 service-card-no">
                        <div class="row">
                            <div class="col-md-3">
                                <button type="button" class="anvayaabutton-primary-lg" style="width: fit-content;" data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"> 
                                    {{name}} Package
                                </button>
                                <!-- <button [hidden]="!freezbtn" type="button" class="btn btn-primary"
                                    data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    Un Freez Package
                                </button> -->

                                
                            </div>
                            <!-- <div class="col-md-3">
                                <button class="anvayaabutton-secondary-lg" style="width: fit-content;" (click)="DownGradePackages()">Downgrade Packages</button>
                            </div> -->
                                                    <!-- freez package details are below -->
                        
                            <div class="panel-default col-md-6" *ngIf="freezeandunfreezeDetails && freezeandunfreezeDetails.length > 0">
                                <!-- *ngIf="freezeandunfreezeDetails.FreezeDetails[0] != null" -->
                                <label for="" class="panel-title">Freeze & Un-Freeze details</label>
                                <div class="card" >
                                    <table class="table  table-striped table-hover">
                                        <thead>
                                            <tr style="text-align: center;">
                                                <th scope="col">Freeze Date</th>
                                                <th scope="col">Un-Freeze Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let freezedata of freezeandunfreezeDetails" style="text-align: center;">
                                                <td>
                                                    {{freezedata?.FreezeDate *1000| date:"dd-MMM-yyyy"}}
                                                </td>
                                                <td>
                                                    {{freezedata?.UnFreezeDate *1000| date:"dd-MMM-yyyy"}}
                                                </td>
                                             
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                       
                        </div>

                        <div class="finalDetailsCarsMrg" *ngIf="downplanList">
                            <div class="row">
                                <div class="col-md-4" *ngFor="let plans of LowerLevelPlans">
                                    <div class="card">
                                        <div class="card-body text-center">
                                                 <label><h4>{{plans.Name}}</h4></label>
                                            <ul class="list-group">
                                                <li class="list-group-item">
                                                    <span class="pull-left"><strong>Per Month</strong></span>
                                                    <span
                                                        class="pull-right ng-binding">${{plans.MonthlyPrice}}</span>
                                                </li>
                                                <li class="list-group-item">
                                                    <span class="pull-left"><strong>PerYear </strong></span>
                                                    <span
                                                        class="pull-right ng-binding">${{plans.YearlyPrice}}</span>
                                                </li>
                                            </ul>
                                            <button type="button" class="btn btn-primary text-center"
                                            (click)="planDetails(plans)">
                                            select
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {{noLowplans}}
                            </div>
                        </div>
                        <div *ngIf="selectionMonths" class="text-center finalDetailsCarsMrg">
                            <div class="row">
                                <div class="card col-md-3">
                                    <div class="card-titel">Select Months</div>
                                    <div class="card-body">
                                        <form [formGroup]="downGradePeriod">
                                            <select class="form-select" formControlName="TimePeriodInMonths">
                                                <option Selected>Select</option>
                                                <option *ngFor="let month of Months" value={{month}}>{{month}}</option>
                                            </select>
                                        </form>
                                    </div>
                                    <div><button class="btn btn-primary"
                                            (click)="getDowngradPlansPriceCalculationDetails()">Select</button></div>
                                </div>
                            </div>


                        </div>
                        <div *ngIf="caliculationDetails">
                            <div class="row text-center finalDetailsCarsMrg">
                                <div class="col-md-6">
                                    <div class="card">
                                        <div class="card-titel">
                                            <h3>Services</h3>
                                        </div>
                                        <div class="card-body">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Service Name</th>
                                                        <th>Total Limit</th>
                                                        <th>Available Limit</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let servicedata of Services">
                                                    <tr>
                                                        <td>{{servicedata.ServiceName}}</td>
                                                        <td>{{servicedata.TotalLimit}}</td>
                                                        <td>{{servicedata.AvailableLimit}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="card-titel">
                                                <h5>New Plan</h5>
                                            </div>
                                            <ul class="list-group">
                                                <li class="list-group-item">
                                                    <span class="pull-left"><strong>Plan</strong></span>
                                                    <span
                                                        class="pull-right ng-binding">{{NewPlanDetails.AliasName}}</span>
                                                </li>
                                                <li class="list-group-item">
                                                    <span class="pull-left"><strong>Start Date</strong></span>
                                                    <span
                                                        class="pull-right ng-binding">{{NewPlanDetails.StartDate*1000|date:"dd-MMM-yyyy"}}</span>
                                                        <!-- {{(PackageAuditDetails.PackageCreatedDate)*1000|date:'dd MMM YYYY hh:mm a'}} -->
                                                        
                                                </li>
                                                <li class="list-group-item">
                                                    <span class="pull-left"><strong>Expiry Date</strong></span>
                                                    <span
                                                        class="pull-right ng-binding">{{NewPlanDetails.EndDate*1000|date:"dd-MMM-yyyy"}}</span>
                                                </li>
                                                <li class="list-group-item">
                                                    <span class="pull-left"><strong>Plan Price</strong></span>
                                                    <span
                                                        class="pull-right ng-binding">{{NewPlanDetails.PlanPrice}}</span>
                                                </li>
                                                <li class="list-group-item">
                                                    <span class="pull-left"><strong>Refund Amount</strong></span>
                                                    <span
                                                        class="pull-right ng-binding">{{NewPlanDetails.RefundAmount}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="container">
                                <div class="row">
                                    <div class="col align-self-end">
      <button type="button" class="btn btn-primary mt-10" (click)="downgradeplan()">Procced</button>

                                    </div>
                                </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 10px;">
        <form [formGroup]="FreezePackageDetails">
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Freez Package</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <label>Enter {{name}} Date:</label>
                            </div>
                            <input formControlName="FreezeDate" type="datetime-local" alt="date" required=""> <br>
                            <label style="color: red;" for=""
                                *ngIf="this.FreezePackageDetails.controls['FreezeDate'].status=='INVALID'&&freezbtnval">*enter
                                {{name}} date</label>
                            <div *ngIf="UnfreezTab">
                                <div>
                                <label>Enter UnFreezeDate</label>
                                </div>
                                
                                <input formControlName="UnFreezeDate" type="datetime-local" alt="date" required> <br>
                            <label style="color: red;" for=""
                                *ngIf="this.FreezePackageDetails.controls['UnFreezeDate'].status=='INVALID'&&freezbtnval">*enter
                                UnFreezeDate date</label>
                            </div>
                                <div>
                                    <p>Resone For Freez</p>
                                    <textarea formControlName="ReasonForFreeze" required=""> </textarea><br>
                                    <label style="color: red;" for=""
                                        *ngIf="this.FreezePackageDetails.controls['ReasonForFreeze'].status=='INVALID'&&freezbtnval">*enter
                                        </label>  
                                </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="anvayaabutton-secondary" data-bs-dismiss="modal"
                                #close>Close</button>
                            <button type="button" class="anvayaabutton-primary" style="width: fit-content;" (click)="FreezPackage(name)">{{name}}
                                package</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>


    <div class="modal fade" id="exampleModal-1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content" style="width:600px;">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Select how many Months ?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
            </div>
        </div>
    </div>

    <div class="panel-default">
        <label for="" class="panel-title">Package History</label>
        <div class="card" >
            <table class="table  table-striped table-hover">
                <thead>
                    <tr style="text-align: center;">
                        <th scope="col">#ID </th>
                        <th scope="col" class="text-start">Package Name</th>
                        <th scope="col">Membership Created Date</th>
                        <th scope="col">Membership Expiry Date</th>
                        <th scope="col">Freez Date</th>
                        <th scope="col">UnFreez Date</th>
                        <!-- <th scope="col">Subscription CREATED Date </th>
                        <th scope="col">Subscription EXPIRY Date</th> -->
                        <th scope="col" class="text-start">Payment Type/Mode</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditdata of PackageAuditDetails" style="text-align: center;">
                        <td>
                            {{auditdata?.AnvayaaPaymentID}}
                        </td>
                        <td class="text-start">
                            {{auditdata?.AliasName |replaceUnderscore}}-{{auditdata?.PurchaseType|replaceUnderscore}}
                        </td>
                        <td>
                            {{(auditdata?.PackageCreatedDate)*1000|date:'dd MMM YYYY'}}
                        </td>
                        <!-- hh:mm a -->
                        <td>
                            {{(auditdata?.PackageExpiryDate)*1000|date:'dd MMM YYYY'}}
                        </td>
                        <td>
                            {{ auditdata?.FreezeDetails[0]?.FreezeDate ? (auditdata.FreezeDetails[0]?.FreezeDate * 1000| date:'dd MMM yyyy') :'-'  }}
                            
                        </td>
                        <td>
                            {{ auditdata?.FreezeDetails[0]?.FreezeDate ? (auditdata.FreezeDetails[auditdata.FreezeDetails.length - 1]?.UnFreezeDate * 1000| date:'dd MMM yyyy') :'-'  }}

                        </td>
                        <!-- hh:mm a -->
                        <!-- <td>
                            {{(auditdata.SubscriptionCreatedDate)*1000|date:'dd MMM YYYY hh:mm a'}}
                        </td>
                        <td>
                            {{(auditdata.SubscriptionExpiryDate)*1000|date:'dd MMM YYYY hh:mm a'}}
                        </td> -->
                        <td class="text-start">
                            {{auditdata?.PaymentType}}/ {{auditdata?.PaymentMode}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  

</div>
<ngx-spinner type="ball-scale-multiple">loading...</ngx-spinner>