<div class="Main">
    <header></header>
     <button class="title"><h3>Anchor Profile</h3></button>
    <div class="row container-fluid ">
        <div class="col-3 col-lg-3">
            <img src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80"
                class="image--cover">
            
        </div>
        <div class=" col-lg-9">
       
            <div class="container">
                <div class="row justify-content-center">
                  <!-- <div class="col-sm-3 statustables "  (click)="profiel()">
                
                    <table class="table text" >
                      <tr routerLink="/anchorprofiel"><td>Anchor's Profile</td></tr>
                      <tr>
                        <td>XRG helth care</td>
                        
                      </tr>
                    </table>
                  </div> -->
                  <div class="col-sm-3 statustables "  (click)="contactDetails()">
                    <table class="table text">
                      <tr>
                        <td><h1></h1> contactDetails</td>
                      </tr>
                      <!-- <tr>
                        <td>Prashanth</td>
                      </tr> -->
                      
                    </table>
                   
                  </div>
                  <!-- <div class="col-sm-3 statustables "  (click)="LocationDetails()" disabled="true">
                    
                    <table class="table text">
                      <tr>
                        <td routerLink="/contactdetails"> LocationDetails</td>
                      </tr>
                      <!-- <tr>
                        <td>Prashanth</td>
                      </tr> -->
                      
                    <!-- </table> -->
                   
                  <!-- </div> --> 
                  <div class="col-sm-3 statustables  hospitaldiv" (click)="comapnydetails()">
                     <table class="table text">
                      <tr><td><h1></h1>CompanyDetails</td></tr>
                      <!-- <tr><td>Hyderabad</td></tr> -->
                    
                    </table>
                  </div>
                
                </div>
              </div>
    
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-sm-3 statustables "  (click)="bankDetails()" >
                
                    <table class="table text">
                      <tr><td>BankDetails</td></tr>
                      <tr>
                     
                       
                      </tr>
                    </table>
                  </div>
          
                  <div class="col-sm-3   statustables hospitaldiv" (click)="agreementDetails()">
                     <table class="table text">
                      <tr><td>AgreementDetails</td></tr>
                      <!-- <tr><td>1000000</td></tr> -->
                    
                    </table>
                  </div>
                  <!-- <div class="col-sm-3  statustables" (click)="revenue()">
                    
                    <table class="table text">
                      <tr>
                        <td>RevenueDetails</td>
                      </tr>
                      <tr>
                        <td>30000</td>
                      </tr>
                      
                    </table>
                   
                  </div> -->
                </div>
              </div>
       
            
        </div>
       
      </div>
    </div>
  