<div class="bodycontent corporate-details">
    <div class="d-flex align-items-center justify-content-between">
        <button class="btn btn-link" (click)="gotoMytasks()">← Go Back</button>
        <h5 class="text-center flex-grow-1" style="color: #914573;">Nishchint Corporate Details</h5>
    </div>
    <div class="card corporate-details-card p-2 mb-2">
        <div class="row">
            <div class="col-md-12">
                <h6 class="corporate-details-card-title text-primary">🏢 Company Details</h6>
                <hr />
                <div class="row">
                    <p class="col-md-3"><strong>Company ID:</strong> {{ companyDetails?.companyID }}</p>
                    <p class="col-md-3"><strong>Company Name:</strong> {{ companyDetails?.companyName }}</p>
                    <p class="col-md-3">
                        <strong>MOU:</strong>
                        <button *ngIf="companyDetails?.mou" class="btn btn-link pl-5" (click)="viewDocument('mou')">view</button>
                        <span *ngIf="!companyDetails?.mou" class="notavailable">N/A</span>
                    </p>
                    <p class="col-md-3">
                        <strong>PAN Number:</strong> {{ companyDetails?.PAN }}
                        <button class="btn btn-link p-0" *ngIf="companyDetails?.PAN" (click)="viewDocument('pan')">view</button>
                        <span *ngIf="!companyDetails?.PAN" class="notavailable">N/A</span>
                    </p>
                    <p class="col-md-4"
                     [ngClass]=""
                    >
                        <strong>GST Number:</strong> {{ companyDetails?.GST }}
                        <button class="btn btn-link p-0" *ngIf="companyDetails?.GST" (click)="viewDocument('gst')">view</button>
                        <span *ngIf="!companyDetails?.GST" class="notavailable">N/A</span>
                    </p>
                </div>
            </div>

            <div class="col-md-12">
                <h6 class="corporate-details-card-title text-success">📝 Plan & Payment Details</h6>
                <hr />
                <div class="row">
                    <div *ngFor="let package of companyDetails?.companyPackageDetails">
                        <div class="row p-2" style="border-right: 1px solid whitesmoke;">
                            <p class="col-md-3"><strong>PlanID:</strong> {{ package?.planDetails[0]?.planID }}</p>
                            <p class="col-md-3"><strong>Plan Name:</strong> {{ package?.planDetails[0]?.aliasName }}</p>
                            <p class="col-md-3"><strong>Plan StartDate:</strong> {{ package?.startDate *1000|
                                date:"dd-MMM-yyyy"}}</p>
                            <p class="col-md-3"><strong>Plan EndDate:</strong> {{ package?.endDate *1000|
                                date:"dd-MMM-yyyy"}}</p>
                            <p class="col-md-3"><strong>Selling Price:</strong> ₹{{ package?.planDetails[0].sellingPrice
                                | number }}</p>
                            <p class="col-md-3"><strong>Procurement Price:</strong> ₹{{
                                package?.planDetails[0].procurementPrice | number }}</p>
                            <p class="col-md-3"><strong>Number of Employees:</strong> {{
                                package?.planDetails[0].noOfEmployees }}</p>
                        </div>
                        <!-- Payment Details Section -->

                        <table class="table table-bordered table-hover">
                            <thead class="table-dark">
                                <tr>
                                    <th>Payment Type</th>
                                    <th>Payment Mode</th>
                                    <th>Payment Date</th>
                                    <th>Tally Number</th>
                                    <th>Bank Ref. Number</th>
                                    <th>Amount (₹)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let payment of package?.paymentDetails">
                                    <td>{{ package?.paymentDetails[0]?.paymentType }}</td>
                                    <td>{{ package?.paymentDetails[0]?.paymentMode }}</td>
                                    <td>{{ package?.paymentDetails[0]?.paymentDate *1000| date:"dd-MMM-yyyy"}}</td>

                                    <td>{{ package?.paymentDetails[0]?.tallyNumber }}</td>
                                    <td>{{ package?.paymentDetails[0]?.bankreferenceNumber }}</td>
                                    <td>{{ package?.paymentDetails[0]?.price | number }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-md-12 d-flex justify-content-end">
                <button class="btn btn-danger p-2 me-2" type="button" (click)="approveOrRejectCorporate('Rejected')">Reject</button>
                <button class="btn btn-primary p-2" type="button" (click)="approveOrRejectCorporate('Approved')">Approve</button>
            </div>
        </div>
    </div>
</div>