<div class="bodycontent">

    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div class="card panel-default">
                <div class="card-body">
                    <h3 class="reuestHeading">My Requests</h3>
                    <form [formGroup]="filtersForm">
                        <div class="row">
                            <div class='col-md-5'>
                                <span class="formDetails">Select Start Date :</span>
                                <div class=" input-group date">
                                    <input formControlName="StartDate" type='date' class="startdate form-control"
                                        placeholder="Select Start Date">
                                </div>
                            </div>
                            <div class='col-md-5'>
                                <span class="formDetails">Select End Date :</span>
                                <div class=" input-group date">
                                    <input formControlName="EndDate" type='date' class="startdate form-control"
                                        placeholder="Select Start Date">
                                </div>
                            </div>
                            <div class="col-md-2">
                                <span class="formDetails">&nbsp;</span>
                                <div class="">
                                    <button class="btn btn-cta" (click)="onSearch()">Search</button>
                                </div>

                            </div>
                        </div>
                    </form>


                    <br>

                    <table class="table table-striped table-hover">
                        <thead class="tablehead">
                            <tr>
                                <th scope="col">Request ID </th>
                                <th scope="col">Sponser Details </th>
                                <th scope="col">Status</th>
                                <th scope="col">Service Date </th>
                                <th scope="col"> </th>
                            </tr>
                        </thead>
                        <tbody class="tableBody">
                            <tr *ngFor="let myRequestsObj of myRequestsArr">
                                <td   (click)="requestDetails(myRequestsObj)"
                                >{{ myRequestsObj?.["RequestID"]}}
                                    <br>
                                    <b>{{myRequestsObj["AliasName"]|replaceUnderscore |titlecase}}</b>
                                </td>
                                <td >
                                    <div *ngIf='myRequestsObj?.["CustomerDetails"]?.["Name"]'>
                                        {{myRequestsObj?.["CustomerDetails"]?.["Name"]}}
                                        <br>
                                        <b>{{myRequestsObj["ServiceAreaName"]|replaceUnderscore |titlecase}}</b></div>
                                   
                                </td>
                               
                                <td>{{myRequestsObj["Status"]}}</td>
                                <td>{{myRequestsObj["RequestedDate"]*1000|date:"dd-MM-YYYY"}}</td>
                                <td>
                                    <div [hidden]='!isHideTheCancelButton(myRequestsObj)'>
                                        <button type="button" class="btn btn-Cancel" data-bs-toggle="modal"
                                        data-bs-target="#cancelRequest" data-bs-whatever="@getbootstrap"  (click)="onCancelClick(myRequestsObj)">Cancel Request</button>

                                    </div>
                                    
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div *ngIf=" myRequestsArr.length<1" style="text-align: center;font-size: x-large;">No Data Found !
                </div>
            </div>
        </div>


    </div>

    <div class="modal fade" id="cancelRequest" tabindex="-1"
                                        aria-labelledby="changeDateLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <!-- <h1 class="modal-title fs-5" id="changeDateLabel">ChangeDate</h1> -->
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close" #cancelRequestCloseBtn></button>
                                                </div>
                                                <div class="modal-body">
                                                    <h3>Are you sure you want to cancel the request</h3>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
                                                    <button type="button" class="btn btn-anvayaaPurple" (click)='onCancelSubmit()'>Yes Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


</div>
<ngx-spinner type="ball-scale-multiple">loading...</ngx-spinner>