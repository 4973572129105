 

<div class="row">

<div class="col-md-2">
  <button (click)="backbtn()" class=" backbtn btn btn-primary">BACK</button>
</div>
<div class="row border border-3">
    <div class="cards1 card text-white  mb-4" >
        <div class="card-header">Total Count</div>
        <div class="card-body">
          <h5 class="card-title">Primary card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
        </div>
      </div>
      <div class="cards2 card text-white  mb-4" >
        <div class="card-header">Round</div>
        <div class="card-body">
          <h5 class="card-title">Primary card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
        </div>
      </div>
      <div class="cards3 card text-white  mb-4" >
        <div class="card-header text-bold">Ongoing Jobs</div>
        <div class="card-body">
          <h5 class="card-title">Primary card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
        </div>
      </div>
      <div class="cards4 card text-white bg-90F7EC  mb-4" >
        <div class="card-header">Bill Uploads</div>
        <div class="card-body">
          <h5 class="card-title text-nowrap ">Primary card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
        </div>
      </div>
      <div class="cards5 card text-white  mb-4" >
        <div class="card-header text-red" >Revenue</div>
        <div class="card-body">
          <h5 class="card-title">Primary card title</h5>
          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
        </div>
      </div>
</div> 
<div class="row">
  <h2>Team Wise Data</h2>

  <div class="col-md-6">

  
<div class="table-responsive">
  <table class=" table table-bordered border-primary  " >
    <thead>
      <tr >
        <th scope="col ">#</th>
        <th scope="col">Name</th>
        <th scope="col">Total Assigned</th>
        <th scope="col">Completed Process</th>
    
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">1</th>
        <td>Cell</td>
        <td>Cell</td>
        <td>Cell</td>
        
     
    </tbody>
  </table>
</div>

</div>
</div>