<div class="bodycontent">

    <h5 class="text-xl font-bold mb-4 p-2">Nishchint Customer Details(Wellbeing Calls) - SupportExecutive Assignment </h5>

    <!-- Responsive Table Wrapper -->
    <div class="overflow-auto w-full m-4">
        <table style="width: 98%;" class="border-collapse border border-gray-200 table-fixed">
            <thead class="tablehead bg-gray-100">
                <tr>
                    <th class="border border-gray-300 px-4 py-2 text-left w-1/6">Customer ID</th>
                    <th class="border border-gray-300 px-4 py-2 text-left w-1/6">Customer Name</th>
                    <!-- <th class="border border-gray-300 px-4 py-2 text-left">Email</th> -->
                    <th class="border border-gray-300 px-4 py-2 text-left w-1/6">Phone Number</th>
                    <th class="border border-gray-300 px-4 py-2 text-left w-1/6">City</th>
                    <th class="border border-gray-300 px-4 py-2 text-left w-1/6">Onboarding Status</th>
                    <th class="border border-gray-300 px-4 py-2 text-left w-1/6">Assign Agent</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let customer of customersDetails">
                    <td class="border border-gray-300 px-4 py-2">{{ customer?.employeeID }}</td>
                    <td class="border border-gray-300 px-4 py-2">{{ customer?.firstName }} {{ customer?.lastName }}</td>
                    <!-- <td class="border border-gray-300 px-4 py-2">{{ customer.emailID }}</td> -->
                    <td class="border border-gray-300 px-4 py-2">{{ customer?.phoneNumber }}</td>
                    <td class="border border-gray-300 px-4 py-2">{{ customer?.city }}</td>
                    <td class="border border-gray-300 px-4 py-2">{{ customer?.onBoardingStatus }}
                    </td>
                    <td class="border border-gray-300 px-4 py-2">
                        <select id="employeeDropdown" class="border border-gray-300 rounded px-2 py-2"
                            (change)="onAgentChange($event,customer?.employeeID)">
                            >
                            <option disabled selected>Select Support Executive</option>
                            <option *ngFor="let agent of agentsDetails" [value]="agent.EmployeeID">
                                {{ agent.FirstName }} {{ agent.LastName }}
                            </option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>