<div class="bodycontent" [formGroup]="updateRequestDetails">
    <img class="backArrow" src="../../../assets/images/left-arrow.png" (click)="myTasks()">
    <div>
        <div class="row">
            <div class="col-md-4">
                <h5><u class="textDecration">Details</u></h5>
                <table>
                    <tr>
                        <td class="serviceNames">Sponser</td>
                        <td>:</td>
                        <td *ngIf="requestDetailsObj.CustomerDetails&&requestDetailsObj.CustomerDetails.Name">
                            {{requestDetailsObj.CustomerDetails.Name}}</td>
                    </tr>
                    <tr *ngIf="BeneficiaryName">
                        <td class="serviceNames">Beneficiary</td>
                        <td>:</td>
                        <td *ngIf="BeneficiaryName">{{BeneficiaryName}}</td>
                    </tr>
                    <tr>
                        <td class="serviceNames"> PMS </td>
                        <td>:</td>
                        <td *ngIf="requestDetailsObj.PmsTransaction&&requestDetailsObj.PmsTransaction.ClosingBalance">
                            {{requestDetailsObj.PmsTransaction.ClosingBalance}}
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-4">
                <h5><u class="textDecration">Services</u></h5>
                <table>
                    <tr>
                        <td class="serviceNames">Name</td>
                        <td>:</td>
                        <td *ngIf="requestDetailsObj.RequestDetails&&requestDetailsObj.RequestDetails.AliasName!=null">
                            {{requestDetailsObj.RequestDetails.AliasName| replaceUnderscore| titlecase}}</td>
                    </tr>
                    <tr>
                        <td class="serviceNames">Service Name</td>
                        <td>:</td>
                        <td
                            *ngIf="requestDetailsObj.RequestDetails && requestDetailsObj.RequestDetails.SubSubCategoryName!=null">
                            {{requestDetailsObj.RequestDetails.SubSubCategoryName| replaceUnderscore| titlecase}}</td>
                    </tr>
                    <tr>
                        <td class="serviceNames">RequestedDate</td>
                        <td>:</td>
                        <td
                            *ngIf="requestDetailsObj.RequestDetails&&requestDetailsObj.RequestDetails.RequestedDate!=null">
                            {{(requestDetailsObj.RequestDetails.RequestedDate)*1000|date:'dd MMM yyyy'}}</td>
                    </tr>
                    <tr>
                        <td class="serviceNames">Budget</td>
                        <td>:</td>
                        <td *ngIf="requestDetailsObj.RequestDetails && requestDetailsObj.RequestDetails.Budget">
                            {{requestDetailsObj.RequestDetails.Budget}}
                        </td>
                    </tr>
                    <tr>
                        <td class="serviceNames"> Vendor</td>
                        <td>:</td>
                        <td *ngIf="ActiveVendorObj && ActiveVendorObj.VendorName">
                            {{ActiveVendorObj.VendorName}}
                        </td>
                    </tr>

                </table>
            </div>
            <div class="col-md-4">
                <h5><u class="textDecration">Status</u></h5>
                <table>
                    <tr>
                        <td class="serviceNames">Request</td>
                        <td>:</td>
                        <td *ngIf="requestDetailsObj.RequestDetails&&requestDetailsObj.RequestDetails.Status">
                            {{requestDetailsObj.RequestDetails.Status | replaceUnderscore| titlecase}}</td>
                    </tr>
                    <tr>
                        <td class="serviceNames">Job</td>
                        <td>:</td>
                        <td *ngIf="ActiveVendorObj&&ActiveVendorObj.Status">
                            {{this.requestObjData[0].Status}}
                        </td>
                    </tr>
                    <tr>
                        <td class="serviceNames">Bill</td>
                        <td>:</td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
        <hr style="height:3px;">
    </div>



    <div class="tabs row">

        <div class="col-md-3 titleButtonsCss" (click)="taskesTab()"
            [ngStyle]="{'background-color': tasksButton ? '#F79122' :''}">

            <label for="" class="tasks"> Tasks</label>

        </div>
        <div class="col-md-3 titleButtonsCss" (click)="jobsTab()"
            [ngStyle]="{'background-color': jobsAndBillsTabButton ? '#F79122' : ''}">


            <label for="" class="tasks"> Jobs And Bills</label>

        </div>
        <div class="col-md-2 titleButtonsCss" (click)="requestDetailTab()"
            [ngStyle]="{'background-color': requestDetailsTabButton ? '#F79122' : ''}">
            <label for="" class="tasks"> Request Details</label>

        </div>
        <div class="col-md-2 titleButtonsCss" (click)="customerDetailTab()"
            [ngStyle]="{'background-color': customerDetailsTabButtons ? '#F79122' : ''}">


            <label for="" class="tasks"> Customer Details</label>
        </div>
        <div class="col-md-2 titleButtonsCss" (click)="ActiviteTab()"
            [ngStyle]="{'background-color': ActivitesButton ? '#F79122' : ''}">

            <label for="" class="tasks"> Activites</label>
        </div>

    </div>
    <div>
        <div class="card-header">

            <h5 style="text-align: center;"><strong><u>{{this.selectedCategoryName|replaceUnderscore}}
                        Requirements </u></strong></h5>
        </div>
        <div class="card">
            <div class="card-body" *ngIf="this.selectedCategoryName == 'HOME_VISITS_BY_PHYSIOTHERAPIST'">


                <table>

                    <tr>

                        <td>Order Type</td>
                        <td>:</td>
                        <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.OrderType}}</td>
                    </tr>
                    <tr>

                        <td>Nurse Type</td>
                        <td>:</td>
                        <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.NurseType}}</td>

                    </tr>
                    <tr>

                        <td>Mobile Number</td>
                        <td>:</td>
                        <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.MobileNumber}}</td>


                    </tr>
                    <tr>

                        <td>Alternate Mobile Number</td>
                        <td>:</td>
                        <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.AlternateMobileNumber}}</td>


                    </tr>
                    <tr>

                        <td>Area</td>
                        <td>:</td>
                        <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.Area}}</td>


                    </tr>
                    <tr>

                        <td>Age</td>
                        <td>:</td>
                        <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.Age}}</td>


                    </tr>
                    <tr>

                        <td>Mobility</td>
                        <td>:</td>
                        <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.Mobility}}</td>


                    </tr>
                    <tr>

                        <td>Service Type</td>
                        <td>:</td>
                        <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.ServiceType}}</td>


                    </tr>
                    <!-- <tr>
    
                        <td>Food In take</td>
                        <td>:</td>
                        <td>{{requestDetailsObj.RequestDetails.OnCallDetails.FoodIntake}}</td>
    
    
                    </tr> -->
                    <tr>

                        <td>Service Required</td>
                        <td>:</td>
                        <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.ServiceRequired}}</td>


                    </tr>
                    <tr>

                        <td>Price Informed</td>
                        <td>:</td>
                        <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.PriceInformed}}</td>


                    </tr>
                    <tr>

                        <td>Servicable Day</td>
                        <td>:</td>
                        <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.ServicableDay}}</td>


                    </tr>


                </table>


            </div>
            <div class="card-body" *ngIf="this.selectedCategoryName == '24X7 CARE GIVER ASSISTANCE'">


                <table>

                    <tbody>
                        <tr>

                            <td>Order Type</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.OrderType}}</td>

                        </tr>
                        <!-- <tr>
    
                            <td>NurseType</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails.CareTaker.NurseType}}</td>
    
    
                        </tr> -->
                        <tr>

                            <td>City</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.City}}</td>


                        </tr>
                        <tr>

                            <td>Mobile Number</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.MobileNumber}}</td>


                        </tr>
                        <tr>

                            <td>Alternate Mobile Number</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.AlternateMobileNumber}}</td>


                        </tr>
                        <tr>

                            <td>Area</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.Area}}</td>


                        </tr>
                        <tr>

                            <td>Age</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.Age}}</td>


                        </tr>
                        <tr>

                            <td>Mobility</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.Mobility}}</td>


                        </tr>
                        <tr>

                            <td>Weight Of The Patient</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.WeightOfThePatient}}</td>


                        </tr>
                        <tr>

                            <td>WashroomUsage</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.WashroomUsage}}</td>


                        </tr>
                        <tr>

                            <td>Food In take</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.FoodIntake}}</td>


                        </tr>
                        <tr>

                            <td>Medication</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.Medication}}</td>


                        </tr>
                        <tr>

                            <td>ExerciseActivity</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.ExerciseActivity}}</td>


                        </tr>
                        <tr>

                            <td>MedicalEquipmentAssistance</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.MedicalEquipmentAssistance}}</td>


                        </tr>
                        <tr>

                            <td>Service Required</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.ServiceRequired}}</td>


                        </tr>
                        <tr>

                            <td>Pricing Quoted</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.PricingQuoted}}</td>


                        </tr>
                        <tr>

                            <td>Price Informed</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.PriceInformed}}</td>


                        </tr>
                        <tr>

                            <td>DutyHours</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.DutyHours}}</td>


                        </tr>
                        <tr>

                            <td>ServiceDurationRequested</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.ServiceDurationRequested}}</td>


                        </tr>
                        <tr>

                            <td>Insulin</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.Insulin}}</td>


                        </tr>
                        <tr>

                            <td>InsulinText</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.InsulinText}}</td>


                        </tr>
                        <tr>

                            <td>TypeOfInsulin</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.TypeOfInsulin}}</td>


                        </tr>
                        <tr>

                            <td>ExerciseText</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.ExerciseText}}</td>


                        </tr>
                        <tr>

                            <td>NoOfPeopleToCook</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.NoOfPeopleToCook}}</td>


                        </tr>
                        <tr>

                            <td>ResidenceType</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.ResidenceType}}</td>


                        </tr>
                        <tr>

                            <td>CookingType</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.CookingType}}</td>


                        </tr>
                        <!-- <tr>
    
                            <td>MedicalProcedure</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails.CareTaker.MedicalProcedure}}</td>
    
    
                        </tr> -->
                        <tr>

                            <td>MedicationText</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.MedicationText}}</td>


                        </tr>
                        <!-- <tr >
    
                            <td>TypeOfMedicalProcedure</td>
                            <td>:</td>
                            <div  *ngFor="let reqdetails of requestDetailsObj.RequestDetails.CareTaker.TypeOfMedicalProcedure;let i = index">
                                <span>{{i+1}}.{{reqdetails.key}}</span>
                            </div>
                        </tr> -->
                        <tr>

                            <td>TypeOfEquipment</td>
                            <td>:</td>
                            <div
                                *ngFor="let reqdetails of requestDetailsObj.RequestDetails?.CareTaker?.TypeOfEquipment;let i = index">
                                <span>{{i+1}}.{{reqdetails.key}}</span>
                            </div>


                        </tr>
                        <tr>

                            <td>EquipmentText</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.EquipmentText}}</td>


                        </tr>
                        <!-- <tr>
    
                            <td>MedicalProcedureText</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails.CareTaker.MedicalProcedureText}}</td>
    
    
                        </tr> -->
                        <tr>

                            <td>DoYouWantCook</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.DoYouWantCook}}</td>


                        </tr>
                        <tr>

                            <td>DoYouWantMaid</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.DoYouWantMaid}}</td>


                        </tr>
                        <!-- <tr>
    
                            <td>DoYouWantNurseOnCall</td>
                            <td>:</td>
                            <td *ngIf="requestDetailsObj.RequestDetails.CareTaker?.DoYouWantNurseOnCall=='No'">{{requestDetailsObj?.RequestDetails?.CareTaker?.DoYouWantNurseOnCall}}</td>
                            <td  *ngIf="requestDetailsObj.RequestDetails.OnCallDetails?.DoYouWantNurseOnCall=='Yes'">{{requestDetailsObj?.RequestDetails?.OnCallDetails?.DoYouWantNurseOnCall}}</td>
    
    
                        </tr> -->
                        <!-- <tr  >
    
                            <td>OnCallServiceRequired</td>
                            <td>:</td>
                            <td *ngIf="requestDetailsObj.RequestDetails&&requestDetailsObj.RequestDetails?.CareTaker?.DoYouWantNurseOnCall=='No'">
                                <div
                                    *ngFor="let oncallservicereq of requestDetailsObj.RequestDetails?.OnCallDetails?.OnCallServiceRequired;let i = index">
                                    <span>{{i+1}}.{{oncallservicereq.key}}</span>
                                </div>
                            </td>
                            <td *ngIf="requestDetailsObj.RequestDetails&&requestDetailsObj.RequestDetails.OnCallDetails?.DoYouWantNurseOnCall=='Yes'">
                                <div
                                    *ngFor="let oncallservicereq of requestDetailsObj.RequestDetails?.OnCallDetails?.OnCallServiceRequired;let i = index">
                                    <span>{{i+1}}.{{oncallservicereq.key}}</span>
                                </div>
                            </td>
    
    
                        </tr> -->
                        <tr>

                            <td>Note</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails?.CareTaker?.Note}}</td>


                        </tr>


                    </tbody>
                </table>


            </div>
            <div class="card-body" *ngIf="this.selectedCategoryName == 'DIAGNOSTICS'">


                <table>

                    <tbody>
                        <tr>

                            <td>Order Type</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.DiagnosticService?.OrderType}}</td>

                        </tr>
                        <!-- <tr>
    
                            <td>Nurse Type</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails.DiagnosticService.NurseType}}</td>
    
                        </tr> -->
                        <tr>

                            <td>Mobile Number</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.DiagnosticService?.MobileNumber}}</td>


                        </tr>
                        <tr>

                            <td>Alternate Mobile Number</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.DiagnosticService?.AlternateMobileNumber}}</td>


                        </tr>
                        <tr>

                            <td>Area</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.DiagnosticService?.Area}}</td>


                        </tr>
                        <tr>

                            <td>Age</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.DiagnosticService?.Age}}</td>


                        </tr>
                        <!-- <tr>
    
                            <td>Mobility</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails.DiagnosticService.Mobility}}</td>
    
    
                        </tr> -->
                        <!-- <tr>
    
                            <td>Service Type</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails.DiagnosticService.ServiceType}}</td>
    
    
                        </tr> -->

                        <tr>

                            <td>Price Informed</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.DiagnosticService?.PriceInformed}}</td>


                        </tr>
                        <tr>

                            <td>Type Of Radiology</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.DiagnosticService?.TypeOfRadiology}}</td>


                        </tr>

                        <tr>

                            <td>Type Of Pathology</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.DiagnosticService?.TypeOfPathology}}</td>


                        </tr>
                        <tr>

                            <td>Note</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.DiagnosticService?.Note}}</td>


                        </tr>

                    </tbody>
                </table>


            </div>
            <div class="card-body" *ngIf="this.selectedCategoryName == 'HOME_STAY_NURSE / HOME_STAY_HELP'">


                <table>

                    <tbody>
                        <tr>

                            <td>Order Type</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.OrderType}}</td>

                        </tr>
                        <tr>

                            <td>NurseType</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.NurseType}}</td>


                        </tr>
                        <tr>

                            <td>City</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.City}}</td>


                        </tr>
                        <tr>

                            <td>Mobile Number</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.MobileNumber}}</td>


                        </tr>
                        <tr>

                            <td>Alternate Mobile Number</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.AlternateMobileNumber}}</td>


                        </tr>
                        <tr>

                            <td>Area</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.Area}}</td>


                        </tr>
                        <tr>

                            <td>Age</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.Age}}</td>


                        </tr>
                        <tr>

                            <td>Mobility</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.Mobility}}</td>


                        </tr>
                        <tr>

                            <td>Weight Of The Patient</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.WeightOfThePatient}}</td>


                        </tr>
                        <tr>

                            <td>WashroomUsage</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.WashroomUsage}}</td>


                        </tr>
                        <tr>

                            <td>Food In take</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.FoodIntake}}</td>


                        </tr>
                        <tr>

                            <td>Medication</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.Medication}}</td>


                        </tr>
                        <tr>

                            <td>ExerciseActivity</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.ExerciseActivity}}</td>


                        </tr>
                        <tr>

                            <td>MedicalEquipmentAssistance</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.MedicalEquipmentAssistance}}</td>


                        </tr>
                        <tr>

                            <td>Service Required</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.ServiceRequired}}</td>


                        </tr>
                        <tr>

                            <td>Pricing Quoted</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.PricingQuoted}}</td>


                        </tr>
                        <tr>

                            <td>Price Informed</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.PriceInformed}}</td>


                        </tr>
                        <tr>

                            <td>DutyHours</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.DutyHours}}</td>


                        </tr>
                        <tr>

                            <td>ServiceDurationRequested</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.ServiceDurationRequested}}</td>


                        </tr>
                        <tr>

                            <td>Insulin</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.Insulin}}</td>


                        </tr>
                        <tr>

                            <td>InsulinText</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.InsulinText}}</td>


                        </tr>
                        <tr>

                            <td>TypeOfInsulin</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.TypeOfInsulin}}</td>


                        </tr>
                        <tr>

                            <td>ExerciseText</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.ExerciseText}}</td>


                        </tr>
                        <!-- <tr>
    
                            <td>NoOfPeopleToCook</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails.CareTaker.NoOfPeopleToCook}}</td>
    
    
                        </tr>
                        <tr>
    
                            <td>ResidenceType</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails.CareTaker.ResidenceType}}</td>
    
    
                        </tr>
                        <tr>
    
                            <td>CookingType</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails.CareTaker.CookingType}}</td>
    
    
                        </tr> -->
                        <tr>

                            <td>MedicalProcedure</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.MedicalProcedure}}</td>


                        </tr>
                        <tr>

                            <td>MedicationText</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.MedicationText}}</td>


                        </tr>
                        <tr>

                            <td>TypeOfMedicalProcedure</td>
                            <td>:</td>
                            <div
                                *ngFor="let reqdetails of requestDetailsObj?.RequestDetails?.CareTaker?.TypeOfMedicalProcedure;let i = index">
                                <span>{{i+1}}.{{reqdetails.key}}</span>
                            </div>
                        </tr>
                        <tr>

                            <td>TypeOfEquipment</td>
                            <td>:</td>
                            <div
                                *ngFor="let reqdetails of requestDetailsObj?.RequestDetails?.CareTaker?.TypeOfEquipment;let i = index">
                                <span>{{i+1}}.{{reqdetails.key}}</span>
                            </div>


                        </tr>
                        <tr>

                            <td>EquipmentText</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.EquipmentText}}</td>


                        </tr>
                        <tr>

                            <td>MedicalProcedureText</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.MedicalProcedureText}}</td>


                        </tr>
                        <!-- <tr>
    
                            <td>DoYouWantCook</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails.CareTaker.DoYouWantCook}}</td>
    
    
                        </tr>
                        <tr>
    
                            <td>DoYouWantMaid</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails.CareTaker.DoYouWantMaid}}</td>
    
    
                        </tr> -->
                        <tr>

                            <td>DoYouWantNurseOnCall</td>
                            <td>:</td>
                            <td *ngIf="requestDetailsObj?.RequestDetails?.CareTaker?.DoYouWantNurseOnCall=='No'">
                                {{requestDetailsObj?.RequestDetails?.CareTaker?.DoYouWantNurseOnCall}}</td>
                            <td *ngIf="requestDetailsObj.RequestDetails.OnCallDetails?.DoYouWantNurseOnCall=='Yes'">
                                {{requestDetailsObj?.RequestDetails?.OnCallDetails?.DoYouWantNurseOnCall}}</td>


                        </tr>
                        <tr>

                            <td>OnCallServiceRequired</td>
                            <td>:</td>
                            <td
                                *ngIf="requestDetailsObj.RequestDetails&&requestDetailsObj.RequestDetails?.CareTaker?.DoYouWantNurseOnCall=='No'">
                                <div
                                    *ngFor="let oncallservicereq of requestDetailsObj.RequestDetails?.OnCallDetails?.OnCallServiceRequired;let i = index">
                                    <span>{{i+1}}.{{oncallservicereq.key}}</span>
                                </div>
                            </td>
                            <td
                                *ngIf="requestDetailsObj.RequestDetails&&requestDetailsObj.RequestDetails.OnCallDetails?.DoYouWantNurseOnCall=='Yes'">
                                <div
                                    *ngFor="let oncallservicereq of requestDetailsObj.RequestDetails?.OnCallDetails?.OnCallServiceRequired;let i = index">
                                    <span>{{i+1}}.{{oncallservicereq.key}}</span>
                                </div>
                            </td>


                        </tr>
                        <tr>

                            <td>Note</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.CareTaker?.Note}}</td>


                        </tr>


                    </tbody>
                </table>


            </div>
            <div class="card-body" *ngIf="this.selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">


                <table>

                    <tbody>
                        <tr>

                            <td>Order Type</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.OrderType}}</td>

                        </tr>
                        <!-- <tr>
    
                            <td>Nurse Type</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails.OnCallDetails.NurseType}}</td>
    
                        </tr> -->
                        <tr>

                            <td>Mobile Number</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.MobileNumber}}</td>


                        </tr>
                        <tr>

                            <td>Alternate Mobile Number</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.AlternateMobileNumber}}</td>


                        </tr>
                        <tr>

                            <td>Area</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.Area}}</td>


                        </tr>
                        <tr>

                            <td>Age</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.Age}}</td>


                        </tr>
                        <tr>

                            <td>Mobility</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.Mobility}}</td>


                        </tr>
                        <tr>

                            <td>Service Type</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.ServiceType}}</td>


                        </tr>
                        <tr>

                            <td>Food In take</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.FoodIntake}}</td>


                        </tr>
                        <tr>

                            <td>Service Required</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.ServiceRequired}}</td>


                        </tr>
                        <tr>

                            <td>Price Informed</td>
                            <td>:</td>
                            <td>{{requestDetailsObj?.RequestDetails?.OnCallDetails?.PriceInformed}}</td>


                        </tr>
                        <!-- <tr>
    
                            <td>Servicable Day</td>
                            <td>:</td>
                            <td>{{requestDetailsObj.RequestDetails.OnCallDetails.ServicableDay}}</td>
    
    
                        </tr> -->

                    </tbody>
                </table>


            </div>
        </div>
    </div>
    <div *ngFor="let task of requestDetailsObj.Request_Task;let i=index">
        <div *ngIf="tasksTab">



            <div *ngIf="task=='Backdated_Requests'">
                <div class="text-center">
                    <h3><u>Approve Backdated Request </u></h3>
                </div>
                <div class="row">
                    <table class="table text-center">
                        <thead>
                            <tr>
                                <th>Request ID</th>
                                <th>Sponsor Name</th>
                                <th>Service</th>
                                <th>City</th>
                                <th>RequestedDate</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{requestDetailsObj.RequestDetails.RequestID}}</td>
                                <td>{{requestDetailsObj.RequestDetails.Name |replaceUnderscore |
                                    titlecase}}</td>
                                <td>{{requestDetailsObj.RequestDetails.SubCategoryName |replaceUnderscore |
                                    titlecase}}</td>
                                <td>{{requestDetailsObj.RequestDetails.ServiceAreaName |replaceUnderscore |
                                    titlecase}}</td>
                                <td>{{requestDetailsObj.RequestDetails.RequestedDate*1000 | date:'dd-MM-YYYY'}}</td>
                                <td>
                                    <button id="approveCancelRequest" class="anvayaabutton-primary"
                                        (click)="onApproveBackDated(requestDetailsObj.RequestDetails.RequestID)">Approve</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <div *ngIf="task=='Approve_Bill_Date'">
                <div class="row">
                    <div class="col-md-4">
                    </div>
                    <div class="col-md-6">
                        <label class="rqDetails"><u>Approve Bill End Date</u></label>
                    </div>
                    <div class="col-md-2">
                    </div>
                </div>
                <div class="card" *ngIf="requestDetailsObj.RequestDetails.JobPaymentDetails">


                    <div class="card-body">

                        <div class="row">
                            <div class="col-md-6">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Payment ID</td>
                                            <td>:</td>
                                            <td>{{requestDetailsObj?.RequestDetails?.PaymentDetails?.Payment.AnvayaaPaymentID}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Requested Service</td>
                                            <td>:</td>
                                            <td>{{requestDetailsObj?.RequestDetails?.ServiceName}}</td>
                                        </tr>
                                        <tr>
                                            <td>Service Type</td>
                                            <td>:</td>
                                            <td>{{requestDetailsObj?.RequestDetails?.ServiceRequestType}}</td>
                                        </tr>
                                        <tr>
                                            <td>Service Area</td>
                                            <td>:</td>
                                            <td>{{requestDetailsObj?.RequestDetails?.PaymentDetails?.ServiceAreaName}}
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6">

                                <table>
                                    <tbody>

                                        <tr>
                                            <td>Payment Date</td>
                                            <td>:</td>
                                            <td>{{requestDetailsObj?.RequestDetails?.PaymentDetails?.Payment?.PaymentCreatedDate*1000
                                                |
                                                date:"dd-MM-yyyy"}}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Price </td>
                                            <td>:</td>
                                            <td>{{requestDetailsObj?.RequestDetails?.PaymentDetails?.Payment.Price+"
                                                "+requestDetailsObj?.RequestDetails?.PaymentDetails?.Payment?.CurrencyType}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Adjusted Amount</td>
                                            <td>:</td>
                                            <td>{{requestDetailsObj?.RequestDetails?.PaymentDetails?.PaymentForDetails?.HomeHelathServices?.AdjustmentAmount+"
                                                "+requestDetailsObj?.RequestDetails?.PaymentDetails?.Payment?.CurrencyType}}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>View Bill </td>
                                            <td>:</td>
                                            <td><a class="btn btn-link"
                                                    (click)="ViewBill(requestDetailsObj?.RequestDetails?.PaymentDetails)">View</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <button class="btn  anvayaaButtonYellow"
                                                    (click)="BillUpdatebyfinance('Approve',requestDetailsObj.RequestDetails.PaymentDetails)">Approve</button>
                                            </td>
                                            <td><button class="btn  anvayaaButton"
                                                    (click)="BillUpdatebyfinance('Reject',requestDetailsObj.RequestDetails.PaymentDetails)">Reject</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div *ngIf="task=='Complete_Requests_Details'">

                <div class="row">
                    <div class="col-md-2">

                    </div>
                    <div class=" reqDetails col-md-6">
                        <label class="DetailsTitle"><u>Complete Request Details</u></label>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <div class="row">
                    <div class="col-md-3">

                        <label class="servicesDropdown">Request Type:</label>
                        <div class="margin10 servicesDivCss">
                            <label for="Status" class="field select">
                                <select class="servicesDivCss" formControlName="ServiceRequestType" required>
                                    <option selected value="">Select Request Type</option>
                                    <option value="InHouseRequest">InHouseRequest</option>
                                    <option value="ThirdPartyRequest">ThirdPartyRequest</option>
                                </select>

                            </label>
                            <!-- <label class="ComleteRequestDetails" for=""
                    *ngIf="this.updateRequestDetails.controls['ServiceRequestType'].status=='INVALID' && UpdateRequestBtn==true">*ServiceRequestType
                    Required</label> -->

                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="servicesDropdown">Service Type:</label>
                        <div class="servicesDivCss">

                            <label for="Status" class="field select ">
                                <select class="servicesDivCss" formControlName="ServiceType" required>
                                    <option selected value="">Select Service Type</option>
                                    <option value="Onetime">Onetime</option>
                                    <option value="Ongoing/ Daily">Daily/ongoing</option>
                                    <option value="Recurring">Recurring</option>
                                </select>

                            </label>

                        </div>
                        <div class="row">
                            <!-- <label class="ComleteRequestDetails" for=""
                *ngIf="this.updateRequestDetails.controls['ServiceType'].status=='INVALID' && UpdateRequestBtn==true">*Service
                type Required</label> -->
                        </div>

                    </div>
                    <div class="col-md-3">
                        <div class="serviceType" [hidden]="requestDetailsObj.RequestDetails.IsBeneficiary=='No'">
                            <span class="servicesDropdown" for=""> Select Beneficiary:</span>
                            <div class="margin20 servicesDivCss" *ngIf="requestDetailsObj.CustomerDetails">
                                <label for="Status" class="field select">
                                    <select class="servicesDivCssBene " (change)="selectBeneficiaryID($event)"
                                        formControlName="CustID">
                                        <option selected value="">Select Beneficiary</option>
                                        <option *ngFor="let data of requestDetailsObj.CustomerDetails.Beneficiaries"
                                            value="{{data.CustID}}">
                                            {{data.Name}}</option>


                                    </select>

                                </label>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">

                        <label class="servicesDropdown">Sub Sub Category:</label>
                        <div>
                            <select (change)="SelectSubSubCategory($event)" class="form-select"
                                formControlName="SubSubCategoryID" aria-label="Default select example">
                                <option selected value="" disabled>Select </option>
                                <option *ngFor="let SubSubCategory of SubSubCategoryData"
                                    [value]="SubSubCategory.CategoryID">
                                    {{SubSubCategory.AliasName}}
                                </option>
                            </select>


                        </div>
                    </div>

                    <!-- fields starts here -->

                    <div class="col-md-3">
                        <label class="servicesDropdown">Service Start Date:</label>
                        <input type="date" formControlName="RequestedDate" class="startDate form-control">


                    </div>
                    <div class="col-md-3" [hidden]="this.updateRequestDetails.value.ServiceType=='Onetime'">
                        <label class="servicesDropdown">Service End Date:</label><input type="date"
                            formControlName="RequestEndDate" class="startDate form-control">

                    </div>

                    <div class="col-md-3">
                        <span class="servicesDropdown">Language</span>
                        <div id='Status'>
                            <ng-multiselect-dropdown value="dropdownList" formControlName="Languages"
                                class="formDetails" [placeholder]="'select Languages'" [settings]="dropdownSettings"
                                [data]="dropdownList" [(ngModel)]="selectedItems">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-md-3 ">
                        <div>
                            <span class="servicesDropdown">Select Religion</span>
                            <!-- <span class=" formDetails" [hidden]="selectBeneficiary!='Yes'" for=""> Select Gender:</span> -->
                            <!-- <div id='Status' (change)="selectBeneficiaryID($event)" [hidden]="selectBeneficiary!='Yes'"> -->
                            <select class="form-select" formControlName="Religion" placeholder="Select Religion">
                                <option selected value="" disabled>Select Religion</option>
                                <option value="Hindu">
                                    <li><span class="dropdown-item-text">Hindu</span></li>
                                </option>
                                <option value="Christianity">
                                    <li><span class="dropdown-item-text">Christianity</span></li>
                                </option>
                                <option value="Islam">
                                    <li><span class="dropdown-item-text">Islam</span></li>
                                </option>
                                <option value="Judaism">
                                    <li><span class="dropdown-item-text">Judaism</span></li>
                                </option>
                                <option value="Buddhism">
                                    <li><span class="dropdown-item-text">Buddhism</span></li>
                                </option>
                                <option value="Jainism">
                                    <li><span class="dropdown-item-text">Jainism</span></li>
                                </option>
                                <option value="Sikhism">
                                    <li><span class="dropdown-item-text">Sikhism</span></li>
                                </option>
                                <option value="Zoroastrianism_Q">
                                    <li><span class="dropdown-item-text">Zoroastrianism.Q</span></li>
                                </option>

                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <span class="servicesDropdown">Select Gender</span>
                        <!-- <span class=" formDetails" [hidden]="selectBeneficiary!='Yes'" for=""> Select Gender:</span> -->
                        <!-- <div id='Status' (change)="selectBeneficiaryID($event)" [hidden]="selectBeneficiary!='Yes'"> -->
                        <select class="form-select" formControlName="Gender" placeholder="Select Gender">
                            <option selected value="" disabled>Select Gender</option>
                            <option value="Male">
                                <li><span class="dropdown-item-text">Male</span></li>
                            </option>
                            <option value="Female">
                                <li><span class="dropdown-item-text">Female</span></li>
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <span class="servicesDropdown">Select Placement Time:</span>
                        <div class=" input-group date">
                            <input formControlName="PlacementTime" type='datetime-local' class="startdate form-control"
                                min="{{mindate | date:'yyyy-MM-dd'}}" placeholder="Select  Placement Time">
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="reqType">
                            <div class="serviceType">
                                <label class="servicesDropdown">Comment:</label>
                                <div class="margin20">
                                    <textarea formControlName="Note" type='text' required style="width: 225px;"
                                        placeholder="Comment"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="reqType">
                            <div class='selectDays '>
                                <span class="formDetails">Budget</span>
                                <div id='Remark'>
                                    <textarea type='number' formControlName="Budget" class="form-control"
                                        style="width: 225px;" placeholder="Budget">
                </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="payment row ">
                            <label class="servicesDropdown">Payment:</label>
                            <div class="col-md-4">
                                <input value="Anvayaa" class="checkboxstyle form-check-input" type="radio"
                                    formControlName="PaymentTo" id="flexRadioDefault2"><span class="DaysSelect"> To
                                    Anvayaa</span>
                                &nbsp;
                            </div>
                            <div class="col-md-6">
                                <input value="Vendor" class="checkboxstyle form-check-input" type="radio"
                                    formControlName="PaymentTo" id="flexRadioDefault2"><span class="DaysSelect"> To
                                    Vendor</span>
                                &nbsp;
                            </div>

                        </div>
                    </div>
                    <div class="col-md-7 paymentMode" [hidden]="requestDetailsObj.RequestDetails.Service=='Covered'">
                        <label class="servicesDropdown">Payment Mode:</label>
                        <div class="col-md-10">
                            <input value="Cash" class="checkboxstyle form-check-input" type="radio"
                                formControlName="PaymentMode" id="flexRadioDefault2"> &nbsp; <span
                                class="DaysSelect">Cash</span> &nbsp;
                            <input value="Cheque" class="checkboxstyle form-check-input" type="radio"
                                formControlName="PaymentMode" id="flexRadioDefault2">&nbsp; <span class="DaysSelect">
                                Cheque</span>&nbsp;
                            <input value="Wiretransfer" class="checkboxstyle form-check-input" type="radio"
                                formControlName="PaymentMode" id="flexRadioDefault2"> &nbsp; <span
                                class="DaysSelect">Wire Transfer</span>&nbsp;
                            <input value="PMS" class="checkboxstyle form-check-input" type="radio"
                                formControlName="PaymentMode" id="flexRadioDefault2" required> &nbsp; <label for=""
                                class="DaysSelect">Pms</label>
                        </div>

                    </div>
                    <div class="col-md-4" style="align-items: center ;">
                        <!-- <div *ngIf="TotalPrice"> -->
                        <!-- Button trigger modal -->
                        <div class="text-center">
                            <button type="button" class="anvayaabutton-primary" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">
                                Fill Few More Requirements
                            </button>
                        </div>

                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog modal-fullscreen-xxl-down">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel"><strong>Fill Few More
                                                Requirements
                                                Here! ({{selectedCategoryName|replaceUnderscore}})
                                            </strong></h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row" formGroupName="CareTaker"
                                            *ngIf="this.selectedCategoryName == '24X7 CARE GIVER ASSISTANCE'||this.selectedCategoryName == 'HOME_STAY_NURSE / HOME_STAY_HELP'">
                                            <div class="col-md-2">
                                                <span class="formdetails">Select Order Type Ct</span>
                                                <select class="form-select" formControlName="OrderType"
                                                    placeholder="Select Order Type">
                                                    <option selected value="" disabled>Select Order Type</option>
                                                    <option value="Placement">
                                                        <li><span class="dropdown-item-text">Placement</span></li>
                                                    </option>
                                                    <option value="Replacement">
                                                        <li><span class="dropdown-item-text">Replacement</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName != 'HOME_STAY_NURSE / HOME_STAY_HELP'">
                                                <span class="formdetails">Select Gender</span>
                                                <select class="form-select" formControlName="CareTakerType"
                                                    placeholder="Select Gender">
                                                    <option selected value="" disabled>Select Gender</option>
                                                    <option value="Male">
                                                        <li><span class="dropdown-item-text">Male</span></li>
                                                    </option>
                                                    <option value="Female">
                                                        <li><span class="dropdown-item-text">Female</span></li>
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="col-md-2">
                                                <span class="formdetails">City</span>
                                                <!-- <Label>City</Label> -->
                                                <input type="text" class="form-control" formControlName="City"
                                                    placeholder="City Name">
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Mobile Number</span>
                                                <input type="tel" class="form-control" placeholder="Enter Number"
                                                    formControlName="MobileNumber" minlength="10" maxlength="10"
                                                    pattern="^[6-9]\d{9}$">
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Alternate M.No</span>
                                                <input type="tel" class="form-control" placeholder="Enter Number"
                                                    formControlName="AlternateMobileNumber" minlength="10"
                                                    maxlength="10" pattern="^[6-9]\d{9}$">
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Area</span>
                                                <textarea type='text' formControlName="Area" class="form-control"
                                                    style="width: 200px;" placeholder=" Enter Area"></textarea>
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Age</span>
                                                <input type="number" class="form-control" placeholder="Enter Age"
                                                    formControlName="Age" minlength="1" maxlength="3" min="1" max="120">
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Select Mobility</span>
                                                <select class="form-select" formControlName="Mobility"
                                                    placeholder="Select Mobility">
                                                    <option selected value="" disabled>Select Mobility</option>
                                                    <option value="Walkable">
                                                        <li><span class="dropdown-item-text">Walkable</span></li>
                                                    </option>
                                                    <option value="Walking_With_Support">
                                                        <li><span class="dropdown-item-text">Walking with support</span>
                                                        </li>
                                                    </option>
                                                    <option value="Bedridden">
                                                        <li><span class="dropdown-item-text">Bedridden</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Weight Of The Patient</span>
                                                <input type="number" class="form-control" placeholder="Enter Weight"
                                                    formControlName="WeightOfThePatient" minlength="1" maxlength="3"
                                                    min="1" max="120">
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Select Washroom Usage</span>
                                                <select class="form-select" formControlName="WashroomUsage"
                                                    placeholder="Select Washroom Usage">
                                                    <option selected value="" disabled>Select Washroom Usage</option>
                                                    <option value="Going_To_Washroom">
                                                        <li><span class="dropdown-item-text">Going to Washroom</span>
                                                        </li>
                                                    </option>
                                                    <option value="Going_to_Washroom_With_Support">
                                                        <li><span class="dropdown-item-text">Going to Washroom with
                                                                support</span></li>
                                                    </option>
                                                    <option value="Daiper">
                                                        <li><span class="dropdown-item-text">Daiper</span></li>
                                                    </option>
                                                    <option value="Cozy_Sheets">
                                                        <li><span class="dropdown-item-text">Cozy Sheets</span></li>
                                                    </option>
                                                    <option value="Bed_Pan">
                                                        <li><span class="dropdown-item-text">Bed Pan</span></li>
                                                    </option>
                                                    <option value="Using_Toilet_Seat/Commode_Wheel_Chair">
                                                        <li><span class="dropdown-item-text">Using Toilet Seat/Commode
                                                                Wheel Chair</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName != 'HOME_STAY_NURSE / HOME_STAY_HELP'">
                                                <span class="formdetails">Food In Take</span>
                                                <select class="form-select" formControlName="FoodIntake"
                                                    placeholder="Select Food In Take">
                                                    <option selected value="" disabled>Select Food In Takee</option>
                                                    <option value="Self">
                                                        <li><span class="dropdown-item-text">Self</span></li>
                                                    </option>
                                                    <option value="Need_Assistance">
                                                        <li><span class="dropdown-item-text">Need Assistance</span></li>
                                                    </option>

                                                </select>
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Medication</span>
                                                <select class="form-select" formControlName="Medication"
                                                    placeholder="Select Medication">
                                                    <option selected value="" disabled>Select Medication</option>
                                                    <option value="Yes">
                                                        <li><span class="dropdown-item-text">Yes</span></li>
                                                    </option>
                                                    <option value="No">
                                                        <li><span class="dropdown-item-text">No</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="updateRequestDetails.get('CareTaker')?.get('Medication') && updateRequestDetails.get('CareTaker')?.get('Medication')?.value ==='Yes'">
                                                <span class="formdetails">What type of Medication</span>
                                                <input type="text" class="form-control"
                                                    placeholder="Enter Medication Type"
                                                    formControlName="MedicationText">
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Exercise Activity</span>
                                                <select class="form-select" formControlName="ExerciseActivity"
                                                    placeholder="Select Exercise Activity">
                                                    <option selected value="" disabled>Select Exercise Activity</option>
                                                    <option value="Yes">
                                                        <li><span class="dropdown-item-text">Yes</span></li>
                                                    </option>
                                                    <option value="No">
                                                        <li><span class="dropdown-item-text">No</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="updateRequestDetails.get('CareTaker')?.get('ExerciseActivity') && updateRequestDetails.get('CareTaker')?.get('ExerciseActivity')?.value ==='Yes'">
                                                <span class="formdetails">What type of Excercise</span>
                                                <input type="text" class="form-control"
                                                    placeholder="Enter Excercise Type" formControlName="ExerciseText">
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="selectedCategoryName == '24X7 CARE GIVER ASSISTANCE'">
                                                <span class="formdetails">Medical Equipment Assistance</span>
                                                <select class="form-select" formControlName="MedicalEquipmentAssistance"
                                                    placeholder="Select Medical Equipment Assistance">
                                                    <option selected value="" disabled>Select Medical Equipment
                                                        Assistance</option>
                                                    <option value="Yes">
                                                        <li><span class="dropdown-item-text">Yes</span></li>
                                                    </option>
                                                    <option value="No">
                                                        <li><span class="dropdown-item-text">No</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="updateRequestDetails.get('CareTaker')?.get('MedicalEquipmentAssistance') && updateRequestDetails.get('CareTaker')?.get('MedicalEquipmentAssistance')?.value ==='Yes'">
                                                <span class="formdetails">Type of Equipmenteeee</span>
                                                <input typ="text" class="form-control"
                                                    placeholder="Write your type of Equipment"
                                                    formControlName="EquipmentText">

                                            </div>
                                            <div class="col-md-4"
                                                *ngIf="updateRequestDetails.get('CareTaker')?.get('MedicalEquipmentAssistance') && updateRequestDetails.get('CareTaker')?.get('MedicalEquipmentAssistance')?.value ==='Yes'">
                                                <span class="formdetails">What types of Equipment Patient is
                                                    using</span>
                                                <ng-multiselect-dropdown value="equipmenttext"
                                                    formControlName="TypeOfEquipment" class="formDetails"
                                                    [placeholder]="'select Equipment type'" [settings]="equipemtsetting"
                                                    [data]="equipmenttext"></ng-multiselect-dropdown>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="selectedCategoryName == '24X7 CARE GIVER ASSISTANCE'">
                                                <span class="formdetails">Select Service Required</span>
                                                <select class="form-select" formControlName="ServiceRequired"
                                                    placeholder="Select Service Required">
                                                    <option selected value="" disabled>Select Service Required</option>
                                                    <option value="General_Care_Support_Only">
                                                        <li><span class="dropdown-item-text">General Care Support
                                                                Only</span></li>
                                                    </option>
                                                    <option value="General_Care_Support&Hygiene">
                                                        <li><span class="dropdown-item-text">General Care Support &
                                                                Hygiene</span></li>
                                                    </option>
                                                    <option value="Caretaker_Support&Vital_Monitoring">
                                                        <li><span class="dropdown-item-text">Caretaker Support & Vital
                                                                Monitoring</span></li>
                                                    </option>
                                                    <option value="Caretaker_Support_Hygiene&Vital_Monitoring">
                                                        <li><span class="dropdown-item-text">Caretaker Support, Hygiene
                                                                & Vital
                                                                Monitoring</span></li>
                                                    </option>
                                                    <option value="Post_Operative_Care">
                                                        <li><span class="dropdown-item-text">Post Operative Care</span>
                                                        </li>
                                                    </option>
                                                    <option value="Companionship">
                                                        <li><span class="dropdown-item-text">Companionship</span></li>
                                                    </option>
                                                    <option value="End_Of_Life_Care">
                                                        <li><span class="dropdown-item-text">End Of Life Care</span>
                                                        </li>
                                                    </option>
                                                    <option value="Dementia_Care">
                                                        <li><span class="dropdown-item-text">Dementia Care</span></li>
                                                    </option>
                                                    <option value="Parkinson">
                                                        <li><span class="dropdown-item-text">Parkinson</span></li>
                                                    </option>
                                                    <option value="BiPolar">
                                                        <li><span class="dropdown-item-text">BiPolar</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Price Quotation Type</span>
                                                <select class="form-select" formControlName="PricingQuoted"
                                                    placeholder="Select Pricing Quoted">
                                                    <option selected value="" disabled>Select Medical Equipment
                                                        Assistance</option>
                                                    <option value="Day">
                                                        <li><span class="dropdown-item-text">Day</span></li>
                                                    </option>
                                                    <option value="Month">
                                                        <li><span class="dropdown-item-text">Month</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Price Informed</span>
                                                <input type="number" class="form-control"
                                                    placeholder="Enter Price Informed" formControlName="PriceInformed">
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName != 'HOME_STAY_NURSE / HOME_STAY_HELP'">
                                                <span class="formdetails">Duty hours</span>
                                                <select class="form-select" formControlName="DutyHours"
                                                    placeholder="Select Duty Hours">
                                                    <option selected value="" disabled>Select Duty Hours</option>
                                                    <option value="24_Hours">
                                                        <li><span class="dropdown-item-text">24 Hours</span></li>
                                                    </option>
                                                    <option value="12_Hrs_Day">
                                                        <li><span class="dropdown-item-text">12-hrs Day</span></li>
                                                    </option>
                                                    <option value="12_Hrs_Night">
                                                        <li><span class="dropdown-item-text">12-hrs Night</span></li>
                                                    </option>
                                                    <option value="Single_Visit">
                                                        <li><span class="dropdown-item-text">Single Visit</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName != 'HOME_STAY_NURSE / HOME_STAY_HELP'">
                                                <span class="formdetails">Service Duration Requested</span>
                                                <input type="number" class="form-control"
                                                    placeholder="Enter Service Duration Requested"
                                                    formControlName="ServiceDurationRequested" min="1" max="31">
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Insulin</span>
                                                <select class="form-select" formControlName="Insulin"
                                                    placeholder="Insulin">
                                                    <option selected value="" disabled>Select Insulin</option>
                                                    <option value="Yes">
                                                        <li><span class="dropdown-item-text">Yes</span></li>
                                                    </option>
                                                    <option value="No">
                                                        <li><span class="dropdown-item-text">No</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="updateRequestDetails.get('CareTaker')?.get('Insulin') && updateRequestDetails.get('CareTaker')?.get('Insulin')?.value ==='Yes'">
                                                <!-- *ngIf="insulintype" -->
                                                <span class="formdetails">What Type of Insulin</span>
                                                <select class="form-select" formControlName="TypeOfInsulin"
                                                    placeholder="Insulin">
                                                    <option selected value="" disabled>Select Insulin Type</option>
                                                    <option value="Pen_Insulin">
                                                        <li><span class="dropdown-item-text">Pen Insulin</span></li>
                                                    </option>
                                                    <option value="Syringe">
                                                        <li><span class="dropdown-item-text">Syringe</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="updateRequestDetails.get('CareTaker')?.get('Insulin') && updateRequestDetails.get('CareTaker')?.get('Insulin')?.value ==='Yes'">
                                                <!-- *ngIf="insulintype" -->
                                                <span class="formdetails">Insulin Details</span>
                                                <input type="text" class="form-control"
                                                    placeholder="Enter Type of Insulin" formControlName="InsulinText">
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="selectedCategoryName == '24X7 CARE GIVER ASSISTANCE'">
                                                <span class="formdetails">Do you want Maid ?</span>
                                                <select class="form-select" formControlName="DoYouWantMaid"
                                                    placeholder="Do you need Maid?">
                                                    <option selected value="" disabled>Need Maid?</option>
                                                    <option value="Yes">
                                                        <li><span class="dropdown-item-text">Yes</span></li>
                                                    </option>
                                                    <option value="No">
                                                        <li><span class="dropdown-item-text">No</span></li>
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="col-md-2"
                                                *ngIf="updateRequestDetails.get('CareTaker')?.get('DoYouWantMaid') && updateRequestDetails.get('CareTaker')?.get('DoYouWantMaid')?.value ==='Yes'">
                                                <span class="formdetails">Residence Type</span>
                                                <select class="form-select" formControlName="ResidenceType"
                                                    placeholder="Insulin">
                                                    <option selected value="" disabled>Select Residence Type</option>
                                                    <option value="1 BHK Flat">
                                                        <li><span class="dropdown-item-text">1 BHK Flat</span></li>
                                                    </option>
                                                    <option value="2 BHK Flat">
                                                        <li><span class="dropdown-item-text">2 BHK Flat</span></li>
                                                    </option>
                                                    <option value="3 BHK Flat">
                                                        <li><span class="dropdown-item-text">3 BHK Flat</span></li>
                                                    </option>
                                                    <option value="4 BHK Flat">
                                                        <li><span class="dropdown-item-text">4 BHK Flat</span></li>
                                                    </option>
                                                    <option value="Villa">
                                                        <li><span class="dropdown-item-text">Villa</span></li>
                                                    </option>
                                                    <option value="Duplex">
                                                        <li><span class="dropdown-item-text">Duplex</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="updateRequestDetails.get('CareTaker')?.get('DoYouWantMaid') && updateRequestDetails.get('CareTaker')?.get('DoYouWantMaid')?.value ==='Yes'">
                                                <span class="formdetails">Specify Maid Work</span>
                                                <select class="form-select" formControlName="SpecifyMaidWork"
                                                    placeholder="Insulin">
                                                    <option selected value="" disabled>Select Residence Type</option>
                                                    <option value="Brooming">
                                                        <li><span class="dropdown-item-text">Brooming</span></li>
                                                    </option>
                                                    <option value="Moping">
                                                        <li><span class="dropdown-item-text">Moping</span></li>
                                                    </option>
                                                    <option value="Dusting">
                                                        <li><span class="dropdown-item-text">Dusting</span></li>
                                                    </option>
                                                    <option value="Washing Cloths With Hands">
                                                        <li><span class="dropdown-item-text">Washing Cloths With
                                                                Hands</span></li>
                                                    </option>
                                                    <option value="Washing Cloths With Machine">
                                                        <li><span class="dropdown-item-text">Washing Cloths With
                                                                Machine</span></li>
                                                    </option>
                                                    <option value="Washroom Cleaning">
                                                        <li><span class="dropdown-item-text">Washroom Cleaning</span>
                                                        </li>
                                                    </option>
                                                    <option value="Utensil Cleaning">
                                                        <li><span class="dropdown-item-text">Utensil Cleaning</span>
                                                        </li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="selectedCategoryName == '24X7 CARE GIVER ASSISTANCE'">
                                                <span class="formdetails">Do you want Cook ?</span>
                                                <select class="form-select" formControlName="DoYouWantCook"
                                                    placeholder="Do you need cook?">
                                                    <option selected value="" disabled>Need Cook?</option>
                                                    <option value="Yes">
                                                        <li><span class="dropdown-item-text">Yes</span></li>
                                                    </option>
                                                    <option value="No">
                                                        <li><span class="dropdown-item-text">No</span></li>
                                                    </option>

                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="updateRequestDetails.get('CareTaker')?.get('DoYouWantCook') && updateRequestDetails.get('CareTaker')?.get('DoYouWantCook')?.value ==='Yes'">
                                                <span class="formdetails">No Of People To Cook</span>
                                                <input type="number" class="form-control"
                                                    placeholder="Enter No.of people to cook"
                                                    formControlName="NoOfPeopleToCook" minlength="1" maxlength="2"
                                                    min="1" max="10">
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="updateRequestDetails.get('CareTaker')?.get('DoYouWantCook') && updateRequestDetails.get('CareTaker')?.get('DoYouWantCook')?.value ==='Yes'">
                                                <span class="formdetails">Cooking Type</span>
                                                <select class="form-select" formControlName="CookingType"
                                                    placeholder="Cooking Type">
                                                    <option selected value="" disabled>Select Cooking Type</option>
                                                    <option value="Vegetarian">
                                                        <li><span class="dropdown-item-text">Vegetarian</span></li>
                                                    </option>
                                                    <option value="Non-Vegetarain">
                                                        <li><span class="dropdown-item-text">Non-Vegetarain</span></li>
                                                    </option>

                                                </select>
                                            </div>

                                            <!-- nurse started-->
                                            <div class="row"
                                                *ngIf="this.selectedCategoryName == 'HOME_STAY_NURSE / HOME_STAY_HELP'">
                                                <div class="col-md-2">
                                                    <span class="formdetails">Select Nurse Type</span>
                                                    <select class="form-select" formControlName="NurseType"
                                                        placeholder="Select Gender">
                                                        <option selected value="" disabled>Select Nurse Gender</option>
                                                        <option value="Male_Nurse">
                                                            <li><span class="dropdown-item-text">Semi Male Nurse</span>
                                                            </li>
                                                        </option>
                                                        <option value="Semi_Female_Nurse">
                                                            <li><span class="dropdown-item-text">Semi Female
                                                                    Nurse</span></li>
                                                        </option>
                                                        <option value="GNM/BSC_Non_Critical_Male">
                                                            <li><span class="dropdown-item-text">GNM/BSC Non Critical -
                                                                    Male</span></li>
                                                        </option>
                                                        <option value="GNM/BSC_Non_Critical_Female">
                                                            <li><span class="dropdown-item-text">GNM/BSC Non Critical -
                                                                    Female</span></li>
                                                        </option>
                                                        <option value="GNM/BSC_Critical_Male">
                                                            <li><span class="dropdown-item-text">GNM/BSC Critical -
                                                                    Male</span></li>
                                                        </option>
                                                        <option value="Female_Nurse">
                                                            <li><span class="dropdown-item-text">Female Nurse</span>
                                                            </li>
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-2">
                                                    <span class="formdetails">Medical Equipment Assistance</span>
                                                    <select class="form-select"
                                                        formControlName="MedicalEquipmentAssistance"
                                                        placeholder="Select Medical Equipment Assistance">
                                                        <option selected value="" disabled>Select Medical Equipment
                                                            Assistance</option>
                                                        <option value="Yes">
                                                            <li><span class="dropdown-item-text">Yes</span></li>
                                                        </option>
                                                        <option value="No">
                                                            <li><span class="dropdown-item-text">No</span></li>
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-2"
                                                    *ngIf="updateRequestDetails.get('CareTaker')?.get('MedicalEquipmentAssistance') && updateRequestDetails.get('CareTaker')?.get('MedicalEquipmentAssistance')?.value ==='Yes'">
                                                    <span class="formdetails">Type of Equipment</span>
                                                    <input typ="text" class="form-control"
                                                        placeholder="Write your type of Equipment"
                                                        formControlName="EquipmentText">

                                                </div>
                                                <div class="col-md-4"
                                                    *ngIf="updateRequestDetails.get('CareTaker')?.get('MedicalEquipmentAssistance') && updateRequestDetails.get('CareTaker')?.get('MedicalEquipmentAssistance')?.value ==='Yes'">
                                                    <span class="formdetails">What types of Equipment Patient is
                                                        using</span>
                                                    <ng-multiselect-dropdown value="equipmenttext"
                                                        formControlName="TypeOfEquipment" class="formDetails"
                                                        [placeholder]="'select Equipment type'"
                                                        [settings]="equipemtsetting"
                                                        [data]="equipmenttext"></ng-multiselect-dropdown>
                                                </div>

                                                <div class="col-md-2">
                                                    <span class="formdetails">Any Medical Procedure</span>
                                                    <select class="form-select" formControlName="MedicalProcedure"
                                                        placeholder="Select Medical Procedure">
                                                        <option selected value="" disabled>Select Medical Procedure
                                                        </option>
                                                        <option value="Yes">
                                                            <li><span class="dropdown-item-text">Yes</span></li>
                                                        </option>
                                                        <option value="No">
                                                            <li><span class="dropdown-item-text">No</span></li>
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="col-md-2"
                                                    *ngIf="updateRequestDetails.get('CareTaker')?.get('MedicalProcedure') && updateRequestDetails.get('CareTaker')?.get('MedicalProcedure')?.value ==='Yes'">
                                                    <span class="formdetails">Type of Medical Procedure</span>
                                                    <input typ="text" class="form-control"
                                                        placeholder="Write your type of Medical Procedure"
                                                        formControlName="MedicalProceduretext">

                                                </div>
                                                <div class="col-md-4"
                                                    *ngIf="updateRequestDetails.get('CareTaker')?.get('MedicalProcedure') && updateRequestDetails.get('CareTaker')?.get('MedicalProcedure')?.value ==='Yes'">
                                                    <span class="formdetails">What types of Medical Procedure</span>
                                                    <ng-multiselect-dropdown value="medicaltext"
                                                        formControlName="TypeOfMedicalProcedure" class="formDetails"
                                                        [placeholder]="'select Medical Procedure Type'"
                                                        [settings]="medicalsetting"
                                                        [data]="medicaltext"></ng-multiselect-dropdown>
                                                </div>
                                                <div class="col-md-2"
                                                    *ngIf="this.selectedCategoryName == 'HOME_STAY_NURSE / HOME_STAY_HELP'">
                                                    <span class="formdetails">Select Service Required</span>
                                                    <select class="form-select" formControlName="ServiceRequired"
                                                        placeholder="Select Service Required">
                                                        <option selected value="" disabled>Select Service Required
                                                        </option>
                                                        <option value="Post_Operative_Care">
                                                            <li><span class="dropdown-item-text">Post Operative
                                                                    Care</span></li>
                                                        </option>
                                                        <option value="ICU_Care">
                                                            <li><span class="dropdown-item-text">ICU Care</span></li>
                                                        </option>
                                                        <option value="Critical_Care">
                                                            <li><span class="dropdown-item-text">Critical Care</span>
                                                            </li>
                                                        </option>
                                                        <option value="Non_Critical_Care">
                                                            <li><span class="dropdown-item-text">Non-Critical
                                                                    Care</span></li>
                                                        </option>
                                                        <option value="Rehablitation">
                                                            <li><span class="dropdown-item-text">Rehablitation</span>
                                                            </li>
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-2">
                                                    <span class="formdetails">Service Duration Requested</span>
                                                    <input type="number" class="form-control"
                                                        placeholder="Enter Service Duration Requested"
                                                        formControlName="ServiceDurationRequested" min="1" max="31">
                                                </div>
                                                <div class="col-md-2">
                                                    <span class="formdetails">Duty hours</span>
                                                    <select class="form-select" formControlName="DutyHours"
                                                        placeholder="Select Duty Hours">
                                                        <option selected value="" disabled>Select Duty Hours</option>
                                                        <option value="24_Hours">
                                                            <li><span class="dropdown-item-text">24 Hours</span></li>
                                                        </option>
                                                        <option value="Shift_Duty_Day">
                                                            <li><span class="dropdown-item-text">Shift Duty Day</span>
                                                            </li>
                                                        </option>
                                                        <option value="Shift_Duty_Night">
                                                            <li><span class="dropdown-item-text">Shift Duty Night</span>
                                                            </li>
                                                        </option>
                                                        <option value="Double_Shift_Duty">
                                                            <li><span class="dropdown-item-text">Double Shift
                                                                    Duty</span></li>
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-2">
                                                    <span class="formdetails">Food In Take</span>
                                                    <select class="form-select" formControlName="FoodIntake"
                                                        placeholder="Select Food In Take">
                                                        <option selected value="" disabled>Select Food In Takee</option>
                                                        <option value="Self">
                                                            <li><span class="dropdown-item-text">Self</span></li>
                                                        </option>
                                                        <option value="Need_Assistance">
                                                            <li><span class="dropdown-item-text">Need Assistance</span>
                                                            </li>
                                                        </option>
                                                        <option value="Ryls_Tube">
                                                            <li><span class="dropdown-item-text">Ryls Tube</span></li>
                                                        </option>
                                                        <option value="Peg_Feeding">
                                                            <li><span class="dropdown-item-text">Peg Feeding</span></li>
                                                        </option>

                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <span class="formdetails">Any Special Instruction</span>
                                                    <textarea type='text' formControlName="Note" class="form-control"
                                                        placeholder=" Enter Special Instruction"></textarea>
                                                </div>
                                            </div>
                                            <!-- nurse ended-->
                                            <div class="col-md-3"
                                                *ngIf="this.selectedCategoryName != 'HOME_STAY_NURSE / HOME_STAY_HELP'">
                                                <span class="formdetails">Any Special Instruction</span>
                                                <textarea type='text' formControlName="Note" class="form-control"
                                                    placeholder=" Enter Special Instruction"></textarea>
                                            </div>


                                        </div>
                                        <div class="row" formGroupName="OnCallDetails">
                                            <!-- ||this.selectedCategoryName == 'Home Stay Nurse' -->
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">Select Order Type</span>
                                                <select class="form-select" formControlName="OrderType"
                                                    placeholder="Select Order Type">
                                                    <option selected value="" disabled>Select Order Type</option>
                                                    <option value="Placement">
                                                        <li><span class="dropdown-item-text">Placement</span></li>
                                                    </option>
                                                    <option value="Replacement">
                                                        <li><span class="dropdown-item-text">Replacement</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName == 'HOME_VISITS_BY_PHYSIOTHERAPIST'">
                                                <span class="formdetails">Select Order Type</span>
                                                <select class="form-select" formControlName="OrderType"
                                                    placeholder="Select Order Type">
                                                    <option selected value="" disabled>Select Order Type</option>
                                                    <option value="Placement">
                                                        <li><span class="dropdown-item-text">Placement</span></li>
                                                    </option>
                                                    <option value="Replacement">
                                                        <li><span class="dropdown-item-text">Replacement</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName =='HOME_VISITS_BY_PHYSIOTHERAPIST'">
                                                <span class="formdetails">Select Nurse Type</span>
                                                <select class="form-select" formControlName="NurseType"
                                                    placeholder="Select Gender">
                                                    <option selected value="" disabled>Select Nurse Gender</option>
                                                    <option value="Male Nurse">
                                                        <li><span class="dropdown-item-text">Male Physio</span></li>
                                                    </option>
                                                    <option value="Female Nurse">
                                                        <li><span class="dropdown-item-text">Female Physio</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">Select Gender</span>
                                                <select class="form-select" formControlName="CareTakerType"
                                                    placeholder="Select Gender">
                                                    <option selected value="" disabled>Select Gender</option>
                                                    <option value="Male">
                                                        <li><span class="dropdown-item-text">Male</span></li>
                                                    </option>
                                                    <option value="Female">
                                                        <li><span class="dropdown-item-text">Female</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName == 'HOME_VISITS_BY_PHYSIOTHERAPIST'||this.selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">City</span>
                                                <!-- <Label>City</Label> -->
                                                <input type="text" class="form-control" formControlName="City"
                                                    placeholder="City Name">
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName == 'HOME_VISITS_BY_PHYSIOTHERAPIST'||this.selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">Mobile Number</span>
                                                <input type="tel" class="form-control" placeholder="Enter Number"
                                                    formControlName="MobileNumber" minlength="10" maxlength="10"
                                                    pattern="^[6-9]\d{9}$">
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName == 'HOME_VISITS_BY_PHYSIOTHERAPIST'||this.selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">Alternate M.No</span>
                                                <input type="tel" class="form-control" placeholder="Enter Number"
                                                    formControlName="AlternateMobileNumber" minlength="10"
                                                    maxlength="10" pattern="^[6-9]\d{9}$">
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName == 'HOME_VISITS_BY_PHYSIOTHERAPIST'||this.selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">Area</span>
                                                <textarea type='text' formControlName="Area" class="form-control"
                                                    style="width: 200px;" placeholder=" Enter Area"></textarea>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName == 'HOME_VISITS_BY_PHYSIOTHERAPIST'||this.selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">Age</span>
                                                <input type="number" class="form-control" placeholder="Enter Age"
                                                    formControlName="Age" minlength="1" maxlength="3" min="1" max="120">
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName == 'HOME_VISITS_BY_PHYSIOTHERAPIST'||this.selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">Select Mobility</span>
                                                <select class="form-select" formControlName="Mobility"
                                                    placeholder="Select Mobility">
                                                    <option selected value="" disabled>Select Mobility</option>
                                                    <option value="Walkable">
                                                        <li><span class="dropdown-item-text">Walkable</span></li>
                                                    </option>
                                                    <option value="Walking_With_Support">
                                                        <li><span class="dropdown-item-text">Walking with support</span>
                                                        </li>
                                                    </option>
                                                    <option value="Bedridden">
                                                        <li><span class="dropdown-item-text">Bedridden</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName == 'HOME_VISITS_BY_PHYSIOTHERAPIST'||this.selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">Food In Take</span>
                                                <select class="form-select" formControlName="FoodIntake"
                                                    placeholder="Select Food In Take">
                                                    <option selected value="" disabled>Select Food In Takee</option>
                                                    <option value="Self">
                                                        <li><span class="dropdown-item-text">Self</span></li>
                                                    </option>
                                                    <option value="Need_Assistance">
                                                        <li><span class="dropdown-item-text">Need Assistance</span></li>
                                                    </option>

                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName == 'HOME_VISITS_BY_PHYSIOTHERAPIST'||this.selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">Select Service Required</span>
                                                <select class="form-select" formControlName="ServiceRequired"
                                                    placeholder="Select Service Required">
                                                    <option selected value="" disabled>Select Service Required</option>
                                                    <option value="General_Care_Support_Only">
                                                        <li><span class="dropdown-item-text">General Care Support
                                                                Only</span></li>
                                                    </option>
                                                    <option value="General_Care_Support&Hygiene">
                                                        <li><span class="dropdown-item-text">General Care Support &
                                                                Hygiene</span></li>
                                                    </option>
                                                    <option value="Caretaker_Support&Vital_Monitoring">
                                                        <li><span class="dropdown-item-text">Caretaker Support & Vital
                                                                Monitoring</span></li>
                                                    </option>
                                                    <option value="Caretaker_Support_Hygiene&Vital_Monitoring">
                                                        <li><span class="dropdown-item-text">Caretaker Support, Hygiene
                                                                & Vital
                                                                Monitoring</span></li>
                                                    </option>
                                                    <option value="Post_Operative_Care">
                                                        <li><span class="dropdown-item-text">Post Operative Care</span>
                                                        </li>
                                                    </option>
                                                    <option value="Companionship">
                                                        <li><span class="dropdown-item-text">Companionship</span></li>
                                                    </option>
                                                    <option value="End_Of_Life_Care">
                                                        <li><span class="dropdown-item-text">End Of Life Care</span>
                                                        </li>
                                                    </option>
                                                    <option value="Dementia_Care">
                                                        <li><span class="dropdown-item-text">Dementia Care</span></li>
                                                    </option>
                                                    <option value="Parkinson">
                                                        <li><span class="dropdown-item-text">Parkinson</span></li>
                                                    </option>
                                                    <option value="BiPolar">
                                                        <li><span class="dropdown-item-text">BiPolar</span></li>
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName == 'HOME_VISITS_BY_PHYSIOTHERAPIST'||this.selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">Price Informed</span>
                                                <input type="number" class="form-control"
                                                    placeholder="Enter Price Informed" formControlName="PriceInformed">
                                            </div>

                                            <!-- <div class="col-md-2">
                                                <span class="formdetails">Select Order Type</span>
                                                <select class="form-select" formControlName="OrderType"
                                                    placeholder="Select Order Type">
                                                    <option selected value="" disabled>Select Order Type</option>
                                                    <option value="Placement">
                                                        <li><span class="dropdown-item-text">Placement</span></li>
                                                    </option>
                                                    <option value="Replacement">
                                                        <li><span class="dropdown-item-text">Replacement</span></li>
                                                    </option>
                                                </select>
                                            </div> -->

                                            <div class="col-md-2"
                                                *ngIf="selectedCategoryName == 'HOME_STAY_NURSE / HOME_STAY_HELP'">
                                                <span class="formdetails">Do you want Nurse On Call ?</span>
                                                <select class="form-select" formControlName="DoYouWantNurseOnCall"
                                                    placeholder="Do you want Nurse On Call ?">
                                                    <option selected value="" disabled>Need Nurse on Call?</option>
                                                    <option value="Yes">
                                                        <li><span class="dropdown-item-text">Yes</span></li>
                                                    </option>
                                                    <option value="No">
                                                        <li><span class="dropdown-item-text">No</span></li>
                                                    </option>

                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="updateRequestDetails.get('OnCallDetails')?.get('DoYouWantNurseOnCall') && updateRequestDetails.get('OnCallDetails')?.get('DoYouWantNurseOnCall')?.value ==='Yes'">
                                                <span class="formdetails">Select Nurse On Call Type</span>
                                                <select class="form-select" formControlName="NurseType"
                                                    placeholder="Select Gender">
                                                    <option selected value="" disabled>Select Nurse Gender</option>
                                                    <option value="Male Nurse">
                                                        <li><span class="dropdown-item-text">Male Nurse</span></li>
                                                    </option>
                                                    <option value="Female Nurse">
                                                        <li><span class="dropdown-item-text">Female Nurse</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <!-- <div class="col-md-2" *ngIf="selectedCategoryName == 'HOME_STAY_NURSE / HOME_STAY_HELP'">
                                                <span class="formdetails">Select Service Required</span>
                                                <select class="form-select" formControlName="ServiceRequired"
                                                  placeholder="Select Nurse Service required">
                                                  <option selected value="" disabled>Select Nurse Service Required</option>
                                                  <option value="IM Injection Service">
                                                    <li><span class="dropdown-item-text">IM Injection Service</span></li>
                                                  </option>
                                                  <option value="IV Injection Service">
                                                    <li><span class="dropdown-item-text">IV Injection Service</span></li>
                                                  </option>
                                                  <option value="Wound Management">
                                                    <li><span class="dropdown-item-text">Wound Management</span></li>
                                                  </option>
                                                  <option value="IV Saline">
                                                    <li><span class="dropdown-item-text">IV Saline</span></li>
                                                  </option>
                                                  <option value="IV NS">
                                                    <li><span class="dropdown-item-text">IV NS</span></li>
                                                  </option>
                                                  <option value="Catheterisation">
                                                    <li><span class="dropdown-item-text">Catheterisation</span></li>
                                                  </option>
                                                  <option value="Catheter Removal">
                                                    <li><span class="dropdown-item-text">Catheter Removal</span></li>
                                                  </option>
                                                  <option value="Suctioning">
                                                    <li><span class="dropdown-item-text">Suctioning</span></li>
                                                  </option>
                                                  <option value="Tracheotomy Care">
                                                    <li><span class="dropdown-item-text">Tracheotomy Care</span></li>
                                                  </option>
                                                  <option value="Colectomy Care">
                                                    <li><span class="dropdown-item-text">Colectomy Care</span></li>
                                                  </option>
                                                </select>
                                              </div> -->
                                            <div class="col-md-4"
                                                *ngIf="updateRequestDetails.get('OnCallDetails')?.get('DoYouWantNurseOnCall') && updateRequestDetails.get('OnCallDetails')?.get('DoYouWantNurseOnCall')?.value ==='Yes'">
                                                <span class="formdetails">Select Service Required(NOC)</span>
                                                <ng-multiselect-dropdown value="nurseoncalltext"
                                                    formControlName="OnCallServiceRequired" class="formDetails"
                                                    [placeholder]="'select service required'"
                                                    [settings]="nurseoncallsetting"
                                                    [data]="nurseoncalltext"></ng-multiselect-dropdown>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="updateRequestDetails.get('OnCallDetails')?.get('DoYouWantNurseOnCall') && updateRequestDetails.get('OnCallDetails')?.get('DoYouWantNurseOnCall')?.value ==='Yes'">
                                                <span class="formdetails">Select Service Type(NOC)</span>
                                                <select class="form-select" formControlName="ServiceType"
                                                    placeholder="Select Nurse Service Type">
                                                    <option selected value="" disabled>Select Nurse Service Type
                                                    </option>
                                                    <option value="One Visit In A Day">
                                                        <li><span class="dropdown-item-text">One Visit In A Day</span>
                                                        </li>
                                                    </option>
                                                    <option value="Two Visits In A Day">
                                                        <li><span class="dropdown-item-text">Two Visits In A Day</span>
                                                        </li>
                                                    </option>
                                                    <option value="Three Visits In A Day">
                                                        <li><span class="dropdown-item-text">Three Visits In A
                                                                Day</span></li>
                                                    </option>
                                                    <option value="Full Day Visit">
                                                        <li><span class="dropdown-item-text">Full Day Visit</span></li>
                                                    </option>
                                                    <option value="Entire Night Visit">
                                                        <li><span class="dropdown-item-text">Entire Night Visit</span>
                                                        </li>
                                                    </option>
                                                    <option value="24 Hours Visit">
                                                        <li><span class="dropdown-item-text">24 Hours Visit</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <!-- Nurse on call fields Ended -->
                                            <!-- physio on call started -->
                                            <!--  -->
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName=='HOME_VISITS_BY_PHYSIOTHERAPIST'">
                                                <span class="formdetails">Select Service Required </span>
                                                <select class="form-select" formControlName="ServiceRequired"
                                                    placeholder="Select Physio Service required">
                                                    <option selected value="" disabled>Select Physio Service Required
                                                    </option>
                                                    <option value="Physiotherapy_For_Muscle_Movement">
                                                        <li><span class="dropdown-item-text">Physiotherapy For Muscle
                                                                Movement</span></li>
                                                    </option>
                                                    <option value="Physiotherapy_For_Pain_Management">
                                                        <li><span class="dropdown-item-text">Physiotherapy For Pain
                                                                Management</span></li>
                                                    </option>
                                                    <option value="Physiotherapy_For_Joint_Locks">
                                                        <li><span class="dropdown-item-text">Physiotherapy For Joint
                                                                Locks</span></li>
                                                    </option>
                                                    <option value="Physiotherapy_For_Neurological_Disorder">
                                                        <li><span class="dropdown-item-text">Physiotherapy For
                                                                Neurological Disorder</span></li>
                                                    </option>
                                                    <option value="Physiothery_For_Post_Operative_Care">
                                                        <li><span class="dropdown-item-text">Physiothery For Post
                                                                Operative Care</span></li>
                                                    </option>
                                                    <option value="Rehablitation">
                                                        <li><span class="dropdown-item-text">Rehablitation</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName=='HOME_VISITS_BY_PHYSIOTHERAPIST'">
                                                <span class="formdetails">Select Service Type</span>
                                                <select class="form-select" formControlName="ServiceType"
                                                    placeholder="Select Physio Service Type">
                                                    <option selected value="" disabled>Select Physio Service Type
                                                    </option>
                                                    <option value="One_Visit_In_A_Day">
                                                        <li><span class="dropdown-item-text">One Visit In A Day</span>
                                                        </li>
                                                    </option>
                                                    <option value="Two_Visits_In_A_Day">
                                                        <li><span class="dropdown-item-text">Two Visits In A Day</span>
                                                        </li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="this.selectedCategoryName=='HOME_VISITS_BY_PHYSIOTHERAPIST'">
                                                <span class="formdetails" for="vote">Physio Servicable Day</span>
                                                <input type="date" id="vote" name="vote" (keydown)="disableDate()"
                                                    formControlName="ServicableDay">
                                            </div>
                                            <div class="col-md-3"
                                                *ngIf="selectedCategoryName == 'HOME_VISITS_BY_PHYSIOTHERAPIST'">
                                                <span class="formdetails">Any Special Instruction</span>
                                                <textarea type="text" class="form-control" formControlName="Note"
                                                    placeholder="Enter Spl Instruction">
                  </textarea>
                                            </div>
                                            <!-- physio on call Ended-->
                                            <!-- dr on call started-->
                                            <div class="col-md-2"
                                                *ngIf="selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">Select Dr Order Type</span>
                                                <select class="form-select" formControlName="OrderType"
                                                    placeholder="Select Dr Order Type">
                                                    <option selected value="" disabled>Select Order Type</option>
                                                    <option value="Doctor_Home_Visit">
                                                        <li><span class="dropdown-item-text">Doctor Home Visit</span>
                                                        </li>
                                                    </option>
                                                    <option value="Doctor_Virtual_Consultation">
                                                        <li><span class="dropdown-item-text">Doctor Virtual
                                                                Consultation</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">Select Service Required</span>
                                                <select class="form-select" formControlName="ServiceRequired"
                                                    placeholder="Select Dr Service required">
                                                    <option selected value="" disabled>Select Dr Service Required
                                                    </option>
                                                    <option value="GP_Consultation">
                                                        <li><span class="dropdown-item-text">GP Consultation</span></li>
                                                    </option>
                                                    <option value="Pain_Management">
                                                        <li><span class="dropdown-item-text">Pain Management</span></li>
                                                    </option>
                                                    <option value="Post_Operative_Checkup">
                                                        <li><span class="dropdown-item-text">Post Operative
                                                                Checkup</span></li>
                                                    </option>
                                                    <option value="Geratic_Care_Checkup">
                                                        <li><span class="dropdown-item-text">Geratic Care Checkup</span>
                                                        </li>
                                                    </option>
                                                    <option value="Pallativ_Care_Checkup">
                                                        <li><span class="dropdown-item-text">Pallative Care
                                                                Checkup</span></li>
                                                    </option>
                                                    <option value="Oncology_Check_up">
                                                        <li><span class="dropdown-item-text">Oncology Check up</span>
                                                        </li>
                                                    </option>
                                                    <option value="Cardiology_Checkup">
                                                        <li><span class="dropdown-item-text">Cardiology Checkup</span>
                                                        </li>
                                                    </option>
                                                    <option value="Orthopedic_Checkup">
                                                        <li><span class="dropdown-item-text">Orthopedic Checkup</span>
                                                        </li>
                                                    </option>
                                                    <option value="Death_validation">
                                                        <li><span class="dropdown-item-text">Death validation</span>
                                                        </li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="selectedCategoryName == 'DR_VIRTUAL_CONSULTATION'">
                                                <span class="formdetails">Select Service Type</span>
                                                <select class="form-select" formControlName="ServiceType"
                                                    placeholder="Select Dr Service Type">
                                                    <option selected value="" disabled>Select Dr Service Type</option>
                                                    <option value="One_Visit_In_A_Day">
                                                        <li><span class="dropdown-item-text">One Visit In A Day</span>
                                                        </li>
                                                    </option>
                                                    <option value="Two_Visits_In_A_Day">
                                                        <li><span class="dropdown-item-text">Two Visits In A Day</span>
                                                        </li>
                                                    </option>
                                                </select>
                                            </div>
                                            <!-- dr on call ended-->

                                            <!-- Diagno strated-->
                                            <!-- [hidden]="data.SubCategoryName != 'DIAGNOSTICS'" -->
                                            <!-- <div class="col-md-2" *ngIf="selectedCategoryName == 'Diagnostic Services'">
                                                <span class="formdetails">Select Order Type</span>
                                                <select class="form-select" formControlName="OrderType"
                                                    placeholder="Select Order Type">
                                                    <option selected value="" disabled>Select Order Type</option>
                                                    <option value="Pathology">
                                                        <li><span class="dropdown-item-text">Pathology</span></li>
                                                    </option>
                                                    <option value="Radiology">
                                                        <li><span class="dropdown-item-text">Radiology</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2" *ngIf="selectedCategoryName == 'Diagnostic Services'">
                                                <span class="formdetails">Type Of Pathology</span>
                                                <input type="text" class="form-control"
                                                    formControlName="TypeOfPathology"
                                                    placeholder="Enter Type Of Pathology">
                                            </div>
                                            <div class="col-md-2" *ngIf="selectedCategoryName == 'Diagnostic Services'">
                                                <span class="formdetails">Type Of Radiology</span>
                                                <input type="text" class="form-control"
                                                    formControlName="TypeOfRadiology"
                                                    placeholder="Enter Type Of Radiology">
                                            </div>
                                            <div class="col-md-3" *ngIf="selectedCategoryName == 'Diagnostic Services'">
                                                <span class="formdetails">Any Special Instruction</span>
                                                <textarea type="text" class="form-control" formControlName="Note"
                                                    placeholder="Enter Spl Instruction">
                  </textarea>
                                            </div> -->

                                            <!-- Diagno ended-->


                                        </div>
                                        <div class="row" formGroupName="DiagnosticService"
                                            *ngIf="this.selectedCategoryName=='Diagnostic Services'">
                                            <div class="col-md-2" *ngIf="selectedCategoryName == 'Diagnostic Services'">
                                                <span class="formdetails">Select Order Type</span>
                                                <select class="form-select" formControlName="OrderType"
                                                    placeholder="Select Order Type">
                                                    <option selected value="" disabled>Select Order Type</option>
                                                    <option value="Pathology">
                                                        <li><span class="dropdown-item-text">Pathology</span></li>
                                                    </option>
                                                    <option value="Radiology">
                                                        <li><span class="dropdown-item-text">Radiology</span></li>
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="col-md-2"
                                                *ngIf="updateRequestDetails.get('DiagnosticService')?.get('OrderType') && updateRequestDetails.get('DiagnosticService')?.get('OrderType')?.value ==='Pathology'">
                                                <span class="formdetails">Type Of test(Pathology)</span>
                                                <input type="text" class="form-control"
                                                    formControlName="TypeOfPathology"
                                                    placeholder="Enter Type Of Pathology">
                                            </div>
                                            <div class="col-md-2"
                                                *ngIf="updateRequestDetails.get('DiagnosticService')?.get('OrderType') && updateRequestDetails.get('DiagnosticService')?.get('OrderType')?.value === 'Radiology'">
                                                <span class="formdetails">Type Of test(Radiology)</span>
                                                <input type="text" class="form-control"
                                                    formControlName="TypeOfRadiology"
                                                    placeholder="Enter Type Of Radiology">
                                            </div>
                                            <!-- <div class="col-md-2">
                                                <span class="formdetails">Select Order Type</span>
                                                <select class="form-select" formControlName="OrderType"
                                                    placeholder="Select Order Type">
                                                    <option selected value="" disabled>Select Order Type</option>
                                                    <option value="Placement">
                                                        <li><span class="dropdown-item-text">Placement</span></li>
                                                    </option>
                                                    <option value="Replacement">
                                                        <li><span class="dropdown-item-text">Replacement</span></li>
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Select Gender</span>
                                                <select class="form-select" formControlName="CareTakerType"
                                                    placeholder="Select Gender">
                                                    <option selected value="" disabled>Select Gender</option>
                                                    <option value="Male">
                                                        <li><span class="dropdown-item-text">Male</span></li>
                                                    </option>
                                                    <option value="Female">
                                                        <li><span class="dropdown-item-text">Female</span></li>
                                                    </option>
                                                </select>
                                            </div> -->
                                            <div class="col-md-2">
                                                <span class="formdetails">City</span>
                                                <!-- <Label>City</Label> -->
                                                <input type="text" class="form-control" formControlName="City"
                                                    placeholder="City Name">
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Mobile Number</span>
                                                <input type="tel" class="form-control" placeholder="Enter Number"
                                                    formControlName="MobileNumber" minlength="10" maxlength="10"
                                                    pattern="^[6-9]\d{9}$">
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Alternate M.No</span>
                                                <input type="tel" class="form-control" placeholder="Enter Number"
                                                    formControlName="AlternateMobileNumber" minlength="10"
                                                    maxlength="10" pattern="^[6-9]\d{9}$">
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Age</span>
                                                <input type="number" class="form-control" placeholder="Enter Age"
                                                    formControlName="Age" minlength="1" maxlength="3" min="1" max="120">
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Price Informed</span>
                                                <input type="number" class="form-control"
                                                    placeholder="Enter Price Informed" formControlName="PriceInformed">
                                            </div>
                                            <div class="col-md-2">
                                                <span class="formdetails">Area</span>
                                                <textarea type='text' formControlName="Area" class="form-control"
                                                    style="width: 175px;" placeholder=" Enter Area"></textarea>
                                            </div>



                                            <!-- Diagno strated-->

                                            <div class="col-md-2" *ngIf="selectedCategoryName == 'Diagnostic Services'">
                                                <span class="formdetails">Any Special Instruction</span>
                                                <textarea type="text" class="form-control" formControlName="Note"
                                                    placeholder="Enter Spl Instruction"></textarea>
                                            </div>

                                            <!-- Diagno ended-->


                                        </div>
                                        <div class="modal-footer"><button class="btn  underline" type="button"
                                                (click)="updateRequestDetailsData()">Save Details</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </div> -->

                    </div>
                </div>



                <div class="row topmargin">
                    <!-- <div class="col-md-4">
                        <label class="servicesDropdown">Request Type:</label>
                        <div class="margin10 servicesDivCss">
                            <label for="Status" class="field select">
                                <select class="servicesDivCss" formControlName="ServiceRequestType" required>
                                    <option selected value="">Select Request Type</option>
                                    <option value="InHouseRequest">InHouseRequest</option>
                                    <option value="ThirdPartyRequest">ThirdPartyRequest</option>
                                </select>

                            </label>
                            <label class="ComleteRequestDetails" for=""
                                *ngIf="this.updateRequestDetails.controls['ServiceRequestType'].status=='INVALID' && UpdateRequestBtn==true">*ServiceRequestType
                                Required</label> 

                        </div>-->
                    <!-- <div class="col-md-4 serviceType">
                            <label class="servicesDropdown">Service Type:</label>
                            <div class="margin20 servicesDivCss">

                                <label for="Status" class="field select">
                                    <select class="servicesDivCss" formControlName="ServiceType" required>
                                        <option selected value="">Select Service Type</option>
                                        <option value="Onetime">Onetime</option>
                                        <option value="Ongoing/ Daily">Daily/ongoing</option>
                                        <option value="Recurring">Recurring</option>
                                    </select>

                                </label>

                            </div>

                        </div>
                        <div class="row">
                            <label class="ComleteRequestDetails" for=""
                                *ngIf="this.updateRequestDetails.controls['ServiceType'].status=='INVALID' && UpdateRequestBtn==true">*Service
                                type Required</label>
                        </div> -->
                    <!-- <div class="serviceType" [hidden]="requestDetailsObj.RequestDetails.IsBeneficiary=='No'">
                            <span class="servicesDropdown" for=""> Select Beneficiary:</span>
                            <div class="margin20 servicesDivCss" *ngIf="requestDetailsObj.CustomerDetails">
                                <label for="Status" class="field select">
                                    <select class="servicesDivCssBene " (change)="selectBeneficiaryID($event)"
                                        formControlName="CustID">
                                        <option selected value="">Select Beneficiary</option>
                                        <option *ngFor="let data of requestDetailsObj.CustomerDetails.Beneficiaries"
                                            value="{{data.CustID}}">
                                            {{data.Name}}</option>


                                    </select>

                                </label>

                            </div> 
                        </div>-->
                    <!-- 
                        <div class="serviceType">
                            <label class="servicesDropdown">Comment:</label>
                            <div class="margin20">
                                <textarea formControlName="Note" type='text' required style="width: 225px;"
                                    placeholder="Comment"></textarea>
                            </div>
                        </div> 

                    </div>-->
                    <div class="col-md-8">
                        <div [hidden]="this.updateRequestDetails.value.ServiceType!='Recurring'">
                            <label class="servicesdropdown">Select Days:</label>
                            <div class="row" (change)="DaysSelect($event)">
                                <div class="col-md-3" *ngFor="let day of Days;let i=index" formArrayName="Days">
                                    <input [formControlName]="i" value="{{day}}" class="checkboxstyle form-check-input"
                                        type="checkbox" name="flexRadioDefault" id="flexRadioDefault2"><span
                                        class="DaysSelect"> {{day}}</span> &nbsp;
                                </div>
                            </div>
                        </div>

                        <div class="row ServiceDates">
                            <!-- <div class="col-md-4"> <label class="servicesDropdown">Service Start Date:</label>
                                <input type="datetime-local" formControlName="RequestedDate"
                                    class="startDate form-control">
                            </div> -->
                            <!-- <span for="" class="alerts"
                                *ngIf='this.updateRequestDetails.controls["RequestedDate"].status=="INVALID" && this.UpdateRequestBtn==true'>*Comment
                                Mandatory</span> -->
                            <!-- <div [hidden]="this.updateRequestDetails.value.ServiceType=='Onetime'" class="col-md-4">
                                <label class="servicesDropdown">Service End Date:</label><input type="date"
                                    formControlName="RequestEndDate" class="startDate form-control">
                            </div> -->

                        </div>


                        <!-- <div class="row paymentMode" [hidden]="requestDetailsObj.RequestDetails.Service=='Covered'">
                            <label class="servicesDropdown">Payment Mode:</label>
                            <div class="col-md-10">
                                <input value="Cash" class="checkboxstyle form-check-input" type="radio"
                                    formControlName="PaymentMode" id="flexRadioDefault2"> &nbsp; <span
                                    class="DaysSelect">Cash</span> &nbsp;
                                <input value="Cheque" class="checkboxstyle form-check-input" type="radio"
                                    formControlName="PaymentMode" id="flexRadioDefault2">&nbsp; <span
                                    class="DaysSelect"> Cheque</span>&nbsp;
                                <input value="Wiretransfer" class="checkboxstyle form-check-input" type="radio"
                                    formControlName="PaymentMode" id="flexRadioDefault2"> &nbsp; <span
                                    class="DaysSelect">Wire Transfer</span>&nbsp;
                                <input value="PMS" class="checkboxstyle form-check-input" type="radio"
                                    formControlName="PaymentMode" id="flexRadioDefault2" required> &nbsp; <label for=""
                                    class="DaysSelect">Pms</label>
                            </div>

                        </div> -->
                        <div class="payment row ">
                            <!-- <label class="servicesDropdown">Payment:</label> -->
                            <!-- <div class="col-md-4">
                                <input value="Anvayaa" class="checkboxstyle form-check-input" type="radio"
                                    formControlName="PaymentTo" id="flexRadioDefault2"><span class="DaysSelect"> To
                                    Anvayaa</span>
                                &nbsp;
                            </div>
                            <div class="col-md-6">
                                <input value="Vendor" class="checkboxstyle form-check-input" type="radio"
                                    formControlName="PaymentTo" id="flexRadioDefault2"><span class="DaysSelect"> To
                                    Vendor</span>
                                &nbsp;
                            </div> -->

                        </div>
                        <div class="payment row ">
                            <!-- <label class="servicesDropdown">Sub Sub Category:</label>
                            <div class="col-md-4">
                                <select (change)="SelectSubSubCategory($event)" class="form-select"
                                    formControlName="SubSubCategoryID" aria-label="Default select example">
                                    <option selected value="" disabled>Select </option>
                                    <option *ngFor="let SubSubCategory of SubSubCategoryData"
                                        [value]="SubSubCategory.CategoryID">
                                        {{SubSubCategory.AliasName}}
                                    </option>
                                </select>
                            </div> -->

                        </div>
                        <!-- <div class="row">
                            <div class="col-md-9"></div>
                            <div class="col-md-2"><button class="btn  underline"
                                    (click)="updateRequestDetailsData()">Save Details</button></div>
                            <div class="col-md-1"></div>
                        </div> -->

                    </div>
                </div>
            </div>
            <div *ngIf="task=='Inform_Vendor_Of_New_Job'" [formGroup]="informVendorNewJob">

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-6">
                        <label class="rqDetails"><u>Inform Vendor of New Job</u></label>
                    </div>
                    <div class="col-md-2">

                    </div>
                </div>

                <div class="row serviceName">
                    <div class="col-md-6">
                        <div>
                            <span><label class="fontstyle">Job Start Date </label>:
                                <input formControlName="jobStartDate" type="datetime-local" readonly></span>
                        </div>

                        <div style="width: 70%;border: 1px solid black; margin-top: 50px;background-color:#ECECEC;">
                            <h6 class="supportexcutivetitle fontstyle">Support Executive Details :</h6>
                            <table>
                                <tr>
                                    <td class="fontstyle">Name</td>
                                    <td>:</td>
                                    <td *ngIf="requestDetailsObj.RequestDetails.SupportExecutiveDetails.length>0">
                                        {{requestDetailsObj.RequestDetails.SupportExecutiveDetails[0].FirstName}} </td>
                                </tr>
                                <tr>
                                    <td class="fontstyle">Mobile No</td>
                                    <td>:</td>
                                    <td *ngIf="requestDetailsObj.RequestDetails.SupportExecutiveDetails.length>0">
                                        {{requestDetailsObj.RequestDetails.SupportExecutiveDetails[0].ContactNumber}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div style="width: 70%;border: 1px solid black; margin-top: 50px;background-color:#ECECEC;">

                            <div class="row">
                                <div class="col-md-4">

                                </div>
                                <div class="col-md-4">

                                </div>
                                <div class="col-md-4">

                                </div>
                            </div>
                            <h6 class="supportexcutivetitle fontstyle">Vendor Contact Details :</h6>
                            <table>
                                <tr>
                                    <td class="fontstyle">Name</td>
                                    <td>:</td>
                                    <td *ngIf="ActiveVendorObj&&ActiveVendorObj.VendorName">
                                        {{ActiveVendorObj.VendorName}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fontstyle">Mobile No</td>
                                    <td>:</td>
                                    <td *ngIf="requestDetailsObj.RequestDetails.ActiveVendors.length>0">
                                        {{ActiveVendorObj.VendorsContactNumber}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div style="width: 70%;border: 1px solid black; margin-top: 38%;background-color:#ECECEC;">
                            <h6 class="supportexcutivetitle fontstyle">Profile Contact Details :</h6>
                            <table>
                                <tr>
                                    <td class="fontstyle">Name</td>
                                    <td>:</td>
                                    <td *ngIf="requestDetailsObj.RequestDetails.VendorProfile.length>0">
                                        {{profileView[0].Name}}</td>
                                </tr>
                                <tr>
                                    <td class="fontstyle">Mobile No</td>
                                    <td>:</td>
                                    <td *ngIf="requestDetailsObj.RequestDetails.VendorProfile.length>0">
                                        {{profileView[0].PrimaryMobileNo}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <button class="btn btn-info infovendorButn" (click)="InformVendorJob()">Informed Vendor</button>
            </div>
            <div *ngIf="task=='Confirm_If_Job_Started'" [formGroup]="informVendorNewJob">

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">

                        <label class="rqDetails"><u>Confirm if Job Started</u></label>
                    </div>
                    <div class="col-md-4">

                    </div>
                </div>

                <div class="row serviceName">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="fontstyle">Job Start Date </label>:
                                <input formControlName="jobStartDate" type="datetime-local" readonly>
                            </div>
                            <div class="col-md-6">
                                <label class="fontstyle">Job End Date </label>: <br>
                                <input type="date">
                            </div>



                        </div>

                        <div class="sponseDetailsbox">
                            <h6 class="supportexcutivetitle fontstyle">Sponsor Details :</h6>
                            <table>
                                <tr>
                                    <td class="fontstyle">Name</td>
                                    <td>:</td>
                                    <td *ngIf="requestDetailsObj.CustomerDetails.Name">
                                        {{requestDetailsObj.CustomerDetails.Name}}</td>
                                </tr>
                                <tr>
                                    <td class="fontstyle">Mobile No</td>
                                    <td>:</td>
                                    <td>{{requestDetailsObj.CustomerDetails.MobileNumber}}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="sponseDetailsbox">
                            <h6 class="supportexcutivetitle fontstyle">Vendor Contact Details :</h6>
                            <table>
                                <tr>
                                    <td class="fontstyle">Name</td>
                                    <td>:</td>
                                    <td>{{requestDetailsObj.RequestDetails.ActiveVendors[0].VendorName}}</td>
                                </tr>
                                <tr>
                                    <td class="fontstyle">Mobile No</td>
                                    <td>:</td>
                                    <td *ngIf="requestDetailsObj.RequestDetails.ActiveVendors.length>0">
                                        {{requestDetailsObj.RequestDetails.ActiveVendors[0].VendorsContactNumber}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="benficiaryDetailsbox">
                            <h6 class="supportexcutivetitle fontstyle">Beneficiary Details :</h6>
                            <table>
                                <tr>
                                    <td class="fontstyle">Name</td>
                                    <td>:</td>
                                    <td>{{BeneficiaryName}}</td>
                                </tr>
                                <tr>
                                    <td class="fontstyle">Mobile No</td>
                                    <td>:</td>
                                    <td>{{BeneficiaryMobileNumber}}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="sponseDetailsbox">
                            <h6 class="supportexcutivetitle fontstyle">Selected Profile Details :</h6>
                            <table>
                                <tr>
                                    <td class="fontstyle">Name</td>
                                    <td>:</td>
                                    <td *ngIf="requestDetailsObj.RequestDetails.ActiveVendors.length>0">
                                        {{requestDetailsObj.RequestDetails.ActiveVendors[0].Name}}</td>
                                </tr>
                                <tr>
                                    <td class="fontstyle">Mobile No</td>
                                    <td>:</td>
                                    <td *ngIf="requestDetailsObj.RequestDetails.ActiveVendors.length>0">
                                        {{requestDetailsObj.RequestDetails.ActiveVendors[0].PrimaryMobileNo}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 20px;"></div>


                <div class="row" [formGroup]="changeRequestDetails">
                    <!-- Button trigger modal -->
                    <!-- Modal -->
                    <div class="modal fade" id="ReshedulJob" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog  modal-xl">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Reshedul Job</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body">


                                    <div class="container allcp-form ">
                                        <div class="row">
                                            <div>
                                                Select Start Date:
                                            </div>

                                            <div class='col-sm-3'>

                                                <div id='Remark'>
                                                    &nbsp; &nbsp;<input formControlName="date" type="date"
                                                        value="Yes">&nbsp;&nbsp;

                                                </div>
                                            </div>


                                        </div>
                                        <div class="row" style="padding-top: 20px ;">

                                            <div class='col-sm-3'>
                                                Remarks :
                                                <div id='Remark'>
                                                    <textarea formControlName="Comment" type='text' class="form-control"
                                                        style="width: 225px;" required>
                                            </textarea>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <label class="ComleteRequestDetails" for=""
                                                    *ngIf="this.updateRequestDetails.controls['Note'].status=='INVALID' && UpdateRequestBtn==true">*Request
                                                    type Required</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="anvayaabutton-primary"
                                        (click)="ConfirmIfJobStart('Rescheduled')">Reschedule Job</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" [formGroup]="changeRequestDetails">
                    <!-- Button trigger modal -->
                    <!-- Modal -->
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Cancel Job</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="container allcp-form ">
                                        <div class="row">
                                            <!-- <div>
                                                Do You Want To Find New Vendor :
                                            </div> -->

                                            <!-- <div class='col-sm-3'>

                                                <div id='Remark'>
                                                    Yes &nbsp;: &nbsp;<input formControlName="Assigned_To_Partner"
                                                        type="radio" value="Yes">&nbsp;&nbsp;
                                                    No &nbsp;:&nbsp; <input type="radio"
                                                        formControlName="Assigned_To_Partner" value="No">
                                                </div>
                                            </div> -->


                                        </div>
                                        <div class="row" style="padding-top: 20px ;">

                                            <div class='col-sm-3'>
                                                Remarks :
                                                <div id='Remark'>
                                                    <textarea formControlName="Comment" type='text' class="form-control"
                                                        required style="width: 225px;" placeholder="Enter Comment">
                        </textarea>
                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                </div>

                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="anvayaabutton-secondary"
                                        (click)="ConfirmIfJobStart('Canceled')">Cancel Job</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-1"> </div>
                    <div class="col-md-2">
                        <button class="btn btn-info " data-bs-toggle="modal" data-bs-target="#exampleModal">Cancel
                            Job</button>
                    </div>
                    <div class="col-md-1"> </div>
                    <div class="col-md-1"> </div>
                    <div class="col-md-2">
                        <button class="btn btn-info " data-bs-toggle="modal" data-bs-target="#ReshedulJob">Reschedule
                            Job</button>
                    </div>
                    <div class="col-md-1"> </div>
                    <div class="col-md-1"> </div>
                    <div class="col-md-2">
                        <button class="btn btn-info " (click)="ConfirmIfJobStart('Job_Started')">Job started</button>
                    </div>
                    <div class="col-md-1"> </div>
                </div>
            </div>
            <div *ngIf="task=='Inform_Vendor_Of_Scheduled_Job'" [formGroup]="informVendorNewJob">

                <div class="row">

                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <label class="rqDetails"><u>Inform Vendor of scheduled Job</u></label>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>

                <div class="row serviceName">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="fontstyle">Job Start Date </label>:
                                <input formControlName="jobStartDate" type="datetime-local" readonly>
                            </div>
                            <div class="col-md-6">
                                <label class="fontstyle">Job End Date </label>:
                                <input type="date">
                            </div>
                        </div>
                        <div style="width: 70%;border: 1px solid black; margin-top: 50px;background-color:#ECECEC;">
                            <h6 class="supportexcutivetitle fontstyle">Vendor Contact Details :</h6>
                            <table>
                                <tr>
                                    <td class="fontstyle">Name</td>
                                    <td>:</td>
                                    <td *ngIf="ActiveVendorObj&&ActiveVendorObj.VendorName">
                                        {{ActiveVendorObj.VendorName}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fontstyle">Mobile No</td>
                                    <td>:</td>


                                    <td *ngIf="ActiveVendorObj&&ActiveVendorObj.VendorName">
                                        {{ActiveVendorObj.VendorsContactNumber}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div style="width: 70%;border: 1px solid black; margin-top: 78px;background-color:#ECECEC;">
                            <h6 class="supportexcutivetitle fontstyle">Profile Contact Details :</h6>
                            <table>
                                <tr>
                                    <td class="fontstyle">Name</td>
                                    <td>:</td>
                                    <td *ngIf="requestDetailsObj.RequestDetails.VendorProfile.length>0">
                                        {{requestDetailsObj.RequestDetails.VendorProfile[0].Name}}</td>
                                </tr>
                                <tr>
                                    <td class="fontstyle">Mobile No</td>
                                    <td>:</td>
                                    <td *ngIf="requestDetailsObj.RequestDetails.VendorProfile.length>0">
                                        {{requestDetailsObj.RequestDetails.VendorProfile[0].PrimaryMobileNo}}</td>
                                </tr>
                            </table>
                        </div>

                    </div>
                </div>
                <div style="margin-top: 50px;"></div>
            </div>
            <div *ngIf="task=='Check_On_CompletedJobs'" [formGroup]="CompleteTheJob">
                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-6">
                        <label class="rqDetails"><u>Check On Complete Job</u></label>
                    </div>
                    <div class="col-md-2">

                    </div>
                </div>

                <div class="row serviceName">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="fontstyle">Job Start Date </label>:
                                <input formControlName="RequestedDate" readonly type="datetime-local">
                            </div>
                            <div class="col-md-6">
                                <label class="fontstyle">Job End Date </label>: <br>

                                <input formControlName="EndDate" type="date">
                            </div>
                            <div class="row tasks">
                                <div class="col-md-3">
                                    <h5 class="pad10px fontstyle">Rating </h5>

                                </div>
                                <div class="col-md-6">
                                    <div class="rate" (change)="ratings($event)">
                                        <input type="radio" id="star5" name="rate" value="5" />
                                        <label for="star5" title="text">5 stars</label>
                                        <input type="radio" id="star4" name="rate" value="4" />
                                        <label for="star4" title="text">4 stars</label>
                                        <input type="radio" id="star3" name="rate" value="3" />
                                        <label for="star3" title="text">3 stars</label>
                                        <input type="radio" id="star2" name="rate" value="2" />
                                        <label for="star2" title="text">2 stars</label>
                                        <input type="radio" id="star1" name="rate" value="1" />
                                        <label for="star1" title="text">1 star</label>
                                    </div>
                                </div>




                            </div>



                        </div>
                        <div class="col-md-8">
                            <div>
                                <label>Feedback :</label>
                                <textarea formControlName="Comments" id="w3review"
                                    style="background-color:#ECECEC; width: 100%;height: 200px;">
                            </textarea>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="previousRating_tab">
                            <h6 class="supportexcutivetitle fontstyle">Previous Ratings :</h6>
                            <div class="nestedRatingTab">
                                <div class="row ratingResoningTab">
                                    <div class="col-md-2 fontstyle">Rating </div>
                                    <div class="col-md-1">:</div>

                                    <div class="col-md-1">4</div>
                                    <div class="col-md-2"></div>
                                    <div class="col-md-2 fontstyle">Date:</div>
                                    <div class="col-md-2"><input type="date"></div>

                                </div>
                                <hr>
                                <div class="row">
                                    <label>Reason :</label>

                                </div>
                            </div>
                            <div class="nestedRatingTab" style="margin-top: 30px;">
                                <div class="row ratingResoningTab">
                                    <div class="col-md-2 fontstyle">Rating </div>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-1">4</div>
                                    <div class="col-md-2"></div>
                                    <div class="col-md-2 fontstyle">Date:</div>
                                    <div class="col-md-2"><input type="date"></div>

                                </div>
                                <hr>
                                <div class="row">
                                    <label>Reason :</label>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row" [formGroup]="extendJob">


                    <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog ">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="DropJob">Extend Job</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="container allcp-form ">
                                        <div class="row">

                                            <div class='col-sm-3'>
                                                EndDate :
                                                <div id='Remark'>
                                                    <input type="date" formControlName="EndDate" value="" required
                                                        style="width: 225px;">

                                                </div>
                                            </div>



                                        </div>

                                    </div>



                                </div>

                                <div class="modal-footer">
                                    <button type="button" class="anvayaabutton-secondary"
                                        data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="anvayaabutton-primary"
                                        (click)="CheckOnCompletedJobs('InProgress')">Extend JOb</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="row" [formGroup]="dropJob">
                    <div class="col-md-1"> </div>
                    <div class="col-md-2">
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="DropJob">Drop Job</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="container allcp-form ">
                                            <div class="row">

                                                <div class='col-sm-3'>
                                                    EndDate :
                                                    <div id='Remark'>
                                                        <input type="date" formControlName="EndDate" value="" required
                                                            style="width: 225px;">

                                                    </div>
                                                </div>



                                            </div>
                                            <div class="row" style="padding-top: 20px ;">
                                                <!-- 
                                            <div class='col-sm-3'>
                                                EndDate :
                                                <div id='Remark'>
                                                    <input type="date" value="" required style="width: 225px;"  >
                                              
                                                </div>
                                            </div> -->
                                                <div>

                                                </div>

                                                <!-- <div class='col-sm-3'>
                                                    Do You Want To Find New Vendor :
                                                    <div id='date' style="margin-top:20px ;">
                                                        Yes &nbsp;: &nbsp;<input formControlName="Assigned_To_Partner"
                                                            type="radio" value="Yes">&nbsp;&nbsp;
                                                        No &nbsp;:&nbsp; <input type="radio"
                                                            formControlName="Assigned_To_Partner" value="No">
                                                    </div>
                                                </div> -->
                                                <div class='col-sm-3' style="margin-left: 10%;">
                                                    Remarks :
                                                    <div id='Remark'>
                                                        <textarea formControlName="Comment" type='text'
                                                            class="form-control" required style="width: 225px;"
                                                            placeholder="Enter Comment" required>
                                          </textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                    <div class="modal-footer">
                                        <button type="button" class="anvayaabutton-secondary"
                                            data-bs-dismiss="modal">Close</button>
                                        <button type="button" class="anvayaabutton-primary"
                                            (click)="ConfirmIfJobStart('Dropped')">Drop JOb</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--                         
                        <!-- <button class="btn btn-info " class="modal-title" id="exampleModalLabel" data-bs-toggle="modal"
                            data-bs-target="#exampleModal">Drop the JOb</button> -->
                        <!-- <button class="btn btn-info " data-bs-toggle="modal" data-bs-target="#ReshedulJob">Reshedul
                                Job</button> -->

                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <button class="anvayaabutton-primary" style="width: fit-content;" id="DropJob"
                                data-bs-toggle="modal" data-bs-target="#exampleModal">Drop the Job</button>
                        </div>
                        <div class="col-md-4"><button class="anvayaabutton-secondary" style="width: fit-content;"
                                id="DropJob" data-bs-toggle="modal" data-bs-target="#exampleModal1">Extend the
                                Job</button></div>
                        <!-- <div class="col-md-4">
                            <button class="btn btn-info " (click)="CheckOnCompletedJobs('Completed')">Complete the
                                Job</button>
                        </div> -->
                    </div>
                    <div class="col-md-1"> </div>
                </div>
            </div>
            <div *ngIf="task=='Assign_Vendor_Executive'">

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-6">
                        <label class="rqDetails"><u>Assign Vendor Executive</u></label>
                    </div>
                    <div class="col-md-2">

                    </div>
                </div>


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <span ngClass="form-control">
                            <label class="fontstyle">Select Vendor Executive :</label>

                            <select (change)="assignVendor($event)" class="form-select"
                                aria-label="Default select example">
                                <option selected>Select </option>
                                <option *ngFor="let list of AuthorizedteamList" [value]="list.EmployeeID">
                                    {{list.FirstName }} {{list.LastName}}
                                </option>
                            </select>

                        </span>

                    </div>
                    <div class="col-md-4"></div>
                </div>


            </div>
            <div *ngIf="task=='Schedule_A_New_Job'">


                <div class="row">
                    <div class="modal fade " id="UpdateStatus" role="dialog " aria-hidden="true "
                        data-backdrop="static " data-keyboard="false " aria-labelledby="fieldEmployeeModalLabel "
                        style="padding-bottom: 50px;">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content" style="height: auto; width: auto;">
                                <div class="modal-header model">
                                    <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
                                    <h4 class="titlevendor modal-title  text-center ">Vendor Status Details</h4>
                                </div>
                                <form name="UpdateStatusForm" [formGroup]="vendorUpdate">
                                    <div class="modal-body">
                                        <div class="container allcp-form ">
                                            <div class="row">

                                                <div class='col-sm-3'>
                                                    Select Start Date :

                                                    <div class="">
                                                        <input formControlName="StartDate" type='datetime-local'
                                                            class="form-control" placeholder="Select Start Date"
                                                            required>
                                                    </div>

                                                </div>
                                                <div class='col-sm-3'>
                                                    Select End Date :

                                                    <div class="">
                                                        <input formControlName="EndDate" type='datetime-local'
                                                            class="form-control" placeholder="Select End Date" required>
                                                    </div>

                                                </div>


                                                <div class='col-sm-3'>
                                                    Price :

                                                    <div id='price'>
                                                        <input type='text' class="form-control" formControlName="Price"
                                                            [attr.disabled]="true ? '' : null" maxlength="5"
                                                            placeholder="Enter Price" required>

                                                    </div>

                                                </div>
                                                <div class='col-sm-3'>
                                                    Job Status
                                                    <div>
                                                        <label for="PriceFor" class="">

                                                            <input type="text" class="form-control"
                                                                [attr.disabled]="true ? '' : null"
                                                                formControlName="PriceFor" />
                                                        </label>
                                                    </div>
                                                </div>


                                            </div>
                                            <div class="row" style="padding-top: 20px ;">

                                                <div class='col-sm-2'>
                                                    Remarks :
                                                    <div id='Remark'>
                                                        <textarea formControlName="StatusRemarks" type='text'
                                                            class="form-control" required style="width: 225px;"
                                                            placeholder="Enter Comment">
                                                </textarea>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </form>
                                <div class="modal-footer">
                                    <button type="button" class="backbtn" (click)="vendorStatusUpdate()">Save </button>
                                    <button type="button" class="closeBtn" data-bs-dismiss="modal">Close</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="" [hidden]="requestDetailsObj.RequestDetails.serviceType=='Onetime'">
                    <div class="card panel-default">
                        <div class="card-body">
                            <table class="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <!-- <p>{{requestDetailsObj.RequestDetails.ActiveVendors.length}}</p> -->
                                        <!-- <th scope="col">Mobile NO</th> -->
                                        <th scope="col">ID Type</th>
                                        <th scope="col">Profile</th>
                                        <th scope="col">Vendor Name</th>
                                        <th scope="col">Minimum Price</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Accept</th>
                                        <th scope="col">Reject</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let VendorData of requestDetailsObj.RequestDetails.VendorProfile;let i=index">

                                        <td>{{VendorData.Name}}</td>
                                        <!-- <td>{{VendorData.PrimaryMobileNo}}</td> -->
                                        <td>{{VendorData.IdType}}</td>
                                        <td> <button class="anvayaabutton-primary" (click)="showView(i)">View</button>
                                        </td>
                                        <td>{{VendorData.VendorData.VendorName}}</td>
                                        <td>&#8377; {{VendorData.MinimumPrice}} / {{VendorData.TariffType}}</td>
                                        <td>{{VendorData.Status}}</td>
                                        <td> <button class="anvayaabutton-primary"
                                                (click)="profileActed(VendorData.ProfileID,'Approved',VendorData.VendorID,VendorData)"
                                                [ngClass]="{'btnColor' : VendorData.Status==='Dropped'}"
                                                [disabled]="VendorData.vendorStatus=='Yes'||VendorData.Status=='Dropped'">Accept</button>
                                        </td>
                                        <td> <button class="anvayaabutton-secondary"
                                                (click)="profileActed(VendorData.ProfileID,'Rejected',VendorData.VendorID,VendorData)"
                                                [ngClass]="{'btnColor' : VendorData.Status==='Rejected'||VendorData.Status==='Dropped'}"
                                                [disabled]="VendorData.Status==='Rejected' || VendorData.Status== 'Dropped'">Reject</button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                        <!-- <div class="col-md-4" [hidden]="VendorData.Status!='Job_Completed'">
                        <button btns style="float: right;" class=" startJob btn btn-primary" data-bs-toggle="modal"
                        data-bs-target="#UpdateStatus" >Send Back To Vendor</button>
                    </div> -->
                    </div>
                </div>



            </div>
            <div *ngIf="task=='Assign_Support_Executive'">

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-6">
                        <label class="rqDetails"><u>Assign Support Executive</u></label>
                    </div>
                    <div class="col-md-2">

                    </div>
                </div>

                <div class="row " style="margin-top:50px">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <span ngClass="form-control">
                            <label class="fontstyle">Select Support Executive :</label>

                            <select (change)="AssignSupport($event)" class="form-select"
                                aria-label="Default select example">
                                <option selected>Select </option>
                                <option *ngFor="let list of Executives" [value]="list.EmployeeID">
                                    {{list.FirstName }} {{list.LastName}}
                                </option>
                            </select>

                        </span>

                    </div>
                    <div class="col-md-4"></div>
                </div>


            </div>
            
            <div *ngIf="task=='Assign_Vendor_Add_Specialist'">

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-6">
                        <label class="rqDetails"><u>Assign Vendor Add Specialist</u></label>
                    </div>
                    <div class="col-md-2">

                    </div>
                </div>

                <div class="card" *ngIf="requestDetailsObj.RequestDetails.IsProfile=='No'">

                    <div class="card-header fontbold  text-center">
                        <h4>Vendor Details</h4>
                    </div>
                    <div class="">
                        <div class="row" [formGroup]="confirmVendor">


                            <div class="col-md-3">

                                <label>

                                    <h6>Select Partner:</h6>

                                </label>
                                <select class="form-select" formControlName="vendorID" (change)="vendorID($event)">
                                    <option value="" selected disabled hidden>
                                        Select Partner
                                    </option>
                                    <option *ngFor="let vList of partnerData" value="{{vList.partnerData.PartnerID}}">
                                        {{vList.partnerData?.BusinessInfo?.PartnerName}}
                                    </option>
                                </select>
                            </div>
                            <!-- <div class="col-md-3">

                                <label>
                                    <h6>Vendor price :</h6>
                                </label>

                                <input class="price" type="number" value="" min=0 oninput="validity.valid||(value='');"
                                    formControlName="VendorPrice" placeholder="Enter Vendor Price" required="">
                                <label style="color: red;"
                                    *ngIf="this.confirmVendor.controls['VendorPrice'].status=='INVALID'&& confirmVendorbtn">*Enter
                                    Vendor Price & min length 3</label>

                            </div> -->
                            <div class="col-md-3">
                                <label>
                                    <h6>Tariff : </h6>
                                </label>
                                <select class="form-select" formControlName="TariffType"
                                    (change)="ReferralType($event)">
                                    <option selected disabled hidden value="">Select</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="OneTime">OneTime</option>
                                </select>
                            </div>

                            <!-- <div class="col-md-3">
                                <div class="row">
                                    <label>
                                        <h6>Payment Type :</h6>
                                    </label>
                                    <div class="form-check col-md-6">
                                        <input class="form-check-input" value="Percentage" type="radio"
                                            name="flexRadioDefault" id="flexRadioDefault1"
                                            formControlName="ReferralType" (change)="ReferralType($event)">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Percentage
                                        </label>
                                    </div>
                                    <div class="form-check col-md-6">
                                        <input class="form-check-input" type="radio" value="Amount"
                                            name="flexRadioDefault" id="flexRadioDefault2"
                                            formControlName="ReferralType" (change)="ReferralType($event)">
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            Amount
                                        </label>
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="col-md-3" *ngIf="referalPercentage">
                                <label>Referral Percentage</label>
                                <input class="form-control" formControlName="AnvayaaReferralFee"
                                    name="AnvayaaReferralFee" [maxlength]="2" [minlength]="1">
                            </div> -->
                            <!-- <div class="col-md-3" *ngIf="referalAmount">
                                <label> Final Amount to Customer</label>
                                <input class="form-control" formControlName="AnvayaaReferralFee" [minlength]="3"
                                    [maxlength]="7">
                            </div> -->


                            <div class="col-md-3" *ngIf="this.SelectedvendorsDetails.length>0">



                                <div *ngIf="this.SelectedvendorsDetails[0].VendorDetails.Type=='Internal'">

                                    <div>
                                        Anvayaa price:

                                    </div>
                                    <input class="price" min=0 oninput="validity.valid||(value='');" type="number"
                                        value="" formControlName="AnvayaaPrice"
                                        required="this.SelectedvendorsDetails[0].VendorDetails.Type=='Internal'">
                                    <label style="color: red;"
                                        *ngIf="this.confirmVendor.controls['AnvayaaPrice'].status=='INVALID'&&confirmVendorbtn">*Enter
                                        Anvayaa Price & min length 3</label>
                                </div>

                                <div>

                                </div>

                            </div>
                        </div>


                        <div class="row">

                            <div class="col-md-3" style="margin-top: 20px;">
                                <button class="btn btn-success" (click)="assignVendorForRequest()"> Confirm
                                    Partner</button>

                            </div>




                            <div class="row" *ngIf="requestDetailsObj.RequestDetails.ActiveVendors.length>0"
                                style="margin-top: 30px;">
                                <table class="table table-striped table-hover table-bordered">
                                    <thead>
                                        <tr>

                                            <th>VendorName</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let vendor of requestDetailsObj.RequestDetails.ActiveVendors">
                                        <tr>

                                            <td>{{vendor.VendorName}}</td>
                                            <td>{{vendor.Status}}</td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                            <div style="text-align: right; margin-right: 20px;" *ngIf="isVendorProfiles">
                                <button class="btn btn-success" (click)="SubmitToPartner()"> Get Job confirmation
                                </button>
                            </div>
                        </div>



                    </div>
                </div>
                <div class="card" *ngIf="requestDetailsObj.RequestDetails.IsProfile=='Yes'" [formGroup]="confirmVendor">

                    <div class="card-header text-center">
                        <b>Vendor Details</b>
                    </div>

                    <div class="card-body">
                        <form [formGroup]="profileForm" id="profileForm" class="border" style="padding: 10px;">
                            <div class="row">

                                <div class="col-md-3">
                                    <label class="inputtext">
                                        <h6>Select ID Type :</h6>
                                    </label>
                                    <select formControlName="IdType" class="form-select" (change)="idValidation($event)"
                                        required>
                                        <option [ngValue]="null" selected disabled>Select ID Type</option>
                                        <option value="AadharID">Aadhar Card</option>
                                        <option value="VoterID">Voter Id</option>
                                        <option value="Licence">Driving Licence</option>
                                    </select>
                                    <div *ngIf=" profileForm.controls['IdType'].status == 'INVALID' && submitted == true"
                                        class="errorColor">*Select ID </div>


                                </div>
                                <div class="col-md-3">
                                    <label class="inputtext">
                                        <h6>ID Number:</h6>
                                    </label>
                                    <input type="tel" placeholder="ID Number" formControlName="IdNumber"
                                        class="form-control" (keyup)="idInput($event)">

                                    <div *ngIf="idProofAadhar">
                                        <div
                                            *ngIf="profileForm.controls['IdNumber'].invalid && (profileForm.controls['IdNumber'].touched || profileForm.controls['IdNumber'].dirty)">
                                            <span *ngIf="profileForm.controls['IdNumber'].errors?.['required']"
                                                class="errorColor">
                                                *Aadhar Number is required.
                                            </span>
                                            <span *ngIf="profileForm.controls['IdNumber'].hasError('minlength')"
                                                class="errorColor">
                                                *Atleast minimum 12 digits Required.
                                            </span>
                                            <span *ngIf="profileForm.controls['IdNumber'].errors?.['maxlength']"
                                                class="errorColor">
                                                *cannot exceed 12 characters.
                                            </span>
                                        </div>
                                    </div>
                                    <div *ngIf="idVoter">
                                        <div
                                            *ngIf="profileForm.controls['IdNumber'].invalid && (profileForm.controls['IdNumber'].touched || profileForm.controls['IdNumber'].dirty)">
                                            <span *ngIf="profileForm.controls['IdNumber'].errors?.['required']"
                                                class="errorColor">
                                                *VoterID Number is required.
                                            </span>
                                            <span *ngIf="profileForm.controls['IdNumber'].hasError('minlength')"
                                                class="errorColor">
                                                *Atleast minimum 10 digits Required.
                                            </span>
                                            <span *ngIf="profileForm.controls['IdNumber'].errors?.['maxlength']"
                                                class="errorColor">
                                                *cannot exceed 10 characters.
                                            </span>
                                        </div>
                                    </div>
                                    <div *ngIf="idLicence">
                                        <div
                                            *ngIf="profileForm.controls['IdNumber'].invalid && (profileForm.controls['IdNumber'].touched || profileForm.controls['IdNumber'].dirty)">
                                            <span *ngIf="profileForm.controls['IdNumber'].errors?.['required']"
                                                class="errorColor">
                                                *License Number is required.
                                            </span>
                                            <span *ngIf="profileForm.controls['IdNumber'].hasError('minlength')"
                                                class="errorColor">
                                                *Atleast minimum 10 digits Required.
                                            </span>
                                            <span *ngIf="profileForm.controls['IdNumber'].errors?.['maxlength']"
                                                class="errorColor">
                                                *cannot exceed 10 characters.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label class="inputtext">
                                        <h6>Name :</h6>
                                    </label>
                                    <input type="text" placeholder="Name" formControlName="Name" class="form-control"
                                        required>

                                    <div *ngIf=" profileForm.controls['Name'].status == 'INVALID'&& submitted == true"
                                        class="errorColor">* Name is Required </div>
                                </div>
                                <div class="col-md-3">
                                    <label class="inputtext">Contact Number:</label>
                                    <input type="tel" placeholder="Contact Number" formControlName="PrimaryMobileNo"
                                        class="form-control" required maxlength="10">
                                    <div
                                        *ngIf="profileForm.controls['PrimaryMobileNo'].invalid && (profileForm.controls['PrimaryMobileNo'].touched || profileForm.controls['PrimaryMobileNo'].dirty)">
                                        <span *ngIf="profileForm.controls['PrimaryMobileNo'].errors?.['required']"
                                            class="errorColor">
                                            *Mobile Number is required.
                                        </span>
                                        <span *ngIf="profileForm.controls['PrimaryMobileNo'].hasError('minlength')"
                                            class="errorColor">
                                            *Atleast minimum 10 digits Required.
                                        </span>
                                        <span *ngIf="profileForm.controls['PrimaryMobileNo'].errors?.['maxLength']"
                                            class="errorColor">
                                            *Mobile Number cannot exceed 10 characters.
                                        </span>
                                    </div>

                                </div>
                                <div class="col-md-3">
                                    <label class="inputtext">
                                        <h6>Select Partner:</h6>
                                    </label>
                                    <select class="form-select" formControlName="VendorID" (change)="vendorID($event)"
                                        required>
                                        <option value="" selected disabled>Select Partner</option>
                                        <option class="form-control" *ngFor="let Vendi of partnerData"
                                            [value]="Vendi.partnerData.PartnerID">
                                            {{Vendi.partnerData.BusinessInfo.PartnerName}}
                                        </option>
                                    </select>
                                    <div *ngIf="profileForm.controls['VendorID'].status == 'INVALID' && submitted == true"
                                        class="errorColor">* Select Vendor Name Required </div>

                                </div>

                                <div class="col-md-3">
                                    <label class="inputtext">Upload Document</label>
                                    <input type="file" class="form-control" #fileUploader
                                        (change)="onuploadtaxFile($event)" data-buttontext="Select a File">
                                </div>
                                <div class="col-md-3" *ngIf="pUrl">
                                    <div>Existing Document</div>
                                    <button (click)="existing()">View</button>
                                </div>


                                <div class="col-md-3">
                                    <label>Tariff</label>
                                    <select class="form-select" formControlName="TariffType"
                                        (change)="ReferralType($event)">
                                        <option selected value="" disabled>Select</option>
                                        <option value="Daily">Daily</option>
                                        <option value="Monthly">Monthly</option>
                                    </select>
                                </div>
                                <div class="col-md-3" *ngIf="minimumSellingPrice > 0 || minimumSellingPrice">

                                    <label class="inputtext">
                                        <h6>Minimum Selling Price</h6>
                                    </label>
                                    <br>
                                    <div class="minimum-price">
                                        <input type="number" min="0" class="form-control" formControlName="MinimumPrice"
                                            readonly>
                                    </div>

                                </div>
                                <div class="col-md-3" *ngIf="minimumSellingPrice > 0 || minimumSellingPrice">

                                    <label class="inputtext" for="isPriceChanged">
                                        <h6>Do you want to change the Price</h6>
                                    </label>
                                    <br>
                                    <div class="radioBtns">
                                        <input type="radio" value="Yes" formControlName="isItDiscountedPrice"
                                            (click)="onChangeMinimumPrice(true)" /> <b> Yes </b>
                                        <input type="radio" value="No" formControlName="isItDiscountedPrice"
                                            (click)="onChangeMinimumPrice(false)" /> <b> No </b>
                                    </div>

                                    <!-- <div class="minimum-price">
                                        <span class="form-control" formControlName="MinimumPrice"> {{minimumSellingPrice>0?minimumSellingPrice:''}}</span>
                                        <span class="changePrice" (click)="onChangeMinimumPrice()"> Change the price</span>
                                    </div> -->

                                </div>

                                <div class="col-md-3" *ngIf="discountPriceFlag">
                                    <label class="inputtext">
                                        <h6>Enter Discounted Price :</h6>
                                    </label>
                                    <input type="number" min="1" placeholder="DiscountedPrice"
                                        formControlName="DiscountedPrice" class="form-control" required>
                                    <div class="errorColor"
                                        *ngIf="submitted && discountPriceFlag && profileForm.value.DiscountedPrice > profileForm.value.MinimumPrice">
                                        * Discounted price should be less than minimum price </div>
                                </div>
                            </div>
                            <br>
                            <div>
                                <button value="Submit" class="addProfile" (click)="SubmitProfile()">Add Profile</button>
                            </div>
                        </form>

                        <table class="table" *ngIf="isVendorProfiles" style="margin-top:10px;">
                            <thead class="thead-dark">
                                <tr style="background-color: #b6b2b2;">
                                    <th scope="col">ID Type</th>
                                    <th scope="col">ID Number</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Mobile No:</th>
                                    <th scope="col">Vendor ID/Name</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Profile</th>
                                    <th scope="col">Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor=" let details of requestDetailsObj.RequestDetails.VendorProfile;let i=index">
                                    <td>{{details.IdType}}</td>
                                    <td>{{details.IdNumber}}</td>
                                    <td>{{details.Name}}</td>
                                    <td>{{details.PrimaryMobileNo}}</td>
                                    <td>{{details.VendorData.VendorName}}</td>
                                    <td>{{details.Status}}</td>
                                    <td><button class="addProfile" (click)="fileView(i)">View</button></td>
                                    <td><button (click)="removeProfile(details)" [disabled]="details.Status !='None'"
                                            class="addProfile">Delete</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="text-align: right; margin-right: 20px;" *ngIf="isVendorProfiles">
                            <button class="btn btn-success" (click)="SubmitToPartner()" class="addProfile"> Get Job
                                confirmation </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="task=='Add_Job_Details'">
                <div class="row">
                    <div class="modal fade " id="UpdateStatus" role="dialog " aria-hidden="true "
                        data-backdrop="static " data-keyboard="false " aria-labelledby="fieldEmployeeModalLabel "
                        style="padding-bottom: 50px;">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content" style="height: auto; width: auto;">
                                <div class="modal-header model">
                                    <h4 class="titlevendor modal-title  text-center ">Vendor Status Details</h4>
                                </div>
                                <form name="UpdateStatusForm" [formGroup]="vendorUpdate">
                                    <div class="modal-body">
                                        <div class="container allcp-form ">
                                            <div class="row">
                                                <div class='col-sm-3'>
                                                    Select Start Date :
                                                    <div class="input-group date">
                                                        <input formControlName="StartDate" type='datetime-local'
                                                            class="form-control" placeholder="Select Start Date"
                                                            required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-1"></div>
                                                <div class='col-sm-3'>
                                                    Select End Date :
                                                    <div class="input-group date">
                                                        <input formControlName="EndDate" type='datetime-local'
                                                            class="form-control" placeholder="Select End Date" required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-1"></div>
                                                <div class='col-sm-3'>
                                                    Price :
                                                    <div id='price'>
                                                        <input type='text' class="form-control" formControlName="Price"
                                                            maxlength="6" placeholder="Enter Price" required>
                                                    </div>
                                                </div>
                                                <div class='col-sm-3'>
                                                    Job Status :
                                                    <div>
                                                        <label>
                                                            <select class="form-select" formControlName="PriceFor"
                                                                style="height: 40px;">
                                                                <option selected disabled value=""> ---Select Tariff
                                                                    Status---</option>
                                                                <option value="OneTime" disabled> OneTime</option>

                                                                <option value="Daily" disabled> Daily</option>
                                                                <option value="Monthly" disabled>Monthly </option>
                                                            </select>
                                                            <i class="arrow double"></i>

                                                        </label>
                                                    </div>

                                                </div>
                                                <div class="col-sm-1"></div>
                                                <div class='col-sm-2'>
                                                    Remarks :
                                                    <div id='Remark'>
                                                        <textarea formControlName="StatusRemarks" type='text'
                                                            class="form-control" required style="width: 225px;"
                                                            placeholder="Enter Comment">
                                                                </textarea>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </form>
                                <div class="modal-footer">
                                    <button type="button" class="backbtn" (click)="vendorStatusUpdate()">Save </button>
                                    <button type="button" class="closeBtn" data-bs-dismiss="modal">Close</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="bodycontent"
                    [hidden]="requestDetailsObj.RequestDetails.serviceType=='Onetime' || requestDetailsObj.RequestDetails.ActiveVendors.length>0">
                    <div class="card panel-default">
                        <div class="card-body">
                            <table class="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                 
                                        <th scope="col">ID Type</th>
                                        <th scope="col">Profile</th>
                                        <th scope="col">Vendor Name</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Accept</th>
                                        <th scope="col">Reject</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let VendorData of requestDetailsObj.RequestDetails.VendorProfile;let i=index">

                                        <td>{{VendorData.Name}}</td>
                                
                                        <td>{{VendorData.IdType}}</td>
                                        <td> <button class="btn btn-primary" (click)="showView(i)">View</button></td>
                                        <td>{{VendorData.VendorData.VendorName}}</td>
                                        <td>{{VendorData.Status}}</td>
                                        <td> <button class="AcceptBtn"
                                                (click)="profileActed(VendorData.ProfileID,'Approved',VendorData.VendorID)"
                                                [disabled]="VendorData.vendorStatus=='Yes'">Accept</button>
                                        </td>
                                        <td> <button class="rejectBtn"
                                                (click)="profileActed(VendorData.ProfileID,'Rejected',VendorData.VendorID)"
                                                [disabled]="VendorData.Status==='Rejected'">Reject</button> </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                       
                    </div>
                </div> -->

                <div class="" *ngIf="requestDetailsObj.RequestDetails.ActiveVendors.length>0">
                    <div class="card panel-default">
                        <div class="card-body">
                            <table class="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">START DATE</th>
                                        <th scope="col">END DATE</th>
                                        <th scope="col">VENDOR NAME</th>
                                        <th scope="col">JOB STATUS</th>
                                        <th scope="col">TARIF TYPE</th>
                                        <th scope="col">Minimum Price</th>
                                        <th scope="col">Final Price</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let VendorData of requestDetailsObj.RequestDetails.ActiveVendors;let i=index">

                                        <td *ngIf="VendorData.StartDate!=null">{{(VendorData.StartDate)*1000 |
                                            date:'dd/MM/YYYY' }}</td>
                                        <td *ngIf="VendorData.StartDate==null"></td>
                                        <td *ngIf="VendorData.EndDate!=null">{{(VendorData.EndDate)*1000 |
                                            date:'dd/MM/YYYY'}}</td>
                                        <td *ngIf="VendorData.EndDate==null"></td>
                                        <td>{{VendorData.VendorName}}</td>
                                        <td>{{(VendorData.Status).replace('_',' ')}}</td>
                                        <td>{{VendorData.PriceFor}}</td>
                                        <td>&#8377; {{VendorData.MinimumPrice}} /{{VendorData.PriceFor}}</td>
                                        <td>{{VendorData.Price}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- <div class="col-md-4" [hidden]="VendorData.Status!='Job_Completed'">
                                        <button btns style="float: right;" class=" startJob btn btn-primary" data-bs-toggle="modal"
                                        data-bs-target="#UpdateStatus" >Send Back To Vendor</button>
                                    </div> -->
                    </div>
                    <button class="ScheduleJob btn anvayaaButton" data-bs-toggle="modal"
                        *ngIf="requestDetailsObj.RequestDetails.ActiveVendors.length>0"
                        data-bs-target="#UpdateStatus">Schedule Job
                    </button>
                </div>



            </div>
            <div *ngIf="task=='Verify_Job_Details_with_Customer'">
                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-6">
                        <label class="rqDetails"><u>Verify Job Details with Customer</u></label>
                    </div>
                    <div class="col-md-2">

                    </div>
                </div>


                <div class="card" *ngIf="requestDetailsObj.RequestDetails.JobPaymentDetails"
                    [formGroup]="confirmVendor">

                    <div class="card-header">
                        <b>Reconsillation Details:</b>
                    </div>

                    <div class="card-body">

                        <form #f="ngForm">
                            <table class="table" style="margin-top:10px;">
                                <thead class="thead-dark">
                                    <tr style="background-color: #b6b2b2;">
                                        <th scope="col">StartDate</th>
                                        <th scope="col">End Date</th>
                                        <th scope="col">No Of Working days</th>
                                        <th scope="col">Update</th>
                                        <th scope="col"></th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{requestDetailsObj?.ReconsillationDetials?.PaymentForDetails.HomeHelathServices.ReconsillationData.StartDate
                                            *1000 |
                                            date:"dd-MM-yyyy"}}</td>
                                        <td>{{requestDetailsObj.ReconsillationDetials?.PaymentForDetails.HomeHelathServices.ReconsillationData.EndDate
                                            *1000 |
                                            date:"dd-MM-yyyy"}}</td>
                                        <td> <input type="number" style="width:65px"
                                                value="{{requestDetailsObj.ReconsillationDetials?.PaymentForDetails.HomeHelathServices.ReconsillationData.ReconsillationFinalDays}}"
                                                (change)="workingDays($event)"> </td>
                                        <td><button class="addProfile"
                                                (click)="UpdateWorkingDays(requestDetailsObj.ReconsillationDetials,'Update')">Update</button>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <button style="margin-left:80%"
                                (click)="ApproveWorkingDays(requestDetailsObj.ReconsillationDetials,'Approve')"
                                class="addProfile">Approve</button>
                        </form>

                    </div>
                </div>
            </div>
            <div *ngIf="task=='Verify_Refund'">
                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-6">
                        <label class="rqDetails"><u>Verify Refund</u></label>
                    </div>
                    <div class="col-md-2">

                    </div>
                </div>


                <div class="card" *ngIf="requestDetailsObj.RequestDetails.JobPaymentDetails"
                    [formGroup]="confirmVendor">

                    <div class="card-header">
                        <b>Reconsillation Details:</b>
                    </div>

                    <div class="card-body">

                        <form #f="ngForm">
                            <table class="table" style="margin-top:10px;">
                                <thead class="thead-dark">
                                    <tr style="background-color: #b6b2b2;">
                                        <th scope="col">StartDate</th>
                                        <th scope="col">End Date</th>
                                        <th scope="col">No Of Working days</th>
                                        <th scope="col">Update</th>
                                        <th scope="col"></th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{requestDetailsObj?.RequestDetails?.JobPaymentDetails?.PaymentForDetails.HomeHelathServices.ReconsillationData.StartDate
                                            *1000 |
                                            date:"dd-MM-yyyy"}}</td>
                                        <td>{{requestDetailsObj?.RequestDetails?.JobPaymentDetails?.PaymentForDetails.HomeHelathServices.ReconsillationData.EndDate
                                            *1000 |
                                            date:"dd-MM-yyyy"}}</td>
                                        <td> <input type="number" style="width:65px"
                                                value="{{requestDetailsObj?.RequestDetails?.JobPaymentDetails?.PaymentForDetails.HomeHelathServices.ReconsillationData.ReconsillationFinalDays}}"
                                                (change)="workingDaysbyPartner($event)"> </td>
                                        <td><button class="addProfile"
                                                (click)="UpdateWorkingDaysByPartner(requestDetailsObj.RequestDetails.JobPaymentDetails,'Update')">Update</button>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <button style="margin-left:80%"
                                (click)="ApproveWorkingDaysByPartner(requestDetailsObj.RequestDetails.JobPaymentDetails,'Approve')"
                                class="addProfile">Approve</button>
                        </form>

                    </div>
                </div>
            </div>
            <div *ngIf="task=='Procress_Refund'">
                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-6">
                        <label class="rqDetails"><u>Procress Refund</u></label>
                    </div>
                    <div class="col-md-2">

                    </div>
                </div>


                <div class="card" *ngIf="requestDetailsObj.RequestDetails.JobPaymentDetails"
                    [formGroup]="confirmVendor">

                    <div class="card-header">
                        <b>Reconsillation Details:</b>
                    </div>

                    <div class="card-body">

                        <form #f="ngForm">
                            <table class="table" style="margin-top:10px;">
                                <thead class="thead-dark">
                                    <tr style="background-color: #b6b2b2;">
                                        <th scope="col">StartDate</th>
                                        <th scope="col">End Date</th>
                                        <th scope="col">No Of Working days</th>
                                        <th scope="col">Update</th>
                                        <th scope="col"></th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{requestDetailsObj?.RequestDetails?.JobPaymentDetails?.PaymentForDetails.HomeHelathServices.ReconsillationData.StartDate
                                            *1000 |
                                            date:"dd-MM-yyyy"}}</td>
                                        <td>{{requestDetailsObj?.RequestDetails?.JobPaymentDetails?.PaymentForDetails.HomeHelathServices.ReconsillationData.EndDate
                                            *1000 |
                                            date:"dd-MM-yyyy"}}</td>
                                        <td> <input type="number" style="width:65px"
                                                value="{{requestDetailsObj?.RequestDetails?.JobPaymentDetails?.PaymentForDetails.HomeHelathServices.ReconsillationData.ReconsillationFinalDays}}"
                                                (change)="workingDaysbyPartner($event)"> </td>
                                        <td><button class="addProfile"
                                                (click)="UpdateWorkingDaysByPartner(requestDetailsObj.RequestDetails.JobPaymentDetails,'Update')">Update</button>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <button style="margin-left:80%"
                                (click)="ApproveWorkingDaysByPartner(requestDetailsObj.RequestDetails.JobPaymentDetails,'Approve')"
                                class="addProfile">Approve</button>
                        </form>

                    </div>
                </div>
            </div>
            <div *ngIf="task=='Upload_Vendor_Bill'">
                <div class="text-center">
                    <h3><u>Upload Vendor Bills </u></h3>

                </div>
                <form [formGroup]="UploadBills">
                    <table class="table text-center">
                        <thead>
                            <th>Amount</th>
                            <th>Attachment</th>
                            <th>ViewBill</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input type="number" class="form-control" formControlName="Amount" readonly></td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <input type="file" class="form-control" style="width:325px"
                                                (change)="uploadBill($event)" data-buttontext="Select a File">
                                        </div>
                                        <div class="col-md-6">
                                            <button class="anvayaabutton-primary"
                                                (click)="uploadVenderBill('Upload')">Upload
                                                Bill</button>
                                        </div>
                                    </div>
                                </td>
                                <td><button class="btn btn-success" (click)="viewFile()">View Document</button></td>
                            </tr>
                        </tbody>
                    </table>
                    <div> <button class="btn btn-warning" style="margin-left:80%"
                            (click)="uploadVenderBill('Submit')">Submit For Verification</button></div>
                </form>

            </div>

            <div *ngIf="task=='Cancelled_Requests'||task=='OTMCancelled_Requests'">
                <div class="text-center">
                    <h3><u>Approve Cancelled Request </u></h3>

                </div>
                <div class="row">
                    <table class="table text-center">
                        <thead>
                            <tr>
                                <th>Request ID</th>
                                <th>Sponsor Name</th>
                                <th>Service</th>
                                <th>Service Area</th>
                                <th>RequestedDate</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{requestDetailsObj.RequestDetails.RequestID}}</td>
                                <td>{{requestDetailsObj.RequestDetails.Name}}</td>
                                <td>{{requestDetailsObj.RequestDetails.SubCategoryName}}</td>
                                <td>{{requestDetailsObj.RequestDetails.ServiceAreaName}}</td>
                                <td>{{requestDetailsObj.RequestDetails.RequestedDate*1000 | date:'dd-MM-YYYY'}}</td>
                                <td>
                                    <button id="approveCancelRequest" class="btn anvayaaButton"
                                        (click)="onCancelApprove(requestDetailsObj.RequestDetails.RequestID)">Approve</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <form [formGroup]="UploadBills">
                    <table class="table text-center">
                        <thead>
                            <th>Amount</th>
                            <th>Attachment</th>
                            <th>ViewBill</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input type="number" class="form-control" formControlName="Amount" readonly></td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <input type="file" class="form-control" style="width:325px"
                                                (change)="uploadBill($event)" data-buttontext="Select a File">
                                        </div>
                                        <div class="col-md-6">
                                            <button class="btn btn-primary" (click)="uploadVenderBill('Upload')">Upload
                                                Bill</button>
                                        </div>
                                    </div>
                                </td>
                                <td><button class="btn btn-success" (click)="viewFile()">View Document</button></td>
                            </tr>
                        </tbody>
                    </table>
                    <div> <button class="btn btn-warning" style="margin-left:80%"
                            (click)="uploadVenderBill('Submit')">Submit For Verification</button></div>
                </form> -->

            </div>
        </div>
    </div>
    <div *ngIf="jobsAndBillsTab">
        <form #f="ngForm" (ngSubmit)="login( f.value )" *ngIf="type =='VerifyBill'">
            <div class="row">
                <div class="col-md-4">

                </div>
                <div class="col-md-6">
                    <label class="rqDetails"><u>Verify bills</u></label>
                </div>
                <div class="col-md-2">

                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <table>
                        <tbody>
                            <tr>
                                <td>Payment ID</td>
                                <td>:</td>
                                <td>{{requestDetailsObj?.RequestDetails?.PaymentDetails?.Payment.AnvayaaPaymentID}}</td>
                            </tr>
                            <tr>
                                <td>Requested Service</td>
                                <td>:</td>
                                <td>{{requestDetailsObj?.RequestDetails?.ServiceName}}</td>
                            </tr>
                            <tr>
                                <td>Service Type</td>
                                <td>:</td>
                                <td>{{requestDetailsObj?.RequestDetails?.ServiceRequestType}}</td>
                            </tr>
                            <tr>
                                <td>Service Area</td>
                                <td>:</td>
                                <td>{{requestDetailsObj?.RequestDetails?.PaymentDetails?.ServiceAreaName}}</td>
                            </tr>
                            <tr>
                                <td>Change EndDate</td>
                                <td>:</td>
                                <td>

                                    <button type="button" class="btn anvayaaButtonYellow" data-bs-toggle="modal"
                                        data-bs-target="#changeDate" data-bs-whatever="@getbootstrap">ClickHere</button>

                                    <div class="modal fade" id="changeDate" tabindex="-1"
                                        aria-labelledby="changeDateLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h1 class="modal-title fs-5" id="changeDateLabel">Change EndDate
                                                    </h1>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close" #closebutton></button>
                                                </div>
                                                <div class="modal-body">
                                                    <form [formGroup]=changeDateForm>
                                                        <div>
                                                            <label for="EndDate">New EndDate </label>
                                                            <input type="date" formControlName="EndDate"
                                                                class="form-control">
                                                            <span class="text-danger"
                                                                *ngIf="!changeDateForm.controls['EndDate'].valid && (changeDateForm.controls['EndDate']?.dirty ||changeDateForm.controls['EndDate']?.touched)">
                                                                Please Enter New End Date
                                                            </span>
                                                        </div>

                                                    </form>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn anvayaaButton"
                                                        (click)="onEndDateChange(requestDetailsObj.RequestDetails.PaymentDetails)">Update</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Request For Bill amount Adjustment</td>
                                <td>:</td>
                                <td>

                                    <button type="button" class="btn anvayaaButtonYellow" data-bs-toggle="modal"
                                        data-bs-target="#adjustDate" data-bs-whatever="@getbootstrap">ClickHere</button>

                                    <div class="modal fade" id="adjustDate" tabindex="-1"
                                        aria-labelledby="changeDateLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h1 class="modal-title fs-5" id="changeDateLabel">Enter Amount to be
                                                        adjusted</h1>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close" #closebutton></button>
                                                </div>
                                                <div class="modal-body">
                                                    <form [formGroup]=AdjustBillFrom>
                                                        <div>
                                                            <label for="EndDate">Enter Amount </label>
                                                            <input type="String" formControlName="Amount"
                                                                class="form-control"
                                                                placeholder="Enter Amount To be adjusted">
                                                            <span class="text-danger"
                                                                *ngIf="!AdjustBillFrom.controls['Amount'].valid && (AdjustBillFrom.controls['Amount']?.dirty ||AdjustBillFrom.controls['Amount']?.touched)">
                                                                Please Enter Amount
                                                            </span>
                                                        </div>

                                                    </form>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn anvayaaButton"
                                                        (click)="onBillAmountAdjustment(requestDetailsObj.RequestDetails.PaymentDetails)">Update</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-6">

                    <table>
                        <tbody>
                            <tr>
                                <td>Payment Mode </td>
                                <td>:</td>
                                <td><select class="form-select" [(ngModel)]="paymentType" name="paymentType"
                                        (change)="recipetbox($event)">
                                        <option value="" disabled selected>Select Payment Option</option>
                                        <option value="Wiretransfer">Wiretransfer</option>
                                        <option value="PMS">PMS</option>
                                        <option value="Cheque">Cheque</option>
                                        <option value="Cash">Cash</option>

                                    </select></td>
                            </tr>
                            <tr *ngIf="reciptenumber">
                                <td>Receipt No</td>
                                <td>:</td>
                                <td><input type=text [(ngModel)]="ReceiptNumber" name="Receipt Number"
                                        placeholder="Enter Receipt  Number" class="form-control"></td>
                            </tr>

                            <tr>
                                <td>Payment Date</td>
                                <td>:</td>
                                <td>{{requestDetailsObj?.RequestDetails?.PaymentDetails?.Payment?.PaymentCreatedDate*1000
                                    |
                                    date:"dd-MM-yyyy"}}</td>
                            </tr>
                            <tr>
                                <td>Total Price </td>
                                <td>:</td>
                                <td>{{requestDetailsObj?.RequestDetails?.PaymentDetails?.Payment.Price+"
                                    "+requestDetailsObj?.RequestDetails?.PaymentDetails?.Payment?.CurrencyType}}</td>
                            </tr>

                            <tr>
                                <td>View Bill </td>
                                <td>:</td>
                                <td><a class="btn btn-link"
                                        (click)="ViewBill(requestDetailsObj.RequestDetails.PaymentDetails)">View</a>
                                </td>

                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>
                                    <input type="submit" class="anvayaabutton-primary" value="Approve"
                                        [disabled]="f.invalid" />

                                </td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>



        </form>
    </div>
    <div *ngIf="requestDetailsTab==true">
        <div>
            <div style="text-align:center">
                <label class="rqDetails"><u>Request Details</u></label>
            </div>
            <div class="serviceDetails row" style="margin-top: 60px;">
                <div class="col-md-6">

                    <span class="serviceStartDate">ServiceName: </span> <label class="serviceNameNew"
                        *ngIf="requestDetailsObj.RequestDetails.AliasName">
                        {{requestDetailsObj.RequestDetails.AliasName}}</label>

                    <div class="serviceName">
                        <span class="serviceStartDate">Select Start Date :</span>
                        <div class=" input-group date">
                            <input type='datetime-local' formControlName="RequestedDate" class="startdate form-control"
                                placeholder="Select Start Date" required>
                        </div>
                    </div>
                    <div class="serviceName">
                        <span class="serviceStartDate">Select End Date :</span>
                        <div class=" input-group date">
                            <input type='date' class="startdate form-control" placeholder="Select Start Date" required>
                        </div>
                    </div>

                </div>
                <div class="col-md-6">
                    <table>
                        <tr>
                            <td class="charges">Service Charges</td>
                            <td>:</td>
                            <td><span class="chargesSpan">1500/-</span></td>
                        </tr>
                        <tr>
                            <td class="charges"> Cordination Charges</td>
                            <td>:</td>
                            <td><span class="chargesSpan">1000/-</span></td>
                        </tr>
                        <div>
                            <textarea name="" id="" style="height: 150px; width: 400px;" formControlName="Note"
                                placeholder="Comment"></textarea>
                        </div>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="customerDetailsTab">
        <label for="" class="customerDetails"><u>Customer Details</u> </label>

        <div class="detailsspcm row">
            <div class="sponserDetailsStyle col-md-6">
                <!-- <label class="headingStyle">Sponsor Details</label> <br> -->
                <div class="card text-center" style="background-color:#a4a5a6;color: aliceblue;">
                    <h4>Sponsor Details</h4>
                </div>
                <table>
                    <tr>
                        <td class="customerTitles">Sponser Name</td>
                        <td>:</td>
                        <td class="SponserName" *ngIf="requestDetailsObj.CustomerDetails.Name">
                            {{requestDetailsObj.CustomerDetails.Name}}</td>
                    </tr>
                    <tr>
                        <td class="customerTitles">Mobile Number</td>
                        <td>:</td>
                        <td class="SponserName" *ngIf="requestDetailsObj.CustomerDetails.MobileNumber">
                            {{requestDetailsObj.CustomerDetails.MobileNumber}}</td>
                    </tr>
                </table>
            </div>
            <!-- <div class="col-md-2"></div> -->
            <div class="beneficiaryDetailsstyle col-md-6">
                <!-- <h5 class="DetailsTitle"><b class="underline">Benificiary Details</b></h5> -->
                <!-- <label class="BeneficiaryDetails" for="">Beneficiary Details</label> -->
                <div class="card text-center" style="background-color:#a4a5a6;color: aliceblue;">
                    <h4>Beneficiary Details</h4>
                </div>
                <table>
                    <tr>
                        <td class="customerTitles">Beneficiary Name</td>
                        <td>:</td>
                        <td class="SponserName" *ngIf="BeneficiaryName">{{BeneficiaryName}}</td>
                    </tr>
                    <tr>
                        <td class="customerTitles">Mobile Number</td>
                        <td>:</td>
                        <td class="SponserName" *ngIf="BeneficiaryMobileNumber">{{BeneficiaryMobileNumber}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row executiveTable">
            <div class="col-md-4 rightBorder"
                *ngIf="requestDetailsObj.CustomerDetails.SupportExecutiveDetails&&requestDetailsObj.CustomerDetails.SupportExecutiveDetails.FirstName">
                <!-- <h5 class="supportStyle"><b class="underline">Support Executive</b></h5> -->
                <!-- <label class="supportStyle"></label> -->
                <div class="card text-center" style="background-color:#a4a5a6;color: aliceblue;">
                    <h4>Support Executive</h4>
                </div>

                <table>
                    <tr>
                        <td class="customerTitles">Name</td>
                        <td>:</td>
                        <td class="SponserName">
                            {{requestDetailsObj.CustomerDetails.SupportExecutiveDetails.FirstName}} </td>
                    </tr>
                    <tr>
                        <td class="customerTitles">Contact</td>
                        <td>:</td>
                        <td class="SponserName">
                            {{requestDetailsObj.CustomerDetails.SupportExecutiveDetails.PrimaryMobileNumber}}</td>
                    </tr>
                    <tr>
                        <td class="customerTitles">Email-Id</td>
                        <td>:</td>
                        <td class="SponserName">
                            {{requestDetailsObj.CustomerDetails.SupportExecutiveDetails.EmailID}} </td>
                    </tr>
                    <tr>
                        <td class="customerTitles">Employee ID</td>
                        <td>:</td>
                        <td class="SponserName">
                            {{requestDetailsObj.CustomerDetails.SupportExecutiveDetails.EmployeeID}}
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-4 rightBorder">
                <!-- <label class="supportStyle">Care Manager</label> -->
                <div class="card text-center" style="background-color:#a4a5a6;color: aliceblue;">
                    <h4>Care Manager</h4>
                </div>

                <table>
                    <tr>
                        <td class="customerTitles">Name</td>
                        <td>:</td>
                        <td class="SponserName"
                            *ngIf="requestDetailsObj.CustomerDetails.DedicatedFieldEmployeeDetails&&requestDetailsObj.CustomerDetails.DedicatedFieldEmployeeDetails.FirstName">
                            {{requestDetailsObj.CustomerDetails.DedicatedFieldEmployeeDetails.FirstName}}
                            {{requestDetailsObj.CustomerDetails.DedicatedFieldEmployeeDetails.LastName}}</td>
                    </tr>
                    <tr>
                        <td class="customerTitles">Contact</td>
                        <td>:</td>
                        <td class="SponserName"
                            *ngIf="requestDetailsObj.CustomerDetails.DedicatedFieldEmployeeDetails&&requestDetailsObj.CustomerDetails.DedicatedFieldEmployeeDetails.PrimaryMobileNumber">
                            {{requestDetailsObj.CustomerDetails.DedicatedFieldEmployeeDetails.PrimaryMobileNumber}}
                        </td>
                    </tr>
                    <tr>
                        <td class="customerTitles">Email-Id</td>
                        <td>:</td>
                        <td class="SponserName"
                            *ngIf="requestDetailsObj.CustomerDetails.DedicatedFieldEmployeeDetails&&requestDetailsObj.CustomerDetails.DedicatedFieldEmployeeDetails.EmailID">
                            {{requestDetailsObj.CustomerDetails.DedicatedFieldEmployeeDetails.EmailID}}</td>
                    </tr>
                    <tr>
                        <td class="customerTitles">Employee ID</td>
                        <td>:</td>
                        <td class="SponserName"
                            *ngIf="requestDetailsObj.CustomerDetails.DedicatedFieldEmployeeDetails&&requestDetailsObj.CustomerDetails.DedicatedFieldEmployeeDetails.EmployeeID">
                            {{requestDetailsObj.CustomerDetails.DedicatedFieldEmployeeDetails.EmployeeID}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-md-4 rightBorder">
                <!-- <label class="backupcm">Backup Care Manager</label> -->
                <div class="card text-center" style="background-color:#a4a5a6;color: aliceblue;">
                    <h4>Backup Care Manager</h4>
                </div>

                <table>
                    <tr>
                        <td class="customerTitles">Name</td>
                        <td>:</td>
                        <td class="SponserName"
                            *ngIf="requestDetailsObj.CustomerDetails.BackUpFieldEmployeeDetails&&requestDetailsObj.CustomerDetails.BackUpFieldEmployeeDetails.FirstName">
                            {{requestDetailsObj.CustomerDetails.BackUpFieldEmployeeDetails.FirstName}}
                            {{requestDetailsObj.CustomerDetails.BackUpFieldEmployeeDetails.LastName}}</td>
                    </tr>
                    <tr>
                        <td class="customerTitles">Contact</td>
                        <td>:</td>
                        <td class="SponserName"
                            *ngIf="requestDetailsObj.CustomerDetails.BackUpFieldEmployeeDetails&&requestDetailsObj.CustomerDetails.BackUpFieldEmployeeDetails.PrimaryMobileNumber">
                            {{requestDetailsObj.CustomerDetails.BackUpFieldEmployeeDetails.PrimaryMobileNumber}}</td>
                    </tr>
                    <tr>
                        <td class="customerTitles">Email-Id</td>
                        <td>:</td>
                        <td class="SponserName"
                            *ngIf="requestDetailsObj.CustomerDetails.BackUpFieldEmployeeDetails&&requestDetailsObj.CustomerDetails.BackUpFieldEmployeeDetails.EmailID">
                            {{requestDetailsObj.CustomerDetails.BackUpFieldEmployeeDetails.EmailID}}</td>
                    </tr>
                    <tr>
                        <td class="customerTitles">Employee ID</td>
                        <td>:</td>
                        <td class="SponserName"
                            *ngIf="requestDetailsObj.CustomerDetails.BackUpFieldEmployeeDetails&&requestDetailsObj.CustomerDetails.BackUpFieldEmployeeDetails.EmployeeID">
                            {{requestDetailsObj.CustomerDetails.BackUpFieldEmployeeDetails.EmployeeID}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="type==='discountprice'">
        <div class="discount-price-heading" style="text-align:center">
        <h3><u>Approve Discounted Amount </u></h3>
        </div>
    <div class="row">
        <table class="table text-center">
            <thead>
                <tr>
                    <th>Request ID</th>
                    <th>Sponsor Name</th>
                    <th>Service</th>
                    <th>Vendor Name</th>
                    <th>Actual Minimum Price</th>
                    <th>Discounted Price</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of discountAmountsListArr">

                    <td>{{requestDetailsObj.RequestDetails.RequestID}}</td>
                    <td>{{requestDetailsObj.RequestDetails.Name |replaceUnderscore |
                        titlecase}}</td>
                    <td>{{requestDetailsObj.RequestDetails.SubCategoryName |replaceUnderscore |
                        titlecase}}</td>

                    <td>{{data.partnerData.VendorName}}</td>
                    <td>{{data.MinimumPrice}}</td>
                    <td>{{data.DiscountedPrice}}</td>
                    <!-- <td>
                        <button  class="anvayaabutton-primary" >Approve</button>
                    </td> -->
                    <td>
                        <!-- Button trigger modal -->
                        <span *ngIf="data.Status != 'Reject'">
                            <button type="button"   class="anvayaabutton-primary" data-bs-toggle="modal" data-bs-target="#discountAmountModal">Action
                            </button>
                        </span>
                            
  
  
                        <!-- <button  class="anvayaabutton-secondary" >Reject</button> -->
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="Activites">Activies</div>
</div>

<!-- Modal For Approve Reject Discount Price Start here -->
<div class="modal fade" id="discountAmountModal" tabindex="-1" aria-labelledby="discountAmountModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div>
                <textArea class="form-control" #commentsForDiscountPrice placeholder="Leave a comment here" id="floatingTextarea" ></textArea>
            </div>
            <div *ngIf="discountValidationObj.commentsLength < 3 && discountValidationObj.submitted" >
                <span class="errorColor">*Comments are mandatory</span>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="anvayaabutton-secondary" (click)="onDiscountPriceApprove(requestDetailsObj.RequestDetails.RequestID,'Reject')">Reject</button>
          <button type="button" class="anvayaabutton-primary" (click)="onDiscountPriceApprove(requestDetailsObj.RequestDetails.RequestID,'Approve')">Approve</button>
        </div>
      </div>
    </div>
  </div>
<!-- Modal For Approve Reject Discount Price End here -->

<!-- <ngx-spinner type="ball-scale-multiple">loading...</ngx-spinner> -->