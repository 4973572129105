<div class="bodycontent">
  <div style="overflow-x:auto;" class="bodycontent">
    <table class="table table-striped table-hover memberTableMaindiv">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">SPONSER NAME</th>
          <th scope="col">PACKAGE NAME</th>
          <th scope="col">EMERGENCY </th>
          <th scope="col">REGISTERD DATE </th>
          <th scope="col">LOCATION </th>
          <!-- <th scope="col"> DEVICE SUBSCRIPTION</th> -->
          <th>REMINDERS</th>
          <th>SMART-WATCH</th>
          <!-- <th>HEALTH VITALS</th> -->

        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let data of list; let i= index">
          
          <td (click)="detailspage(data)">{{data.CustRecID}}</td>
          <td (click)="aserseScreen(data)">{{data.Name}}</td>
          <td>{{data.CustomerPackage.AliasName}}</td>
          <td>{{data.EmergencyPlan}}</td>
          <td>{{data.CreatedDate*1000 | date:'dd-MMM-YYYY'}}</td>
          <td>{{data.CustomerPackage.ServiceAreaName}}</td>
          <!-- <td (click)="configpage(data)">{{data.ASERSConfigured}}</td> -->
           <!-- <td><button class="btn btn-primary"type="button" (click)="ConfigurationForm(data)">{{data.ASERSConfigured == true?'Update':'Register'}}</button></td> -->
           <td><button type="button"  class="btn" (click)="remainders(data)"  [hidden]="data.CustomerPackage.AliasName ==undefined"><i class="fa fa-bell" aria-hidden="true" ></i></button></td>

           <td><button type="button" class="btn" (click)="watchDetails(data)" [hidden]="!data.ASERSConfigured"><img src="../../assets/images/smart-watch.png" /></button></td>
           <!-- <td><button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="findbeneficiaries(data)" ><img src="../../assets/images/oximeter.png" /></button></td> -->
        </tr>
      </tbody>
    </table>
  </div>

<!-- Modal -->
<div class="modal " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Are you for looking 6in1 Beneficiary</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"  #Benclose></button>
      </div>
      <div class="modal-body">
        <div *ngIf="nodatatemp">
          No beneficiaries Found
        </div>
        <div *ngIf="InputDrop">
          <form [formGroup]="beneficiarysixinone">
            <select class="form-select" formControlName="beneficiaries" (change)="sixinonemodule()">
              <option  value=""selected disabled>Select Beneficiary</option>
              <option *ngFor="let ben of beneficiariesArray" value="{{ben.CustID}}" data-bs-dismiss="modal" id="closeModal" >{{ben.Name}}</option>
            </select>
          </form>
        </div>
       
      </div>
      <div class="modal-footer">
       
        <!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="sixinonemodule()" ></button> -->
      </div>
    </div>
  </div>
</div>


</div>
<ngx-spinner type="ball-scale-multiple">loading...</ngx-spinner>



    


