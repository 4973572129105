<div class="bodycontent">    
    <!-- Conditionally render the iframe if onboarding is true -->
    <iframe *ngIf="onboarding"
            id="onboardingIframe"
            width="100%"
            height="600px"
            frameborder="0"
            style="border:none;"
            [src]="iframeSrc">  <!-- Dynamically set the src using property binding -->
    </iframe>
  </div>