<div class="bodycontent">
    <form [formGroup]="filterConditionsForm">

    <div class="filters">
        <div>        
            <label> StartDate</label><input type="date" class="form-control" data-date-format="DD MM YYYY" formControlName="startDate"/>
        </div>
        <div>
            <label> EndDate</label><input type="date" class="form-control" formControlName="endDate">
        </div>
        <div>
            <label> CareManager</label>
            <select class="form-select" aria-label="Default select example" formControlName="careManager" >
                <option selected value="" >All</option>
                <option *ngFor="let careManagerObj of careManagers" value="{{careManagerObj.EmployeeID}}">
                    {{careManagerObj.FullName}}
                    </option>
            </select>
            <!-- <input type="date" class="form-control" formControlName="startDate"/> -->
        </div>
        <div>
            <label> Support Executive</label>
            <select class="form-select" aria-label="Default select example" formControlName="supportExecutive" >
                <option selected value="" >All</option>
                <option *ngFor="let supportObj of supportExecutives" value="{{supportObj.EmployeeID}}">
                    {{supportObj.FullName}}
                    </option>
            </select>
        </div>
        <div>
            <label> City</label>
            <select class="form-select" aria-label="Default select example" formControlName="city" >
                <option selected value="" >All</option>
                <option *ngFor="let cityObj of availbleCities" value="{{cityObj.serviceAreaID}}">
                    {{cityObj.seviceAreaName}}
                    </option>
            </select>
        </div>
        <div>
            <button class="anvayaabutton-primary" (click)="handleOnSearchClick()">Search</button>
        </div>
    </div>
</form>

    <table class="table table-striped table-hover EmergencyTableList">
		<thead class="listHead">
			<tr>
				<th scope="col" class="EmergencyTableList-th-td">Request Details</th>
				<th scope="col" class="EmergencyTableList-th-td">Raised By</th>
				<th scope="col" class="EmergencyTableList-th-td">Customer Details</th>
				<th scope="col" class="EmergencyTableList-th-td">Source </th>
				<th scope="col" class="EmergencyTableList-th-td">Location</th>
				<th scope="col" class="EmergencyTableList-th-td">Care Manager Name</th>
				<th scope="col" class="EmergencyTableList-th-td">Support Executive Name</th>
				<th scope="col" class="EmergencyTableList-th-td">Status</th>
				<th scope="col" class="EmergencyTableList-th-td">Details</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let closedEmergencyObj of closedEmergencyList  | paginate
            : {
                itemsPerPage: pageLimit,
                currentPage: page,
                totalItems: +totalRecords
              };
        let i = index">
                <td>
                    <div class="fs-6">{{closedEmergencyObj.RequestedDate*1000|date : 'dd-MMM-yyyy'}}</div>
					<div>{{closedEmergencyObj.RequestedDate*1000|date : 'hh:mm a'}}</div>
					<span style="font-size: 13px;">{{closedEmergencyObj.RequestID}}</span>
                </td>
                <td>
                    <div>{{closedEmergencyObj?.CreatedPersonJob|titlecase}}</div>
					<div>{{closedEmergencyObj?.CreatedPersonName|titlecase}}</div>
                </td>
                <td>
                    {{closedEmergencyObj?.Name|titlecase}}
                </td>
                <td>{{closedEmergencyObj?.AliasAlarmType}}
                    <!-- Source -->
                </td>
                <td>{{closedEmergencyObj.ServiceAreaName|titlecase}}</td>
                <td>{{closedEmergencyObj.CustomerDetails?.DedicatedFieldEmployeeDetails?.fullName|titlecase}}</td>
                <td>{{closedEmergencyObj.CustomerDetails?.supportExecutiveDetails?.fullName|titlecase}}</td>
                <td>{{closedEmergencyObj.Status}}</td>
                <td><img src="../../assets/images/view.png" style="width: 30px;cursor: pointer;"  data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"  (click)="detaiedViewOfRequest(closedEmergencyObj.RequestID)"></td>
            </tr>
		</tbody>
	</table>
    <div class="d-flex justify-content-center">
        <pagination-controls   class="pagination"
          previousLabel="Prev"
          nextLabel="Next"
          (pageChange)="onTableDataChange($event)"
        >
        </pagination-controls>
      </div>
</div>

<!-- Below canvase for show detailed view of closed emergency request -->

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style="width:50%">
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel">RequestID  : {{RequestDetailsObject?.RequestID}}</h5>
     
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <hr>
    <div class="offcanvas-body">
      
        <ul class="list-group list-group-horizontal">
            <li class="list-group-item border-0" style="width: 30%;">
                <div class="row">
                    <div class="col-md-3"><b>Name</b></div>
                    <div class="col-md-1 p-0">:</div>
                    <div class="col-md-8 p-0 text-wrape">{{RequestDetailsObject?.EmergencyPersonDetails?.Name}}</div>
                </div>
            </li>
            <li class="list-group-item border-0" style="width: 24%;">
                <div class="row">
                    <div class="col-md-5"><b>Gender</b></div>
                    <div class="col-md-1 p-0">:</div>
                    <div class="col-md-5 p-0">{{RequestDetailsObject?.EmergencyPersonDetails?.Gender}}</div>
                </div>
            </li>
            <!-- <li class="list-group-item border-0" style="width: 27%;">
                <div class="row">
                    <div class="col-md-3"><b>DOB</b></div>
                    <div class="col-md-1 p-0">:</div>
                    <div class="col-md-6 p-0">{{RequestDetailsObject?.EmergencyPersonDetails?.DOB}}</div>
                </div>
            </li> -->
            <li class="list-group-item border-0" style="width: 30%;">
                <div class="row">
                    <div class="col-md-4"><b>Mobile</b></div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-4 p-0">{{RequestDetailsObject?.EmergencyPersonDetails?.MobileNumber}}</div>
                </div>
            </li>
          </ul>

          <div class="border border-secondry m-3">
            <div>
               <h5>Emergency Team Details</h5> 
            </div>
            <table class="table table-borderless">
                <thead>
                </thead>
                <tbody *ngFor="let team of RequestDetailsObject?.EmergencyTeam">
                  <tr >
                    <th scope="row">{{team?.AcceptedPersonName}}</th>
                    <td>{{team?.MobileNumber}}</td>
                    <td>{{team?.Role}}</td>
                  </tr>
                </tbody>
              </table>
          </div>
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item" *ngIf="!HospitalData">
                <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                    Hospital
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                  <div class="accordion-body">
                    <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Type</th>
                            <th scope="col">Address</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let hospital of RequestDetailsObject?.OtherHospitalDetails">
                            <ng-container *ngIf="hospital?.IsAccepted">
                                <th>{{hospital?.OtherHospitalName}}</th>
                                <td>{{hospital?.ContactNumber}}</td>
                                <td>{{hospital?.HospitalType}}</td>
                                <td>{{hospital?.Address}}</td>
                            </ng-container>
                            
                          </tr>
                          
                        </tbody>
                      </table>
                   </div>
                </div>
              </div>
            <div class="accordion-item" *ngIf="!AmbulanceData">
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                  Ambulance
                </button>
              </h2>
              <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                <div class="accordion-body">
                    <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Provider Name</th>
                            <th scope="col">Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let ambulance of RequestDetailsObject?.AmbulanceDetails">
                            <ng-container *ngIf="ambulance?.IsAccepted">
                                <td>{{ambulance?.ProviderName}}</td>
                                <th>{{ambulance?.ContactName}}</th>
                                <td>{{ambulance?.ContactNumber}}</td>
                                <td>{{ambulance?.AmbulanceType}}</td>
                                
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>  
                </div>
              </div>
            </div>
            <div class="accordion-item" *ngIf="!PoliceStationData">
              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                  Police 
                </button>
              </h2>
              <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                <div class="accordion-body">
                    <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Address</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let police of RequestDetailsObject?.PoliceStation">
                            <ng-container *ngIf="police?.IsAccepted">
                                <th>{{police?.PoliceStationName}}</th>
                                <td>{{police?.ContactNumber}}</td>
                                <td>{{police?.Address}}</td>
                            </ng-container>
                            
                          </tr>
                          
                        </tbody>
                      </table> 
                </div>
              </div>
            </div>
            <div class="accordion-item" *ngIf="!FireStationData">
                <h2 class="accordion-header" id="panelsStayOpen-headingfour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsefour" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                    Fire Station
                  </button>
                </h2>
                <div id="panelsStayOpen-collapsefour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingfour">
                  <div class="accordion-body">
                    <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Address</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let fire of RequestDetailsObject?.FireStation">
                            <ng-container *ngIf="fire?.IsAccepted">
                                <th>{{fire?.FireStationName}}</th>
                                <td>{{fire?.ContactNumber}}</td>
                                <td>{{fire?.Address}}</td>
                            </ng-container>
                            
                          </tr>
                          
                        </tbody>
                      </table>
                  </div>
              </div>
            </div>
          </div>

          <div class="border border-secondry m-3">
            <div class="wrapper m-4" style="height: 350px; overflow-y: auto;">
                <ol class="StepProgress" *ngFor="let status of RequestDetailsObject?.StatusTrack">
                  <li class="StepProgress-item">
                    <strong>{{status?.Status}}</strong>
                    <p>{{status?.StatusMessage}}</p>


                    <div *ngIf="status?.Status == 'Update'"><span class="text-danger">* Note</span> : {{status?.Note}}</div>
                    {{status?.Time*1000|date:"dd-MMM-yyyy HH:mm a"}}
                  </li>
                </ol>
              </div>
            </div>

  </div>
