import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-partner-finance-approvals',
  templateUrl: './partner-finance-approvals.component.html',
  styleUrls: ['./partner-finance-approvals.component.css']
})
export class PartnerFinanceApprovalsComponent implements OnInit {
  @ViewChild('statusclosebutton') statusclosebutton: any;

  title: string = 'Partner Details';
  type: string = 'Bank';
  contractID: string = '';
  partnerID: string = '';
  updatedStatus: string = '';
  updateStatusForm: UntypedFormGroup;
  submitted = false;
  partnerDetails: any = {}
  contractDetails: any
  bankDetails: any = {}
  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private userservice: UsersService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }
  ngOnInit(): void {
    this.spinner.show();
    this.updateStatusForm = this.formBuilder.group({
      status: ['', [Validators.required]],
      comments: ['', [Validators.required, Validators.minLength(3)]]
    });

    this.contractID = this.route.snapshot.queryParamMap.get('contractID') ?? '';
    this.partnerID = this.route.snapshot.queryParamMap.get('partnerID') ?? '';
    this.type = this.route.snapshot.queryParamMap.get('type') ?? '';
    this.type === 'Bank' ? this.getPartnerBankDetails(this.partnerID) : this.getPartnerContractDetails();
    this.spinner.hide();
  }
  get f(): { [key: string]: AbstractControl } {
    return this.updateStatusForm.controls;
  }
  getPartnerBankDetails(partnerID: string) {
    this.spinner.show();
    this.userservice.getPartnerBankDetails(partnerID).subscribe((res: any) => {
      this.bankDetails = res.data
      this.partnerDetails = this.bankDetails.partnerDetails
      this.spinner.hide();

    }, (err) => {
      this.spinner.hide();
      alert(err.error.data)
    })
  }

  getPartnerContractDetails() {
    this.spinner.show();
    this.userservice.onViewPartnerContractDetails({ contractID: this.contractID, partnerID: this.partnerID }).subscribe((res: any) => {
      this.contractDetails = res.data;
      this.partnerDetails = this.contractDetails.partnerDetails
    })
  }

  onStatusChange(event: any) {
    this.updatedStatus = event.target.value;
  }
  handleUpdateStatus() {
    this.spinner.show();
    this.submitted = true;
    if (this.updateStatusForm.invalid) {
      this.spinner.hide();
      return;
    }
    if (this.type === 'Bank') {
      this.onUpdateBankStatus()
    }
    if (this.type === 'Contract') {
      this.onUpdateContractStatus()
    }
    this.router.navigate(['Dashboard/Task/MyTask']);
    this.submitted = false;
    this.spinner.hide();

  }
  onUpdateBankStatus() {
    if (this.updateStatusForm.value['status'] === 'Approved') {
      this.userservice.onApprovePartnerBankDetails({ partnerID: this.partnerID, comments: this.updateStatusForm.value['comments'] }).subscribe((res) => {
        this.getPartnerBankDetails(this.partnerID)
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        alert(err.error.message)
      })
    } else {
      this.userservice.onRejectPartnerBankDetails({ partnerID: this.partnerID, comments: this.updateStatusForm.value['comments'] }).subscribe((res) => {
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        alert(err.error.message)
      })
    }
  }
  onUpdateContractStatus() {
    this.userservice.handleApproveContractDetailsByFinance({ contractID: this.contractID,status:this.updateStatusForm.value['status'] , comment: this.updateStatusForm.value['comments'] }).subscribe((res) => {
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      alert(err.error.message)
    })


    // handleApproveContractDetailsByFinance
  }


}
