<div class="maiDiv">
    <form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
      <div class="card">
        <div class="ms-3 mt-4">
          <div class="row">
            
            <!-- Address Field with Autocomplete -->
            <div class="col-5 col-md-5 col-lg-5 ms-1 mb-2 mt-2">
              <label for="address">Address <span class="text-danger">*</span></label>
              <ng-autocomplete
                style="border-radius: 10px;"
                [data]="suggetionsList"
                name="Name"
                formControlName="address"
                [placeholder]="autocompletePlaceHolder"
                [searchKeyword]="keyword"
                notFoundText="Not found"
                (inputChanged)='onSearch($event, "pick")'
                [itemTemplate]="itemTemplateStatic"
                (selected)='onChangeSearch($event)'
                (inputCleared)='onFocused($event, "pick")'
                [notFoundTemplate]="notFoundTemplate"
                (keypress)="onlyAlphabets($event)">
              </ng-autocomplete>
              <ng-template #itemTemplateStatic let-item>
                <a [innerHTML]="item.description"></a>
              </ng-template>
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
  
            <!-- Nearby Landmark Field -->
            <div class="col-5 col-md-5 col-lg-5 ms-1 mb-2 mt-2">
              <label for="landmark">Nearby Landmark</label>
              <input class="form-control" id="landmark" formControlName="landmark" placeholder="Nearby landmark" />
            </div>
  
            <!-- State Field -->
            <div class="col-3 col-md-3 ms-1 mb-2 mt-2">
              <label for="state">State</label>
              <input class="form-control" id="state" formControlName="state" placeholder="State" />
            </div>
  
            <!-- Pin Code Field -->
            <div class="col-3 col-md-3 ms-1 mb-2 mt-2">
              <label for="pincode">Pin Code</label>
              <input class="form-control" id="pincode" formControlName="pincode" placeholder="Pin code" />
            </div>
  
            <!-- Map Placeholder -->
            <div class="col-3 col-md-3 ms-1 mb-2 mt-2">
              <label>Map</label>
              <!-- Map content or component goes here -->
              <div class="map-placeholder">Map</div>
            </div>
  
            <!-- Start Date Field -->
            <div class="col-3 col-md-3 ms-1 mb-2 mt-2">
              <label for="startDate">Start Date</label>
              <input class="form-control" type="date" id="startDate" formControlName="startDate" />
            </div>
  
          </div>
        </div>
        
        <!-- Form Submit Button -->
        <div class="ms-3 mt-4">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </div>
  