
<div class="bodycontent">
  <table class="table" style="margin-bottom: 20px; border: 1px solid #ddd; border-radius: 8px;">

    <thead>
      <tr>
        <th>RequestID</th>
        <th>Customer Name</th>
        <th>Mobile number</th>
        <th>Issue Type</th>
        <th>Agent Name</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ interactiondetails?.RequestDetails?.RequestID }}</td>
        <td>{{ interactiondetails?.CustomerDetails?.Name }}</td>
        <td>{{ interactiondetails?.CustomerDetails?.MobileNumber }}</td>
        <td>{{ interactiondetails?.RequestDetails?.ServiceName }}</td>
        <td>{{ interactiondetails?.RequestDetails?.CreatedPersonName }}</td>
      </tr>
    </tbody>
  </table>
  <br>
  <button class="anvayaabutton-primary" style="width: fit-content; margin-left: 500px; margin-bottom: 30px;" (click)="toggleAddInteractionCard()">Add Interaction</button>
  <button class="anvayaabutton-secondary" style="width: fit-content; margin-left: 100px; margin-bottom: 30px;"  (click)="onClickCancelButton()" >Cancel</button>
  
  <div class="row" [formGroup]="Addnteraction" *ngIf="showAddInteractionCard">

    <div class="col-md-3">
      <span class="formdetails">Status:</span>
      <select class="form-select" aria-label="Select Issue" formControlName="Status">
        <option selected disabled hidden>Select Issue</option>
        <option *ngFor="let escalation of interactionStatus" [value]="escalation">{{ escalation }}</option>
      </select>
      <div class="row" *ngIf='this.Addnteraction.controls["Status"].status=="INVALID" && this.addinteractionbtn==true'>
        <p class="errorlines">*Select Status</p>
      </div>

    </div>

    <div class="col-md-3">
      <span class="formdetails">Comment:</span>
      <textarea formControlName="Comment" class="textbox" rows="2" placeholder="Enter Comment"></textarea>
      <div class="row" *ngIf='this.Addnteraction.controls["Comment"].status=="INVALID" && this.addinteractionbtn==true'>
        <p class="errorlines">*Add Comment</p>
      </div>
    </div>

    <div class="col-md-2">
      <button class="anvayaabutton-primary" style="width: fit-content; margin-top: 30px;" (click)="saveInteraction()">Save</button>

    </div>

</div>
  <table class="table" style="margin-bottom: 20px; border: 1px solid #ddd; border-radius: 8px;">
    <thead>
      <tr>
        <th>Status</th>
        <th>Comment</th>
        <!-- <th>Department</th> -->
        <th>Updated Person</th>
        <th>Updated Date</th>
        
        <!-- <th>Action</th> -->
      </tr>
    </thead>
    <tbody *ngFor="let interaction of insteractionList">
      <tr>
        <td>{{ interaction.Status }}</td>
        <td class="comments-cell" style="word-wrap: break-word;">{{ interaction.Comments }}</td>
        <!-- <td>{{interaction.Department}}</td> -->
        <td>{{interaction.CreatedPersonName}}</td>
        <td>{{ interaction.Time * 1000 | date:'dd-MMM-yyyy hh:mm a' }}</td>
        
        <!-- <td>
          <button class="anvayaabutton" style="width: fit-content;" (click)="toggleAddInteractionCard()">Action</button>
        </td> -->
      </tr>
    </tbody>
  </table>

  
</div>
  