import { Component } from '@angular/core';
import { UsersService } from 'src/app/users.service';
import { ActivatedRoute, Router, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
// import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Location } from '@angular/common'
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-ambulanceintegrations',
  templateUrl: './ambulanceintegrations.component.html',
  styleUrls: ['./ambulanceintegrations.component.css']
})
export class AmbulanceintegrationsComponent {

  intialAmbulanceForm:UntypedFormGroup
  constructor(private spinner:NgxSpinnerService,private location: Location,   
    private userservice: UsersService, private route: Router, private router: ActivatedRoute, private formBuilder: UntypedFormBuilder) { }
    PartnerID:any
    RequestID:any
    requestdetail:any = {}
    CustomerDetails:any
    suggetionsList:any = []
    autocompletePlaceHolder:string = "Search Address"
    pickupaddressLocation:any
    dropAddressLocation:any
    public keyword = 'description';
    ngOnInit(): void {
      this.RequestID = this.router.snapshot.queryParamMap.get('RequestID');
      this.PartnerID = this.router.snapshot.queryParamMap.get('PartnerID');
     this.intialAmbulance()  //form function  
     this.requestDetails() // requestDetails
    }

    intialAmbulance(){

      this.intialAmbulanceForm = this.formBuilder.group({
        userName: ['', Validators.required], // Add Validators if needed
        userPhone: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
        PartnerID:['', ],
        RequestID:['', ],

        // Nested pickupPoint form group
        pickupPoint: this.formBuilder.group({
          address: ['',Validators.required],
          city: ['',Validators.required],
          location: ['',Validators.required],
          state: ['',Validators.required],
          pinCode: [
            '',Validators.required
          ], // Ensures only a 6-digit pin code
        }),
        dropoffPoint: this.formBuilder.group({
          address: ['',Validators.required],
          city: ['',Validators.required],
          location: ['',Validators.required],
          state: ['',Validators.required],
          pinCode: [
            '',Validators.required
          ], // Ensures only a 6-digit pin code
        }),
      });
      
    }
       

    requestDetails(){
      this.spinner.show();
      this.userservice.viewRequest(this.RequestID).subscribe(async (data: any) => {
        this.spinner.hide();
        this.requestdetail = data.data // assin=gning the fetched request details
          this.CustomerDetails = this.requestdetail.customerDetails.Beneficiaries.filter((data:any)=>{
                if(this.requestdetail.CustID == data.CustID){
                   return data
                }
          })

            this.intialAmbulanceForm.patchValue({'userName':this.CustomerDetails[0].Name})

            this.intialAmbulanceForm.patchValue({
              pickupPoint: {
              
                  city: this.CustomerDetails[0].ServiceAreaName,
                  address: this.CustomerDetails[0].Address,
                  pincode: this.CustomerDetails[0].Pincode,
                  state: this.CustomerDetails[0].State,
                  floor: this.CustomerDetails[0]?.pickupFloor
                }
            
            });
            this.supportEmployeeDetails()
      },
        (error) => {
          this.spinner.hide();
          alert(error.data.data);
        },
      );
    }
      


    supportEmployeeDetails(){


       let EmployeeID = localStorage.getItem("LoginEmployeeIDNew")
      
    
      this.userservice.individualEmployeeDetails(EmployeeID).subscribe(async (data: any) => {
        this.spinner.hide();
        this.intialAmbulanceForm.patchValue({'userPhone':data.data[0].ContactNumber})

      },
        (error) => {
          this.spinner.hide();
          alert(error.data.data);
        },
      );
    }



    submitIntialForm(){
         console.log(this.intialAmbulanceForm.value)
    }


    onSubmit() {
      if (this.intialAmbulanceForm.invalid) {
        this.intialAmbulanceForm.markAllAsTouched(); // Mark all fields as touched to trigger validation
        return;
      }
    
      // Process the form values
      console.log(this.intialAmbulanceForm.value);
    }



    submitVendorToRequest(){

       this.spinner.show()
       this.RequestID = this.router.snapshot.queryParamMap.get('RequestID');
       this.PartnerID = this.router.snapshot.queryParamMap.get('PartnerID');
      const Vendor:any = {
        "RequestID":this.RequestID,
        // "PartnerID":this.SelectedPartner
      }

      this.intialAmbulanceForm.patchValue({'RequestID':this.RequestID})
      this.intialAmbulanceForm.patchValue({'PartnerID':this.PartnerID})


      this.intialAmbulanceForm.patchValue({
        pickupPoint:{

        }
      })  
      
      this.intialAmbulanceForm.value.pickupPoint.address = this.intialAmbulanceForm.value.pickupPoint.address.description
      this.intialAmbulanceForm.value.dropoffPoint.address = this.intialAmbulanceForm.value.dropoffPoint.address.description
          
      
      this.userservice.AddVendorForRequest(this.intialAmbulanceForm.value).subscribe((VendorsList:any) => {
       this.spinner.hide()
           
        alert(VendorsList.message)
          this.route.navigate(['/Dashboard/Task/MyTask'])
          if (VendorsList.code == "S002") {
          this.spinner.hide()

        } if (VendorsList.code == "ND01") {
         // this.route.navigate(['/Dashboard/Task/MyTask'])
       this.spinner.hide()

        }
      }, (error) => {
       this.spinner.hide()

        alert(error.error.message);
      })
    }

    onSearch(event:any,type:any){
      console.log("input search",event,type)
         const searchwoard = {
            input : event
         }
      this.userservice.googleSearchAddress(searchwoard).subscribe((response:any) => {
           console.log("response of google api ",response.data)
           this.suggetionsList = response.data.prediction
           if(type === "pick"){
            this.pickupaddressLocation = response.data.location
            this.intialAmbulanceForm.patchValue({
             pickupPoint: {
               
               city: this.pickupaddressLocation.city,
               // address:response.data.location.city,
               pinCode: this.pickupaddressLocation.pincode,
               state:this.pickupaddressLocation.state,
               // floor:""
             }
            })
           }else{
            this.dropAddressLocation = response.data.location
            this.intialAmbulanceForm.patchValue({
              dropoffPoint: {
               
               city: this.dropAddressLocation.city,
               // address:response.data.location.city,
               pinCode: this.dropAddressLocation.pincode,
               state:this.dropAddressLocation.state,
               // floor:""
             }
            })
           }
          

       if (response.code == "S002") {
     } if (response.code == "ND01") {
      // this.route.navigate(['/Dashboard/Task/MyTask'])
     }
   }, (error) => {
    //  alert(error.error.message);
   })
    }

    onChangeSearch(event:any){
      console.log("input change",event)
    }

    onFocused(event:any,type:any){
      console.log("input onfocus",event)
      if(type === "pick"){
        if(event === undefined){
          this.pickupaddressLocation = ""
          this.intialAmbulanceForm.patchValue({
            pickupPoint:{  city: "",
            // address:response.data.location.city,
            pinCode: "",
            state:"",
            }
          })
        }
      }else{

        if(event === undefined){
          this.dropAddressLocation = ""
          this.intialAmbulanceForm.patchValue({
            dropoffPoint:{  city: "",
            // address:response.data.location.city,
            pinCode: "",
            state:"",
            }
          })
        }
      }
     
    }

    onlyAlphabets(event:any){
      console.log("input alphabets",event)
    }

}
