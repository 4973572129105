import { Component } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from 'src/app/users.service';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent {
  suggetionsList: any = []
  autocompletePlaceHolder: string = "Search Address"
  public keyword = 'description';
  addressForm: UntypedFormGroup;
  constructor(private spinner: NgxSpinnerService,private fb: FormBuilder,
    private userservice: UsersService, private route: Router, private router: ActivatedRoute, private formBuilder: UntypedFormBuilder) { }

 ngOnInit():void{

  this.addressForm = this.fb.group({
    address: [''],
    landmark: [''],
    state: [''],
    pincode: [''],
    startDate: ['']
  });


 }

  onSearch(event: any, type: any) {
    console.log("input search", event, type)
    const searchwoard = {
      input: event
    }
    this.userservice.googleSearchAddress(searchwoard).subscribe((response: any) => {
      console.log("response of google api ", response.data)
      this.suggetionsList = response.data.prediction
      //   


      if (response.code == "S002") {
      } if (response.code == "ND01") {
        // this.route.navigate(['/Dashboard/Task/MyTask'])
      }
    }, (error) => {
      //  alert(error.error.message);
    })
  }

  onChangeSearch(event: any) {
    console.log("input change", event)
  }

  onFocused(event: any, type: any) {
    console.log("input onfocus", event)
  }

  onlyAlphabets(event: any) {
    console.log("input alphabets", event)
  }

  onSubmit() {
    console.log(this.addressForm.value);
  }

}
