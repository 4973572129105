
<div class="mainDiv">

  <!-- <div  *ngIf= "modelBox"           class="alertBox card  text-center">
    <h1 style="color:red">EMERGENCY ALERT !!!!!!!</h1>
    <table class="table card-body">
      <tr><td class="fw-bold">CustomerName</td><td>:</td><td class="text-center"  style="color:red">{{UserDetails?.CustomerName}}</td></tr>
      <tr><td class="fw-bold">ContactNumber</td><td>:</td><td class="text-center" style="color:red">{{UserDetails?.ContactNumber}}</td></tr>
      <tr><td class="fw-bold">EmailID</td><td>:</td><td class="text-center" style="color:red">{{UserDetails?.EmailID}}</td></tr>
     </table>

       <button class="btn btn-danger" (click)="alertAction()">View</button>
  </div> -->
<div class="card">
    <div class="card-body">
      <h4>Customer Details</h4>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
               <table class="table">
                <tr><td class="fw-bold">CustomerID</td><td>:</td><td class="text-center">{{UserDetails?.CustomerID}}</td></tr>
                <tr><td class="fw-bold">SponsorID</td><td>:</td><td class="text-center">{{UserDetails?.SponsorID}}</td></tr>
                <tr><td class="fw-bold"> Date of Birth</td><td>:</td><td class="text-center">{{UserDetails?.DOB*1000| date: 'dd-MM-yyyy'}}</td></tr>
               </table>
            </div>
            <div class="col-md-4">
                <table class="table">
                    <tr><td class="fw-bold">CustomerName</td><td>:</td><td class="text-center">{{UserDetails?.CustomerName}}</td></tr>
                    <tr><td class="fw-bold">ContactNumber</td><td>:</td><td class="text-center">{{UserDetails?.ContactNumber}}</td></tr>
                    <tr><td class="fw-bold">EmailID</td><td>:</td><td class="text-center">{{UserDetails?.EmailID}}</td></tr>
                   </table>
            </div>
            <div class="col-md-4">
                <table class="table">
                    <tr><td class="fw-bold">PlanStartDate</td><td>:</td><td class="text-center">{{UserDetails?.PlanStartDate*1000| date:'dd-MM-yyyy'}}</td></tr>
                    <tr><td class="fw-bold">PlanExpiryDate</td><td>:</td><td class="text-center">{{UserDetails ?UserDetails.PlanExpiryDate:'-'}}</td></tr>
                    <tr><td class="fw-bold">Emergency Contact Number</td><td class="text-center">:</td><td>{{UserDetails?.EmergencyPointOfContactNumber}}</td></tr>
                   </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" style="margin-top: 15px;margin-bottom: 15px;">
    <form [formGroup]="Dates">
      <div class="row">
     <div class="col-md-3"><label>StartDate:</label> <input class="form-control" formControlName="StartDate" type="date"></div>
     <div class="col-md-3"> <label>EndDate:</label><input class="form-control" formControlName="EndDate" type="date"></div>
    </div>
   
    </form>
    </div>
  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active btn-lg  btn190" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-Steps" type="button" role="tab" aria-controls="pills-Steps" aria-selected="true"(click)="watchData('UD_LTE')"><h6>Steps</h6></button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link  btn190" id="pills-profile-tab " data-bs-toggle="pill" data-bs-target="#pills-Heartbeat" type="button" role="tab" aria-controls="pills-Heartbeat" aria-selected="false" (click)="watchData('BPHRT')"><h6>B.P / HeartBeat</h6></button>
    </li>
   
    
    <li class="nav-item" role="presentation">
      <button class="nav-link  btn190" id="pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#pills-Location" type="button" role="tab" aria-controls="pills-Location" aria-selected="false" (click)="watchData('UD_LTE')"><h6>Location</h6></button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link  btn190" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-Alarm" type="button" role="tab" aria-controls="pills-BP" aria-selected="false" (click)="watchData('AL_LTE')"><h6>Alarm</h6></button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link  btn190" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-spo2" type="button" role="tab" aria-controls="pills-BP" aria-selected="false" (click)="watchData('Oxygen')"><h6>spO2</h6></button>
    </li>
  </ul>
  <div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active " id="pills-Steps" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
        
        
        <div class="card" style="width:50%">
            <div class="card-body">
                <div class="card">
                    <div class="card-body">
                        <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Steps Count  <img src="../../assets/images/human-footprint.png" /></th>
                                <th scope="col">Date/Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let steps of watchdata">
                                <td>{{steps.DeviceData.Steps}}</td>
                                <td>{{steps.CreatedDate*1000|date:'dd-MM-yyyy / HH:mm:ss a'}}</td>
                                
                              </tr>
                             
                            </tbody>
                          </table>
                    </div>
                  </div>
            </div>
          </div>
    
    
    
    
    
    </div>
    <div class="tab-pane fade" id="pills-Heartbeat" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
      <div class="card">
        <div class="card-body">
            <div class="card">
                <div class="card-body">
                    <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">HeartRate <span><img src="../../assets/images/heart-rate (1).png"></span></th>
                            <th scope="col">BP  <span><img src="../../assets/images/blood-pressure (3).png"></span></th>
                            <th scope="col">Date/Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let bp of watchdata">
                            <td>{{bp.DeviceData.Heartrate}}</td>
                            <td>{{bp.DeviceData.Hypertension +"/"+ bp.DeviceData.Hypotension }}</td>
                            <td>{{bp.CreatedDate*1000|date:'dd-MM-yyyy / HH:mm:ss a'}}</td>
                          </tr>
                         
                        </tbody>
                      </table>
                </div>
              </div>
        </div>
      </div>

    </div>
    <div class="tab-pane fade" id="pills-Alarm" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
      <div class="card">
        <div class="card-body">
            <div class="card">
                <div class="card-body">
                    <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Latitude</th>
                            <th scope="col">Longitude</th>
                            <th scope="col">Time</th>
                            <th scope="col">Alarm Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let Alarm of watchdata">
                            <td>{{Alarm.DeviceData.Latitude}}</td>
                            <td>{{Alarm.DeviceData.Longitude}}</td>
                            <td>{{Alarm.CreatedDate*1000|date:'dd-MM-yyyy / HH:mm:ss a'}}</td>
                            <td>{{Alarm.DeviceData.TerminalState}}</td>
                           
                          </tr>
                         
                        </tbody>
                      </table>
                </div>
              </div>
        </div>
      </div>

    </div>
    <div class="tab-pane fade" id="pills-Location" role="tabpanel" aria-labelledby="pills-disabled-tab" tabindex="0">
        <div class="card">
            <div class="card-body">
                <div class="card">
                    <div class="card-body">
                        <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Latitude</th>
                                <th scope="col">Longitude</th>
                                <th scope="col">Date/Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let location of watchdata">
                                <td>{{location.DeviceData.Latitude}}</td>

                                <td>{{location.DeviceData.Longitude}}</td>
                                <td>{{location.CreatedDate*1000|date:'dd-MM-yyyy HH:mm:ss a'}}</td>
                              
                             
                              </tr>
                             
                            </tbody>
                          </table>
                    </div>
                  </div>
            </div>
          </div>
    </div>
    <div class="tab-pane fade" id="pills-spo2" role="tabpanel" aria-labelledby="pills-disabled-tab" tabindex="0">
      <div class="card" style="width:50%">
          <div class="card-body">
              <div class="card">
                  <div class="card-body text-center">
                      <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">spO2 <span><img src="../../assets/images/oxygen.png"></span></th>
                              <th scope="col">Date / Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let oxygen of watchdata">
                              <td>{{oxygen.DeviceData.Oxygen}}</td>
                              <td>{{oxygen.CreatedDate*1000 | date:"dd-MM-yyyy / HH:mm:ss a"}}</td>
                            </tr>
                           
                          </tbody>
                        </table>
                  </div>
                </div>
          </div>
        </div>
  </div>
  </div>

  
</div>
