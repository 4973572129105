
<div class="bodycontent"  style="padding: 10px;">
    <div class="card"  style="padding: 10px;">

        <h3>Partner Revenue Records</h3>

        <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr style="background-color: #c7c4c4;">
                <th>Invoice ID</th>
                <th>Customer Details</th>
                <th>Vendor Details</th>
                <th>Service Details</th>
                <th>Days Served</th>
                <th>City</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let revenueRecord of RevenueRecords;let i = index">
                    <td>{{revenueRecord.InvoiceID}}</td>
                    <td>{{revenueRecord.CustomerDetails.Name}}<br>{{revenueRecord.CustomerDetails.CustRecID}}</td>
                    <td>{{revenueRecord.VendorDetails.VendorName}}<br>{{revenueRecord.VendorDetails.VendorID}}</td>
                    <td>{{revenueRecord.ServiceDetails.RequestID}}<br>{{revenueRecord.ServiceDetails.SubCategoryName}}</td>
                    <td>{{revenueRecord.ServiceDetails.ServiceDaysCount}}</td>
                    <td>{{revenueRecord.ServiceDetails.ServiceAreaName}}</td>
                    <td>{{revenueRecord.Status}}</td>
                    <td>  <button btns style="float: right;" class=" backbtn btn btn-primary" data-bs-toggle="modal"
                        data-bs-target="#UpdateStatus" (click)="onUpadteClick(revenueRecord)">Update</button></td>
                </tr>
            </tbody>
          </table>
    </div>
</div>

<div class=" row">
  
    <div class="modal fade " id="UpdateStatus" role="dialog " aria-hidden="true " data-backdrop="static "
        data-keyboard="false " aria-labelledby="fieldEmployeeModalLabel " style="padding-bottom: 50px;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content" style="height: auto; width: auto;">
                <div class="modal-header model">
                    <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
                    <h4 class="titlevendor modal-title  text-center ">Vendor Status Details</h4>
                </div>
                <form name="UpdateStatusForm" [formGroup]="billUpdate">
                    <div class="modal-body">
                        <div class="container allcp-form ">
                        
                            <div class="row" style="padding-top: 0px ;">

                                <div class='col-sm-4'>
                                    Actual Days Served :
                                    <div id='ActualDaysServed'>
                                        <input formControlName="ActualDaysServed" type='number' class="form-control"
                                            required style="width: 225px;" placeholder="Enter Days"/>
                                    
                                    </div>
                                </div>

                                <div class="col-md-5">
                                    <label class="inputtext">Upload Bill</label>
                                    <input type="file" class="form-control" #fileUploader (change)="onuploadtaxFile($event)"
                                        data-buttontext="Select Bill">
                                </div>

                            </div>
                        </div>
                    </div>

                </form>
                <div class="modal-footer">
                    <button type="button" class="AcceptBtn" (click)="uploadBill()">Update </button>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancel</button>
                </div>

            </div>
        </div>
    </div>
</div>
